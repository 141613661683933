<template>
  <div class="ent_lay">
    <div class="layout home_top"></div>
    <div class="content ent_bg">
      <div class="ent">
        <a href="https://bscv1.fivexw.com/#/selectMode">
          <div class="btn btn_normal">立即参加</div>
        </a>
      </div>
      <div class="dengzi"></div>
    </div>
    <div class="info"></div>
    <div class="layout home_bot"></div>
  </div>
</template>

<script>
export default {
  name: "ent",
  setup() {
    const goEnt = () => {};
  },
};
</script>

<style lang="scss" scoped>
.ent_lay {
  width: 100%;
  height: 100%;
  .content {
    height: calc(100vh - 2.9rem);
    color: #fffdfc;
    font-size: 0.13rem;
    display: flex;
    align-items: center;
    position: relative;
    overflow: hidden;
  }
  .info {
    height: 0.8rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.14rem;
    background: #1a1518;
  }
  .home_top {
  }
  .home_bot {
    padding-top: 0.1rem;
  }
  .layout {
    height: 0.1rem;
    width: 100%;
    background: url("../../assets/images/wyf_lay.png") 100% no-repeat;
    background-position: center;
    position: relative;
    background-size: cover;
  }
}
</style>
