<template>
  <Header :title="$route.meta.title" v-if="!$route.meta.isIndex && $route.meta.title" />
  <Suspense>
    <router-view class="router-view" v-slot="{ Component }">
      <keep-alive v-show="$route.meta.alive">
        <component :is="Component"></component>
      </keep-alive>
       <component :is="Component" v-show="!$route.meta.alive"></component>
    </router-view>
  </Suspense>
</template>

<script>
import Header from "@/components/Header";
import { watch } from "@vue/runtime-core";
import { isLoading } from "./utils";
import { useStore } from "vuex";
export default {
  name: "App",
  components: {
    Header,
  },
  created() {
    this.$store.dispatch("STAFF_WEBSOCKET").then((res) => {});
  },
  setup() {
        const store = useStore();
    watch(
      () => store.state.createUnionNow,
      () => {
        store.state.createUnionNow ? isLoading(false) : "";
      }
    );

  },
};
</script>

<style lang="scss">
@import "@/assets/scss/main.scss";
</style>
