
<template>
  <!-- 任命，审核，踢人的页面 -->
  <div class="members">
    <table>
      <tr>
        <th style="flex: 1.2; padding-left: 0.16rem">成员名</th>
        <th style="flex: 0.8">活跃度</th>
        <th style="flex: 1" v-if="type != '审核'">质押量</th>

        <th style="flex: 1; text-align: left" v-if="type != '审核'">贡献值</th>
      </tr>
      <tr v-for="item of list" :key="item.id">
        <td style="flex: 1.2; padding-left: 0.16rem">
          <el-checkbox
            v-model="item.checked"
            @change="checkMember(item)"
            style="
              height: 0.13rem;
              position: absolute;
              left: 0.2rem;
              top: 0.13rem;
            "
          ></el-checkbox>
          <span style="margin-left: 0.2rem">
            {{ item.nickname }}
          </span>
        </td>
        <td style="flex: 0.8">{{ item.active }}</td>
        <td style="flex: 1" v-if="type != '审核'">{{ item.pledge }}</td>

        <td style="flex: 1; text-align: left" v-if="type != '审核'">
          {{ Number((item.devote ? item.devote : 0) * 100).toFixed(2) }}
        </td>
      </tr>
    </table>
    <button @click="kickMenber" :class="{ noMenber: checkButton() }">
      确认{{ type }}
    </button>
  </div>
</template>

<script>
import { useStore } from "vuex";
import { ref, reactive, toRefs } from "vue";
import router from "@/router/index.js";
import { getUnionInfoContent, getCacheTableList } from "../apis/httpReq";
import { createUnionCall } from "../apis/callBack";

import { getAccount, getActionContract, isLoading } from "../utils";
import { ElMessage } from "element-plus";
import { getCurrentInstance } from "vue";
import config from "../store/config";
export default {
  name: "ChooseIcons",
  setup: async () => {
    const vue = getCurrentInstance();
    const store = useStore();

    let type = router.currentRoute.value.query.type;
    let unionId = router.currentRoute.value.query.id;
    let InfoContent;
    if (type == "审核") {
      InfoContent = await getCacheTableList(unionId);
    } else {
      InfoContent = await getUnionInfoContent(unionId);
    }
    let list = reactive(InfoContent.list);
    let account = await getAccount();

    let ids = reactive([]);
    let actionContract = getActionContract(); //获得action合约对象
    let param = ref("");
    if (list && list.length > 0) {
      if (type != "审核") {
        list.shift();
      }

      list.forEach((v) => {
        v = reactive(v);
        v.checked = false;
        if (v.role === 98 && type == "任命") {
          //初始化
          v.checked = true;
        }
      });
    }
    ids = new Map();
    const checkButton = (item) => {
      console.log(type)
      if(type==="任命"){
        return false;
      }
      if (!list) {
        return true;
      }
      return !list.some((v, i) => {
        return v.checked;
      });
    };
    const checkMember = (item) => {
      //根据type判断选择时参数应该如何拼接
      param.value = unionId;
      list.forEach((v, i) => {
        if (v.checked) {
          if (type == "踢出") {
            ids.set(i, [v.addr]);
          } else if (type == "任命") {
            ids.set(i, [v.playerId]);
          } else if (type == "审核") {
            ids.set(i, [v.ID]);
          }
        } else {
          ids.delete(i);
        }
      });
      ids.forEach((v) => {
        if (type == "审核") {
          param.value += "," + "3," + v.join(",").toString();
        } else {
          param.value += "," + v.join(",").toString();
        }
      });
    };
    if (list && list.length > 0) { //初始化参数
      checkMember();
    }
    const kickMenber = () => {
      console.log(param.value, account);
      if (checkButton()) {
        return;
      }
          isLoading(true);
      if (type == "踢出") {
        actionContract.methods
          .requestPlayerAction("kickoutMember", param.value)
          .send({ from: account })
          .catch((err) => {
            ElMessage.warning({
              message: "用户取消",
              type: "warning",
            });
            isLoading(false);
          })
          .then((res) => {
            let tx;
            tx = res.transactionHash;
            console.log("执行set-");
            setTimeout(function () {
              console.log("执行set+");
              if (!store.state.createUnionNow) {
                createUnionCall(tx).then((res) => {
                  ElMessage.success({
                    message: res.msg,
                    type: "success",
                  });
                  history.back();
                  isLoading(false);
                  store.state.createUnionNow = false;
                });
              }
            }, config.HTTP_OUT_TIME);

            console.log(res);
          });
      } else if (type == "任命") {
        actionContract.methods
          .requestPlayerAction("setSociatyVP", param.value)
          .send({ from: account })
          .catch((err) => {
            ElMessage.warning({
              message: "用户取消",
              type: "warning",
            });
            isLoading(false);
          })
          .then((res) => {
            let tx;
            tx = res.transactionHash;
            console.log("执行set-");
            setTimeout(function () {
              console.log("执行set+");
              if (!store.state.createUnionNow) {
                createUnionCall(tx).then((res) => {
                  ElMessage.success({
                    message: res.msg,
                    type: "success",
                  });
                  history.back();
                  isLoading(false);
                  store.state.createUnionNow = false;
                });
              }
            }, config.HTTP_OUT_TIME);

            console.log(res);
          });
      } else if (type == "审核") {
        actionContract.methods
          .requestPlayerAction("handleSociatyMemberCache", param.value)
          .send({ from: account })
          .catch((err) => {
            ElMessage.warning({
              message: "用户取消",
              type: "warning",
            });
            isLoading(false);
          })
          .then((res) => {
            let tx;
            tx = res.transactionHash;
            console.log("执行set-");
            setTimeout(function () {
              console.log("执行set+");
              if (!store.state.createUnionNow) {
                createUnionCall(tx).then((res) => {
                  ElMessage.success({
                    message: res.msg,
                    type: "success",
                  });
                  history.back();
                  isLoading(false);
                  store.state.createUnionNow = false;
                });
              }
            }, config.HTTP_OUT_TIME);

            console.log(res);
          });
      }
    };
    return {
      list,
      checkMember,
      kickMenber,
      checkButton,
      type,
      ids,
    };
  },
};
</script>
<style lang="scss" scoped>
@import "../assets/scss/_varibles.scss";
.members {
  background: #f7f8fa;
  margin-top: 0.08rem;
  font-size: 0.14rem;
  width: 100%;
  position: relative;
  //   padding: 0.18rem;
  table {
    width: 100%;
    max-height: 60vh;
    display: block;
    overflow-y: scroll;
    margin-top: 0.16rem;
  }
  ::v-deep .el-checkbox__input.is-checked .el-checkbox__inner {
    background: rgb(206, 36, 121);
    border-color: rgb(206, 36, 121);
    border-radius: 50%;
  }
  ::v-deep .el-checkbox__inner {
    border-radius: 50% !important;
    border-color: #c0c4cc !important;
  }
  .noMenber {
    background: #cccccc !important;
  }
  tr {
    height: 0.4rem;
    line-height: 0.4rem;
    width: 100%;
    background: white;
    border: 1px solid #eeeeee;
    display: flex;
    width: 100%;
    text-align: center;
    > td:first-child {
      text-align: center;
      position: relative;
    }
  }
  tr:first-child {
    // position: fixed;
    top: 2.22rem;
    display: flex;
    width: 100%;
    text-align: center;

    > th {
      color: rgba(247, 71, 158, 1);
    }
    background: #f7f8fa;
  }

  td {
    @include bold();
  }
  th,
  td {
    position: relative;
    img {
      position: absolute;
      width: 0.16rem;
      height: 0.16rem;
      top: 0.07rem;
      right: 0;
      text-align: left;
    }
    &:first-child {
      display: block;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    // &:last-child {
    //   text-align: right;
    //   width: 20%;
    // }
  }
  > button {
    @include primaryButton();
    margin-top: 0.16rem;
  }
}
</style>
