<template>
  <div class="create-union">
    <ul>
      <li
        v-for="(item, index) of tabList"
        :key="index"
        class="item"
        @click="handleChooseMenPai(item)"
      >
        <span>{{ item.sectsName }}</span>
        <span
          :class="{ active: currentI == item.sectsName ? true : false }"
        ></span>
      </li>
    </ul>

    <button
      @click="()=>{
      router.replace({
          path: './createunion',
          query: {
            menpai: currentI,
            token: token,
            tokenName: tokenName,
            Threshold,
          },
        })
      
      }
      "
    >
      确认
    </button>
  </div>
</template>

<script>
import { ref } from "vue";
import { getGameMenPai } from "../apis/httpReq";
import router from "@/router/index.js";
import getToken from "../store/constants";
export default {
  name: "ChooseIcons",
  setup: async () => {
    const tabList = await getGameMenPai();
    let currentI = ref(tabList[0].sectsName);
    let token = ref("");
    let tokenName = ref("");
    let Threshold = ref(Object.keys(tabList[0].sociatyLevelConfig)[0]);
    Object.values(getToken().TokensOptions).forEach((v) => {
      console.log(v);
      if (v.name == tabList[0].token) {
        token.value = v.address;
        tokenName.value = v.name;
      }
    });
    const handleChooseMenPai = (ix) => {
      currentI.value = ix.sectsName;
      Threshold.value= Object.keys(ix.sociatyLevelConfig)[0];
      Object.values(getToken().TokensOptions).forEach((v) => {
        console.log(v);
        if (v.name == ix.token) {
          token.value = v.address;
          tokenName.value = ix.token;
        }
      });
    };
    return { currentI, handleChooseMenPai, router, token, tabList, tokenName,Threshold };
  },
};
</script>
<style lang="scss" scoped>
.item {
  display: flex;
  justify-content: space-between;
}
.active {
  background: url("../assets/images/selected.png") no-repeat 100% / contain;
  width: 0.2rem;
  height: 0.5rem;
}
</style>
