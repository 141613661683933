<template>
    <div class="pickNumber">
        <div class="bg" @click="$emit('close', null)"></div>
        <div class="container">
            <div class="title">
                参与五行八卦阵
            </div>
            <p class="subtitle">
                注入灵气
            </p>
            <p class="subtitle">
                选择中奖凭证
                <span>
                    {{
                        `${removeDecimal(amount, tokenAddr)} ${
                            getConstants().TokensOptions[tokenAddr].name
                        }`
                    }}
                </span>
            </p>
            <p class="pick-hint">
                * 选择方位和两个数字，点击后立即生效
            </p>
            <div class="position">
                <span
                    v-for="(pos, ix) in allPostions"
                    :key="ix"
                    :class="[
                        pos,
                        positionRef === ix ? 'picked' : '',
                        disabledPositionsRef.includes(ix) ? 'disabled' : ''
                    ]"
                    @click="
                        handlePickPostion(ix, disabledPositionsRef.includes(ix))
                    "
                ></span>
            </div>
            <div class="allNumbers">
                <span
                    v-for="number in allNumbers"
                    @click="
                        handlePickNum(number, disabledNumRef.includes(number))
                    "
                    :key="number"
                    :class="[
                        numsRef.includes(number) ? 'picked' : '',
                        disabledNumRef.includes(number) ? 'disabled' : ''
                    ]"
                >
                    {{ number }}
                </span>
            </div>
        </div>
    </div>
</template>

<script>
import { onMounted, ref, reactive, watch } from 'vue';
import getConstants from '../../store/constants';
import { approve } from '../../apis/contractErc20';
import { fillIn } from '../../apis/contractPk';
import { removeDecimal } from '../../utils';

const disabledInfoEffect = (info) => {
    const disabledNumRef = reactive([]);
    const disabledPositionsRef = reactive([]);
    info.players.forEach((v, ix) => {
        if (v !== getConstants().blankAddr) {
            disabledNumRef.push(info.betNum1[ix]);
            disabledNumRef.push(info.betNum2[ix]);
            disabledPositionsRef.push(ix);
        }
    });
    return { disabledNumRef, disabledPositionsRef };
};
const pickPostionEffect = (numsAndPos) => {
    const positionRef = ref();
    const handlePickPostion = (pos, disabled) => {
        if (disabled) return;
        positionRef.value = pos;
    };
    if (numsAndPos) {
        positionRef.value = numsAndPos.position;
    }
    return { positionRef, handlePickPostion };
};
const pickNumEffect = (numsAndPos) => {
    const numsRef = reactive([]);
    const handlePickNum = (num, disabled) => {
        if (disabled) return;
        const ixof = numsRef.indexOf(num);
        if (ixof === -1) {
            if (numsRef.length < 2) {
                numsRef.push(num);
            }
        } else {
            numsRef.splice(ixof, 1);
        }
    };
    if (numsAndPos) {
        numsRef.push(numsAndPos.betNum1);
        numsRef.push(numsAndPos.betNum2);
    }
    return { numsRef, handlePickNum };
};

const getNumsAndPos = (gameInfo) => {
    const blankAddr = getConstants().blankAddr;
    let betNums = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
    let positions = ['0', '1', '2', '3', '4'];
    for (let i in gameInfo.players) {
        if (gameInfo.players[i] !== blankAddr) {
            betNums.splice(betNums.indexOf(gameInfo.betNum1[i]), 1);
            betNums.splice(betNums.indexOf(gameInfo.betNum2[i]), 1);
            positions.splice(positions.indexOf(i), 1);
        }
    }
    const betNum1 = betNums[Math.floor(Math.random() * betNums.length)];
    betNums.splice(betNums.indexOf(betNum1), 1);
    const betNum2 = betNums[Math.floor(Math.random() * betNums.length)];
    const position = positions[Math.floor(Math.random() * positions.length)];
    return {
        position: Number(position),
        betNum1,
        betNum2
    };
};

export default {
    name: 'PickNumber',
    props: [
        'info',
        'tokenAddr',
        'amount',
        'campaignId',
        'betSide',
        'quickStart',
        'blankTableInfo',
        'totalTable'
    ],
    setup(props, context) {
        let tableInfo;
        if (props.quickStart && props.totalTable < 10) {
            tableInfo = reactive(props.blankTableInfo);
        } else {
            tableInfo = reactive(props.info);
        }
        const { disabledNumRef, disabledPositionsRef } = disabledInfoEffect(
            tableInfo
        );

        let numsAndPos;
        if (props.quickStart) {
            numsAndPos = getNumsAndPos(tableInfo);
        }

        const { positionRef, handlePickPostion } = pickPostionEffect(
            numsAndPos
        );
        const { numsRef, handlePickNum } = pickNumEffect(numsAndPos);

        onMounted(async () => {
            await approve(
                props.tokenAddr,
                getConstants().FomoPkAddress,
                props.amount
            );
            if (props.quickStart) {
                await sendTrans();
            }
        });

        let flag = false;
        const sendTrans = async () => {
            if (flag) return;
            flag = true;
            if (numsRef[0] > numsRef[1]) {
                [numsRef[0], numsRef[1]] = [numsRef[1], numsRef[0]];
            }
            const res = await fillIn({
                campaignId: props.campaignId,
                betSide: props.betSide,
                gameNum: tableInfo.gameNum,
                conflictOption: '1',
                betNum1: numsRef[0],
                betNum2: numsRef[1],
                position: positionRef.value
            });
            if (res) {
                context.emit(
                    'close',
                    res?.events?.FillIn?.returnValues?.gameNum
                );
            } else {
                positionRef.value = undefined;
                numsRef.splice(0);
            }
            flag = false;
        };

        watch([numsRef, positionRef], async () => {
            if (flag) return;
            if (numsRef.length === 2 && positionRef.value !== undefined) {
                await sendTrans();
            }
        });
        return {
            tableInfo,
            allNumbers: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0'],
            allPostions: ['jin', 'mu', 'shui', 'huo', 'tu'],
            positionRef,
            handlePickPostion,
            numsRef,
            handlePickNum,
            disabledNumRef,
            disabledPositionsRef,
            getConstants,
            removeDecimal
        };
    }
};
</script>
