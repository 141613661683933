<template>
  <div class="JHmenpai">
    <div class="JH_title">
      <span class="JH_bagua"></span>
      <span class="JH_home" @click="goHome"></span>
    </div>
    <div class="JH_lay">
      <div class="JH_item">
        <p>
          <span class="yellow" @click="goSpringInfo"> </span>
          <span>
            <span class="JH_left_syb"></span>
            <span class="yellow mar_tile">{{ poolName }}收益</span>
            <span class="JH_right_syb"></span>
          </span>
          <span class="yellow chkInfo" @click="goSpringInfo(1)">
            查看明细
          </span>
        </p>
        <p style="margin: 0.3rem 0 0.2rem 0" class="item_money pink">
          {{ addDecimal(parseInt(info.assets_reward_sum), token) }}
        </p>
        <p>
          <span class="getBreath" @click="getLingqis"
            >提取{{ tokenAlias }}</span
          >
        </p>
      </div>
      <div class="JH_item">
        <p style="margin: 0.1rem 0 0.22rem 0">
          <span>
            <span class="JH_left_syb"></span>
            <span class="yellow mar_tile">合成分解</span>
            <span class="JH_right_syb"></span>
          </span>
          <span class="yellow chkInfo" @click="goSpringInfo(3)">
            查看明细
          </span>
        </p>
        <div class="itemB">
          <span>可用{{ tokenAlias }} </span>
          <span class="text-a pink"
            >{{ removeDecimal(tokenBalance, token) }}
            <span class="getBreath yellow" @click="goFix('合成')"
              >合成{{ currentN }}</span
            ></span
          >
        </div>
        <div class="itemB">
          <p class="flex-c">
            <span style="text-align: left">可用{{ currentN }} </span>
            <span style="font-size: 0.08rem; margin-top: 0.06rem"
              >(活期质押量+锁仓到期量)</span
            >
          </p>
          <span class="text-a pink" style="line-height: 0.36rem"
            >{{ currentStock }}
            <span class="getBreath yellow" @click="goFix('分解')"
              >分解{{ currentN }}</span
            ></span
          >
        </div>
      </div>
      <div class="JH_item">
        <p style="margin: 0.1rem 0 0.22rem 0">
          <span class="JH_left_syb"></span>
          <span class="yellow mar_tile">活期锁仓</span>
          <span class="JH_right_syb"></span>
        </p>
        <div class="itemB">
          <span> 当前质押{{ tokenAlias }}量</span>
          <span class="pink">{{ info.aura_amount }} </span>
        </div>
        <!-- <div class="itemB">
          <span> 当前可提取灵气量</span>
          <span class="pink">  99999 <span class="getBreath"></span></span>
        </div> -->
      </div>
      <div class="JH_item">
        <p
          style="margin: 0.1rem 0 0.22rem 0"
          v-if="gouList && gouList.length > 0"
        >
          <span class="JH_left_syb"></span>
          <span class="yellow mar_tile">定期锁仓</span>
          <span class="JH_right_syb"></span>
        </p>
        <p style="margin: 0.1rem; font-size: 0.08rem">
          *锁仓到期灵石自动转为活期
        </p>
        <!-- {{ gouList[0].duration }} -->
        <div class="itemB" v-for="item of gouList" :key="item">
          <span
            >{{
              item.duration
                ? "锁仓" + item.duration / (24 * 60 * 60) + "天"
                : "活期"
            }}
            <span class="size_13 yellow"
              >{{ Number(item.apy * 100).toFixed(3) }}%</span
            ></span
          >
          <span class="text-a"
            >锁仓数量 <span class="pink">{{ item.amount }}</span></span
          >
        </div>
      </div>
      <!-- <el-collapse v-model="activeNames" @change="handleChange">
        <el-collapse-item title="一致性 Consistency" name="1">
       <table>
         <tr>
           <th>

           </th>
         </tr>
         <tr>
           <td>

           </td>
         </tr>
       </table>
        </el-collapse-item>
  
      </el-collapse> -->
    </div>
  </div>
</template>

<script>
import { ref } from "@vue/reactivity";
import {
  getGameMenPai,
  getLingqi,
  getSpringInfo,
  getPlayeMoney,
  getGrou,
  getDrawAura,
} from "../../apis/httpReq";
import { ElMessage } from "element-plus";
import router from "@/router/index.js";
import {
  parseTime,
  getAccount,
  addDecimal,
  removeDecimal,
  getErc20Contract,
  isLoading,
} from "../../utils";
import getToken from "../../store/constants";

export default {
  name: "spring",

  async setup(props, context) {
    // let tabList = ["灵宗派", "火月派", "黄金门", "泰达派", "墨易门"];
    const tabList = await getGameMenPai();
    let account = await getAccount();
    let currentM = ref(router.currentRoute.value.query.token);

    let token = router.currentRoute.value.query.tokenAddr;
    let info = ref(await getSpringInfo(account, currentM.value));
    let tokenAlias = router.currentRoute.value.query.tokenAlias;
    let poolName = router.currentRoute.value.query.poolName;
    let currentN = router.currentRoute.value.query.name;
    let contract = getErc20Contract(token);
    let tokenBalance = ref(await contract.methods.balanceOf(account).call());
    let playerMoney = await getPlayeMoney(account);
    let currentStock;
    let gouList = await getGrou(account, currentM.value);
    console.log(gouList, 2222222222);
    playerMoney.map((v) => {
      if (v.token_name == currentM.value) {
        console.log(11);
        if (v.regular_frozen_amount <= v.frozen_amount) {
          currentStock =
            v.amount - v.frozen_amount > 0 ? v.amount - v.frozen_amount : 0;
        }else{
           currentStock =
            v.amount - v.regular_frozen_amount > 0 ? v.amount - v.regular_frozen_amount : 0;
        }
      }
    });
    const goFix = (type) => {
      router.push({
        path: "./fix",
        query: {
          tokenAddr: token,
          token: currentM.value,
          name: currentN,
          type,
          tokenAlias: tokenAlias,
        },
      });
    };
    const goSpringInfo = (type) => {
      router.push({
        path: "/springInfo",
        query: {
          tokenAddr: token,
          token: currentM.value,
          name: currentN.value,
          type: type,
        },
      });
    };
    const goHome = () => {
      router.push({
        path: "./",
        query: {},
      });
    };
    const getLingqis = () => {
      isLoading(true);
      getLingqi(account, currentM.value).then(async (res) => {
        info.value = await getSpringInfo(account, currentM.value);
        ElMessage.success({
          message: "提取成功",
          type: "warning",
        });
        isLoading(false);
      });
    };
    return {
      tabList,
      tokenAlias, //灵气
      poolName, //token的区域灵泉等
      tokenBalance,
      goFix,
      gouList,
      currentN,
      goSpringInfo,
      goHome,
      getLingqis,
      info,
      currentStock,
      addDecimal,
      removeDecimal,
      token,
    };
  },
};
</script>

<style lang="scss" scoped>
.JHmenpai {
  background: black;
  color: #fffdfc;
  padding-bottom: 0.2rem;
  font-size: 0.14rem;
  .but-img {
    height: 0.2rem;
    align-items: center;
    justify-content: center;
    display: inline-flex;
    color: rgb(232, 194, 120);
    background-size: cover;
    padding: 0.05rem 0.08rem;
    margin-left: 0.01rem;
  }
  .size_13 {
    font-size: 0.13rem;
  }
  .flex-c {
    display: flex;
    flex-direction: column;
  }
  .text-a {
    text-align: right;
  }
  .chkInfo {
    position: absolute;
  }
  .fix {
    @extend .but-img;
    background: url("../../assets/images/分解灵气.png") 100% no-repeat;
  }
  .split {
    @extend .but-img;
    background: url("../../assets/images/合成灵石.png") 100% no-repeat;
    margin-right: 0.1rem;
  }
  .getBreath {
    @extend .but-img;
    background: url("../../assets/images/home_btn_bg.png") 100% no-repeat;
  }
  .flex {
    display: flex;
    justify-content: space-between;
    padding: 0.1rem 0.16rem;
    position: relative;
  }
  .itemB {
    background: #120d11;
    display: flex;
    justify-content: space-between;
    padding: 0.1rem 0.16rem;
    border-radius: 4px;
    border: 1px solid #707b81;
    margin: 0.1rem;
  }
  .mar_tile {
    margin: 0 0.1rem;
  }
  .yellow {
    color: #e5cf80;
  }
  .chkTab {
    background: #312c2f !important;
    color: #f7479e;
  }
  .JH_item {
    padding: 0.2rem 0;
    .item_money {
      font-size: 0.2rem;
    }
    p {
      text-align: center;
      word-break: break-all;
      letter-spacing: 0.01rem;
    }
  }
  .JH_lay {
    background: #120d11;
    margin: 0.1rem 0.16rem;
  }
  .JH_title {
    display: flex;
    justify-content: space-between;
    padding: 0.1rem 0.16rem;
    background: #1b1a1e;
  }
  .JH_tab {
    color: #999999;
    margin-bottom: 0.26rem;
    ul {
      display: flex;
      li {
        list-style: none;
        width: 20%;
        // height: 0.6rem;
        padding: 0.18rem 0;
        background: #1a1518;
      }
      li:first-child {
        border-top-left-radius: 0.1rem;
        border-bottom-left-radius: 0.1rem;
      }
      li:last-child {
        border-top-right-radius: 0.1rem;
        border-bottom-right-radius: 0.1rem;
      }
    }
  }
}
</style>
