<template>
  <div class="JHmenpai">
    <div class="JH_title">
      <span class="JH_bagua"></span>
      <span class="JH_home" @click="goHome"></span>
    </div>
    <div class="JH_lay">
      <div class="JH_tab">
        <ul>
          <li
            v-for="(item, index) of tabList"
            :key="index"
            @click="chkTab(item)"
            :class="{ chkTab: currentI == item.sectsName ? true : false }"
          >
            {{ item.sectsName }}
          </li>
        </ul>
      </div>
      <div class="JH_item">
        <p style="margin: 0.1rem 0">
          <span class="JH_left_syb"></span>
          <span class="yellow mar_tile">{{ currentI }}({{ currentM }})</span>
          <span class="JH_right_syb"></span>
        </p>
        <p style="margin: 0.2rem 0">门派介绍:</p>
        <p style="margin-bottom: 0.2rem">
          {{ currentInfo.sect_desc }}
        </p>
        <div class="itemB">
          <span> 门派成员数量</span>
          <span class="pink"> {{ currentInfo.sociaty_member_count }}</span>
        </div>
        <div class="itemB">
          <span> 公会数量</span>
          <span class="pink"> {{ currentInfo.sociaty_count }}</span>
        </div>
      </div>
      <div class="JH_item">
        <p style="margin: 0.1rem 0">
 
          <span>
            <span class="JH_left_syb"></span>
            <span class="yellow mar_tile">{{poolName}}</span>
            <span class="JH_right_syb"></span>
          </span>
          <!-- <span class="yellow chkInfo" @click="goSpringInfo"> 查看明细 </span> -->
        </p>

        <p style="margin: 0.1rem; font-size: 0.08rem">*圣物合成所</p>
        <p style="margin-bottom: 0.1rem" class="flex">
          <span>{{tokenAlias}}质押池总量</span>
          <span class="pink">{{addDecimal(currentInfo.total_pledge_token,token) }}</span>
        </p>
        <p style="margin-bottom: 0.1rem" class="flex">
          <span>年化收益率</span>
          <span class="pink"
            >{{ currentInfo.profit_year_rate?(currentInfo.profit_year_rate * 100).toFixed(2):currentInfo.profit_year_rate}}%<span
              class="Apy"
              @click="goApy"
              >?</span
            ></span
          >
        </p>
        <!-- <div class="itemB">
          <span>当前质押灵气量</span>
          <span class="pink">{{ currentInfo.sociaty_count }}</span>
        </div>
        <div class="itemB">
          <span>当前灵气收益量</span>
          <span>
            <span class="pink">{{ currentInfo.sociaty_count }}</span>
          </span>
        </div> -->
        <p style="margin-top: 0.2rem">
              <span class="split" @click="goSpring" style="margin:0"> 进入{{poolName}} </span>
          <!-- <span class="split" @click="goFix('合成')">合成{{ currentN }}</span>
          <span class="fix" @click="goFix('分解')">分解{{ currentN }}</span> -->
        </p>
      </div>
      <div class="JH_item">
        <p style="margin: 0.1rem 0 0.22rem 0">
          <span class="JH_left_syb"></span>
          <span class="yellow mar_tile">矿山保卫情况</span>
          <span class="JH_right_syb"></span>
        </p>
        <div class="itemB">
          <span> 争夺中</span>
          <span class="pink">{{ currentInfo.attacking_mine_count }}</span>
        </div>
        <div class="itemB">
          <span> 开采中</span>
          <span class="pink">{{ currentInfo.minting_mine_count }}</span>
        </div>
        <div class="itemB">
          <span> 报名中</span>
          <span class="pink">{{ currentInfo.signup_mine_count }} </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "@vue/reactivity";
import { getGameMenPai } from "../../apis/httpReq";
import router from "@/router/index.js";
import getToken from "../../store/constants";
import {  addDecimal} from "../../utils";
export default {
  name: "JHmenpai",

  async setup(props, context) {
    // let tabList = ["灵宗派", "火月派", "黄金门", "泰达派", "墨易门"];
    console.log(router.currentRoute.value.query);
    const tabList = await getGameMenPai();
    let token = ref("");
    let currentM = ref("");
    let tokenAlias = ref("");
      let poolName = ref("");
    let currentI = router.currentRoute.value.query.menpai
      ? ref(router.currentRoute.value.query.menpai)
      : ref(tabList[0].sectsName);
    let currentInfo = ref("");
    tabList.forEach((v) => {
      v.token == currentI
        ? (currentInfo.value = v)
        : (currentInfo.value = tabList[0]);
    });

    let currentN = ref("");
    console.log(currentI);
    tabList.forEach((v) => {
      if (
        v.sectsName == router.currentRoute.value.query.menpai ||
        v.sectsName == currentI.value
      ) {
        currentM.value = v.token;
        currentN.value = v.name;
        tokenAlias.value=v.tokenAlias;
        poolName.value=v.poolName;
        console.log(currentN);
      }
    });
    Object.values(getToken().TokensOptions).forEach((v) => {
      console.log(v);
      if (v.name == currentM.value) {
        token.value = v.address;
      }
    });
    const goFix = (type) => {
      router.push({
        path: "./fix",
        query: {
          tokenAddr: token.value,
          token: currentM.value,
          name: currentN.value,
          type,
        },
      });
    };
    const goSpring = () => {
      router.push({
        path: "./spring",
        query: {
          tokenAddr: token.value,
          token: currentM.value,
          name: currentN.value,
          poolName:poolName.value,
          tokenAlias:tokenAlias.value
        },
      });
    };
    const goSpringInfo = () => {
      router.push({
        path: "/springInfo",
        query: {
          tokenAddr: token.value,
          token: currentM.value,
          name: currentN.value,
          type: 3,
        },
      });
    };
    const goApy = () => {
      router.push({
        path: "/Apy",
        query: {profit_year_rate:currentInfo.value.profit_year_rate?(currentInfo.value.profit_year_rate * 100).toFixed(3):currentInfo.value.profit_year_rate,type:'apy',token: currentM.value,},
      });
    };
    const goHome = () => {
      router.push({
        path: "./",
        query: {},
      });
    };

    // console.log(list);

    const chkTab = (item) => {
      currentI.value = item.sectsName;
      currentM.value = item.token;
      currentN.value = item.name;
              tokenAlias.value=item.tokenAlias;
        poolName.value=item.poolName;
      currentInfo.value = item;
      Object.values(getToken().TokensOptions).forEach((v) => {
        console.log(v);
        if (v.name == item.token) {
          token.value = v.address;
        }
      });
    };
    return {
      tabList,
      currentI,
      currentM,//token
      tokenAlias,//灵气
      poolName,//token的区域灵泉等
      currentN,//token名字
      addDecimal,
      token,
      chkTab,
      goFix,
      goSpring,
      goSpringInfo,
      goApy,
      goHome,
      currentInfo,
    };
  },
};
</script>

<style lang="scss" scoped>
.JHmenpai {
  background: black;
  color: #fffdfc;
  font-size: 0.13rem;
  padding-bottom: 0.2rem;
  .Apy {
    display: inline-flex;
    width: 0.2rem;
    height: 0.2rem;
    border-radius: 50%;
    background: #f7479e;
    color: black;
    align-items: center;
    font-weight: 700;
    justify-content: center;
  }
  .but-img {
    height: 0.2rem;
    vertical-align: text-bottom;
    display: inline-block;
    background-size: cover;
    display: inline-flex;
    align-items: center;
    padding: 0.05rem 0.08rem;
    justify-content: center;
    color: rgb(232,194,120)
  }
  .fix {
    @extend .but-img;
    background: url("../../assets/images/home_btn_bg.png") 100% no-repeat;
  }
  .split {
    @extend .but-img;
    background: url("../../assets/images/home_btn_bg.png") 100% no-repeat;
    margin-right: 0.1rem;
  }
  .getBreath {
    @extend .but-img;
    background: url("../../assets/images/提取灵气.png") 100% no-repeat;
  }
  .flex {
    display: flex;
    justify-content: space-between;
    padding: 0.1rem 0.16rem;
  }
  .itemB {
    background: #120d11;
    display: flex;
    justify-content: space-between;
    padding: 0.1rem 0.16rem;
    border-radius: 4px;
    border: 1px solid #707b81;
    margin-top: 0.1rem;
  }
  .mar_tile {
    margin: 0 0.1rem;
  }
  .yellow {
    color: #e5cf80;
  }
  .chkTab {
    background: #312c2f !important;
    color: #f7479e;
  }
  .JH_item {
    p {
      text-align: center;
      word-break: break-all;
      letter-spacing: 0.01rem;
      line-height: 0.18rem;
    }
  }
  .JH_lay {
    background: #120d11;
    margin: 0.1rem 0.16rem;
  }
  .JH_title {
    display: flex;
    justify-content: space-between;
    padding: 0.1rem 0.16rem;
    background: #1b1a1e;
  }
  .chkInfo {
    position: absolute;
  }
  .JH_tab {
    color: #999999;
    margin-bottom: 0.26rem;
    ul {
      display: flex;
      li {
        list-style: none;
        width: 20%;
        // height: 0.6rem;
        padding: 0.18rem 0;
        background: #1a1518;
      }
      li:first-child {
        border-top-left-radius: 0.1rem;
        border-bottom-left-radius: 0.1rem;
      }
      li:last-child {
        border-top-right-radius: 0.1rem;
        border-bottom-right-radius: 0.1rem;
      }
    }
  }
}
</style>
