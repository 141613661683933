import { getTokenExchangeContract } from '../utils';
import Decimal from 'decimal.js';
import getConstants from "../store/constants";

export const getTokenAmount = async (tokenAddr, value) => {
    const basicValue = Math.pow(10, 18);
    try {
        const rate = await getTokenExchangeContract()
            .methods.getTokenAmount(tokenAddr, basicValue.toString())
            .call();
        const bigInt = new Decimal(value / basicValue).mul(rate).toNumber();
        const res = scientificNotationToString(bigInt);
        return res;
    } catch (e) {
        console.log(e);
        return null;
    }
};

/**
 *
 * @param tokenAddr
 * @param value
 * @returns {Promise<number>} 1个token值几个usdt
 */
export const getTokenToUsdtPrice = async (tokenAddr) => {
    const tokenInfo = getConstants().TokensOptions[tokenAddr]
    const baseValue = Math.pow(10, tokenInfo.decimal)
    try {
        let price = await getTokenExchangeContract().methods.getUsdtAmount(tokenAddr, baseValue.toString()).call();
        price = new Decimal(price).div(Math.pow(10, 18))//usdt默认18decimal
        return price.toNumber();
    }catch (e) {
        console.error(e);
        return 0
    }
}

function scientificNotationToString(param) {
    let strParam = param.toString();
    let flag = /e/.test(strParam);
    if (!flag) return param;

    // 指数符号 true: 正，false: 负
    let sysbol = true;
    if (/e-/.test(strParam)) {
        sysbol = false;
    }
    // 指数
    let index = Number(strParam.match(/\d+$/)[0]);
    // 基数
    let basis = strParam.match(/^[\d.]+/)[0].replace(/\./, '');

    if (sysbol) {
        return basis.padEnd(index + 1, 0);
    } else {
        return basis.padStart(index + basis.length, 0).replace(/^0/, '0.');
    }
}
