<template>
    <div class="line">
        <span
            class="attack"
            :style="{
                width: `${powersWidth.attack}%`,
                borderRadius: powersWidth.attack === 100 ? '.15rem' : ''
            }"
        ></span>
        <span
            class="defence"
            :style="{
                width: `${powersWidth.defence}%`,
                borderRadius: powersWidth.defence === 100 ? '.15rem' : ''
            }"
        ></span>
        <span class="attack-icon">攻</span>
        <span class="attack-power">{{ powers?.[0] }}</span>
        <span class="defence-icon">守</span>
        <span class="defence-power">{{ powers?.[1] }}</span>
    </div>
</template>

<script>
export default {
    name: 'PowerLine',
    props: ['powers', 'powersWidth']
};
</script>
