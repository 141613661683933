import { createRouter, createWebHashHistory } from 'vue-router';

import Home from '../views/Home.vue';
let routes = [
    {
        path: '/',
        name: 'index',
        component: Home,
        meta: {
            isIndex: true,
            alive: true
        }
    },
    {
        path: '/map',
        name: 'Map',
        component: () => import('../views/Map.vue'),
        meta: {
            title: '矿山保卫战'
        }
    },
    {
        path: '/unionstatus',
        name: 'UnionStatus',
        component: () => import('../views/UnionStatus.vue'),
        meta: {
            title: '公会状态'
        }
    },
    {
        path: '/unionlist',
        name: 'UnionList',
        component: () => import('../views/UnionList.vue'),
        meta: {
            title: '公会列表'
        }
    },
    {
        path: '/fix',
        name: 'fix',
        component: () => import('../components/homepage/homeFix.vue'),
        meta: {
            title: '分解合成'
        }
    },
    {
        path: '/chooseToken',
        name: 'chooseToken',
        component: () => import('../views/chooseToken.vue'),
        meta: {
            title: '报名详情',
            alive: true
        }
    },
    {
        path: '/chooseMoney',
        name: 'chooseMoney',
        component: () => import('../views/chooseMoney.vue'),
        meta: {
            title: '选择币种'
        }
    },
    {
        path: '/createunion',
        name: 'CreateUnion',
        component: () => import('../views/CreateUnion.vue'),
        meta: {
            title: '创建公会',
            alive: true
        }
    },
    {
        path: '/joinunion',
        name: 'joinUnion',
        component: () => import('../views/joinUnion.vue'),
        meta: {
            title: '加入公会',
            alive: true
        }
    },
    {
        path: '/chooseicons',
        name: 'ChooseIcons',
        component: () => import('../views/ChooseIcons.vue'),
        meta: {
            title: '选择公会队标'
        }
    },
    {
        path: '/chooseiconMen',
        name: 'chooseiconMen',
        component: () => import('../views/ChooseMenPai.vue'),
        meta: {
            title: '选择门派',
            alive: true
        }
    },
    {
        path: '/pk',
        name: 'PK',
        component: () => import('../views/PK.vue'),
        meta: {
            title: '矿山保卫战'
        }
    },
    {
        path: '/rule',
        name: 'rule',
        component: () => import('../components/minePage/rule.vue'),
        meta: {
            title: '规则'
        }
    },
    {
        path: '/package',
        name: 'package',
        component: () => import('../components/homepage/homePackage.vue'),
        meta: {
            title: '我的背包'
        }
    },
    {
        path: '/springInfo',
        name: 'springInfo',
        component: () => import('../components/homepage/homeInfo.vue'),
        meta: {
            title: '明细'
        }
    },
    {
        path: '/Apy',
        name: 'Apy',
        component: () => import('../components/homepage/homeApy.vue')
    },
    {
        path: '/spring',
        name: 'spring',
        component: () => import('../components/homepage/homeSpring.vue'),
        meta: {
            title: '圣物收益'
        }
    },
    {
        path: '/jh',
        name: 'jh',
        component: () => import('../components/homepage/homeJH.vue'),
        meta: {
            title: '江湖门派'
        }
    },
    {
        path: '/mountstatus',
        name: 'MountStatus',
        component: () => import('../views/MountStatus.vue'),
        meta: {
            title: '矿山状态'
        }
    },
    {
        path: '/kick',
        name: 'kick',
        component: () => import('../components/kickTable.vue'),
        meta: {
            title: '踢出公会'
        }
    },
    {
        path: '/tablesList',
        name: 'TablesList',
        component: () => import('../views/TablesList.vue'),
        meta: {
            title: '投注记录'
        }
    }
];

const router = createRouter({
    history: createWebHashHistory(process.env.BASE_URL),
    routes
});
//路由守卫
router.beforeEach((to, from, next) => {
    if (to.name == 'fix') {
        to.meta.title = to.query.type + to.query.name

    }
    if (to.name == 'kick') {
        if (to.query.type == '任命') {
            to.meta.title = '任命列表';
        } else if (to.query.type == '审核') {
            to.meta.title = '入会审核列表';
        } else {
            to.meta.title = to.query.type + '公会'
        }
       
    }
    next()
});
export default router;
