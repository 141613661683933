<template>
    <div :class="['table', isSmall ? 'smallTxt' : '']">
        <div
            @click="
                () => {
                    goToLink(status, info.hitBlockHash);
                }
            "
            :class="[
                'centerConWarp',
                status === statusType.Playing || status === statusType.Ready
                    ? 'logo'
                    : ''
            ]"
        >
            <div
                v-if="
                    status !== statusType.Playing && status !== statusType.Ready
                "
                class="centerCon"
            >
                <p v-if="status === statusType.End">
                    {{ hashArray[0]
                    }}<span class="highlight">{{ hashArray[1] }}</span
                    >{{ hashArray[2] }}
                </p>
                <p v-else>{{ centerStr }}</p>
            </div>
        </div>
        <div
            v-for="(v, ix) in positions"
            :key="ix"
            :class="[
                v,
                'item',
                info.players[ix] && info.players[ix] !== blankAddr
                    ? info.players[ix] === account
                        ? 'myPick'
                        : 'othersPick'
                    : ''
            ]"
        >
            <img
                v-if="
                    status === statusType.End &&
                        Number(info.hitPosition) === ix &&
                        info.players[ix] === account
                "
                class="winner"
                alt=""
                src="../../assets/images/selfWin.png"
            />
            <img
                v-if="
                    status === statusType.End &&
                        Number(info.hitPosition) === ix &&
                        info.players[ix] !== account
                "
                class="winner"
                alt=""
                src="../../assets/images/othersWin.png"
            />
            <span
                v-if="info.players[ix] && info.players[ix] !== blankAddr"
                class="firstNum"
            >
                {{ info.betNum1[ix] }}
            </span>
            <span
                v-if="info.players[ix] && info.players[ix] !== blankAddr"
                class="secondNum"
            >
                {{ info.betNum2[ix] }}
            </span>
        </div>
    </div>
</template>

<script>
import getConstants from '../../store/constants';
import { getAccount } from '../../utils';
const statusType = {
    Ready: '0',
    Playing: '1',
    Blocking: '2',
    End: '3',
    Cancel: '4'
};
const blankAddr = getConstants().blankAddr;
const getCenterInfo = (hitBlockHash, status) => {
    const hashArray = ['', '', ''];
    if (hitBlockHash && hitBlockHash !== blankAddr) {
        const hash = hitBlockHash.substring(hitBlockHash.length - 8);
        for (let i = hash.length - 1; i >= 0; i--) {
            const c = hash[i];
            if (!isNaN(c)) {
                hashArray[1] = c;
                hashArray[0] = hash.substring(0, i);
                break;
            } else {
                hashArray[2] += c;
            }
        }
    }
    let centerStr = '';
    switch (status.toString()) {
        case statusType.Blocking:
            centerStr = '开奖中';
            break;
        case statusType.End:
            centerStr = '已结束';
            break;
        case statusType.Cancel:
            centerStr = '已取消';
            break;
        default:
            break;
    }
    return { hashArray, centerStr };
};
const goToLink = (status, hash) => {
    if (status === statusType.End) {
        //test
        //window.open(`https://testnet.hecoinfo.com/block/${hash}`);
        //prod
        window.open(`https://hecoinfo.com/block/${hash}`);
    }
};
export default {
    name: 'Table',
    props: ['info', 'isSmall'],
    async setup(props) {
        console.log(props.info);
        const account = await getAccount();
        const positions = ['jin', 'mu', 'shui', 'huo', 'tu'];
        const { hashArray, centerStr } = getCenterInfo(
            props.info.hitBlockHash,
            props.info.status
        );

        return {
            positions,
            blankAddr,
            account,
            statusType,
            hashArray,
            centerStr,
            status: props.info.status.toString(),
            goToLink
        };
    }
};
</script>
