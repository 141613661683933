<template>
  <div class="index">
      <div class="layout home_top"></div>
      <div class="bright"></div>
      <div class="content">
          <div class="content_lay">
              <div class="content_item">
                
                <p>AURA流通量 </p>
                <ul>
                  <li v-for="(item,index) of getAuraList()" :key="index" class="pink">
                    {{item}}
                  </li>
                  </ul>
              </div>
              <div class="content_item hasBg2">
                  <p><span class="left_syb"></span>AURA数据 <span class="right_syb"></span></p>
           
                  <div class="item_half">
                      <span class="mar_item_f">质押量</span>
                      <span class="pink">{{infoList[0].pledge_amount}}</span>
                  </div>
                  <div class="item_half">
                      <span class="mar_item_f">APY</span>
                      <span class="pink">{{ infoList[0].profit_year_rate?(infoList[0].profit_year_rate * 100).toFixed(2):infoList[0].profit_year_rate}}%</span>
                  </div>
                         <div class="item_half ">
                      <span class="mar_item_f">销毁量</span>
                      <span class="pink">{{infoList[0].release_amount}}</span>
                  </div>
                  <div class="item_half">
                      <span class="mar_item_f">价格</span>
                      <span class="pink">{{( renderPrice(infoList[0].price,2))}}</span>
                  </div>
              </div>
              <div class="content_item hasBg3">
                  <div class="item_half">
                    <span class="mar_item_l">持有用户数</span>
                    <span class="pink">{{infoList[0].holders_count}}</span>
                  </div>
                  <div class="item_half">
                    <span class="mar_item_l">上链数量</span>
                    <span class="pink">{{infoList[0].transaction_count}}</span>
                  </div>
              </div>
          </div>
      </div>
      <div class="layout home_bot"></div>
    </div>
</template>

<script>
import router from "@/router/index.js";
import {getIndexInfo}from"../../apis/httpReq";
import { addDecimalByName,removeDecimal,renderPrice } from "../../utils";
export default {
  name: "Homeindex",
   async setup () {
    const AURAList = [1, 2, 3, 4, 5, 6, 7];
    const infoList= await getIndexInfo();
    const getAuraList=()=>{
      return (infoList[0].total_supply+'').split('')
    }
    console.log(infoList)
    return { AURAList, router,infoList,getAuraList,addDecimalByName,removeDecimal,renderPrice};
  },
};
</script>

<style lang="scss" scoped>
.index {
  position: relative;
  .bright {
      position: absolute;
      width: 0.65rem;
      height: 0.73rem;
      right: 0;
      top: 0.2rem;
      z-index: 100;
    }
  .content {
    height: calc(100vh - 2.2rem);
    color: #fffdfc;
    font-size: 0.13rem;
    .content_lay {
      border-left: 0.16rem solid rgb(23, 31, 42);
      border-right: 0.16rem solid rgb(23, 31, 42);
      padding-bottom: 0.04rem;
      margin: 0 0.1rem;
      background: #120d11;
      height: 100%;
      overflow-y: auto;
      overflow-x: hidden;
      position: relative;
      .content_item {
        margin: 0.1rem 0.08rem;
        border-radius: 0.1rem;
        padding: 0.16rem;
        background: url("../../assets/images/home/bg1.png") 100% no-repeat;
        background-size: cover;
        border: 1px solid rgba(219, 146, 10,0.2);
        >p{
          margin-bottom: 0.1rem;
        }
        .item_half {
          display: inline-flex;
          flex-direction: column;
          width: 50%;
          span{
            line-height: 0.2rem;
          }
        }
        ul {
          display: inline-flex;
          li {
            list-style: none;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            width: 0.17rem;
            height: 0.2rem;
            margin-right: 0.06rem;
            background: #383032;
          }
        }
      }
      .hasBg2{
         background: url("../../assets/images/home/bg2.png") 100% no-repeat;
         background-size: cover;
         height: 1.86rem;
      }
      .hasBg3{
         background: url("../../assets/images/home/bg3.png") 100% no-repeat;
         background-size: cover;
         height: 0.88rem;
      }
      .hasBg4{
         background: url("../../assets/images/home/bg4.png") 100% no-repeat;
         background-size: cover;
         height: 1.4rem;
         .otherCurrency{
              display: flex;
              flex-direction: column;
              align-items: center;
              .mar_item_f{
                display: flex;
                align-items: center;
                &:first-child{
                  margin-bottom: 0;
                }
                div{
                  width: 1rem;
                  text-align: left;

                }
              }
            img{
              width: 0.24rem;
              height: 0.24rem;
              margin-right: 0.14rem;
            }

         }
         
      }
    }
  }
  .layout {
    position: absolute;
    height: 0.2rem;
    width: 100%;
    background: url("../../assets/images/jz.png") 100% no-repeat;
    background-position: center;
    position: relative;
    background-size: cover;
  }
  .home_top {
    top: 0;
    left: 0;
  }
  .home_bot {
    bottom: 0;
    left: 0;
    padding-top: 0.1rem;
  }
  .mar_item_f {
    margin: 0.2rem 0 0.1rem 0;
  }
  .mar_item_l {
    padding-top: 0.04rem;
    margin: 0 0 0.1rem 0;
  }
}
</style>
