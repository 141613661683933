import {getAccount, getActionContract, isLoading } from '../utils';
import { ElMessage } from "element-plus";
export const createUnion = async (params) => {
    isLoading(true);
    let action = getActionContract();
    let account = await getAccount();
    try {
        let tx = await action.methods
            .requestCreateGuild(params.tokenAddress, params.name,params.logo,params.pledgeAmount,params.joinGemThreshold)
            .send({from:account});
        console.log(tx)
        // isLoading(false);
        return tx.transactionHash;
    } catch (e) {
        if(e.code==4001){
            ElMessage.warning({
                message: "用户取消",
                type: "warning",
              });
        }else{
            ElMessage.warning({
                message: e.message||'出错啦！',
                type: "warning",
              });
        }
          
            isLoading(false);
            // return
        return null;
    } finally {
        // 外层有响应后再 关掉菊花
        // isLoading(false);
    }
};

//占领无主矿山
export const attackMine = async (params) => {
    console.log(params,"占领矿山ID")
    isLoading(true);
    let action = getActionContract();
    let account = await getAccount();
    try {
        let tx = await action.methods
            .requestAttackMine(params.mineId,params.tokenAddress)
            .send({from:account});
        isLoading(false);
        return tx.transactionHash;
    } catch (e) {
        console.log(e);
        return null;
    } finally {
        isLoading(false);
    }
};