import axios from "axios";
import qs from "qs";
import getConstants from "./store/constants";
import { isLoading } from "./utils";
import { ElMessage } from "element-plus";

axios.defaults.baseURL = `${getConstants().serverLink}/api`;

axios.defaults.headers.post["Content-Type"] =
  "application/x-www-form-urlencoded;charset=UTF-8";

axios.defaults.timeout = 10 * 10000;

axios.interceptors.request.use(
  (config) => {
    isLoading(true);
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => {
    isLoading(false);
    return response;
  },
  (error) => {
    isLoading(false);
    console.log(error);
  }
);

export default function axiosApi(url, params, method = "get") {
  let data = method === "post" ? qs.stringify(params) : params;
  return new Promise((resolve, reject) => {
    axios({
      method: method,
      url: url,
      data: data,
    })
      .then((res) => {
        if (res?.status === 200) {
          if (res.data.code === 0) {
            isLoading(false);
            resolve(res.data.data);
          } else if (res.data.code === 2) {
            isLoading(false);
            resolve(res.data);
          } else {
            ElMessage.warning({
              message: res.data.msg,
              type: "warning",
            });
          }
        } else {
          console.log(res);
          ElMessage.warning({
            message: "网络错误",
            type: "warning",
          });
        }
      })
      .catch((err) => {
        ElMessage.warning({
          message: "网络错误",
          type: "warning",
        });
        reject(err);
      });
  });
}
