<template>
  <div class="pk">
    <div class="top-section">
      <div class="info-container">
        <div class="info">
          <span>{{ mine.name }}({{ getBonusPosi(mine.bonus_position) }})</span>
        </div>
        <Countdown
          v-if="
            mine.next_state_time && mine.next_state_time * 1000 > currentTime
          "
          :endTime="mine.next_state_time"
          @timeup="handlePkEnd"
        />
        <div class="stars">
          <span v-for="index in mine.level" :key="index"></span>
        </div>
      </div>
      <div v-if="showHelpState.includes(mine.state) && sideRef" class="amount">
        {{
          removeDecimal(
            amountRef?.[sideRef],
            campaignInfoRef.info?.tokens?.[sideRef]
          )
        }}
        <span>{{
          getConstants().TokensOptions[campaignInfoRef.info?.tokens?.[sideRef]]
            .name
        }}</span>
      </div>
      <div v-else class="amount">
        {{ mine.bet_value_usdt }}<span>USDT等额</span>
      </div>

      <div class="blood-container">
        <!-- 助力双方得到的奖池总数 -->

        <button
          v-if="campaignInfoRef?.info?.id"
          class="listEntry"
          @click="
            router.push({
              path: '/tableslist',
              query: { campaignId: campaignInfoRef?.info?.id },
            })
          "
        >
          已助力
        </button>
        <div class="blood" v-if="showHelpState.includes(mine.state)">
          {{
            getTotalReward(
              mine.current_campaign?.offence_reward,
              mine.current_campaign?.defence_reward
            )
          }}
        </div>

        <div class="blood" v-else-if="mine.state == 4 && mine.current_campaign">
          {{
            getTotalReward(
              mine.current_campaign?.offence_reward,
              mine.current_campaign?.defence_reward
            )
          }}
        </div>
        <div class="blood" v-else>0</div>

        <PowerLine
          v-if="
            showHelpState.includes(mine.state) && campaignInfoRef?.info.powers
          "
          :powers="[
            mine.current_campaign?.offence_power,
            mine.current_campaign?.defence_power,
          ]"
          :powersWidth="powersWidthRef"
        />

        <div v-if="mine.state == 4">
          <PowerLine
            v-if="campaignInfoRef?.info?.powers"
            :powers="[
              mine.current_campaign?.offence_power,
              mine.current_campaign?.defence_power,
            ]"
            :powersWidth="powersWidthRef"
          />

          <PowerLine
            v-else
            :powers="[0, 0]"
            :powersWidth="{ attack: '50', defence: '50' }"
          />
        </div>

        <PowerLine
          v-if="
            showFightState.includes(mine.state) ||
            mine.state == 1 ||
            mine.state == 5
          "
          :powers="[0, 0]"
          :powersWidth="{ attack: '50', defence: '50' }"
        />
        <PowerLine
          v-if="mine.state == 6 && !mine.current_campaign"
          :powers="[0, 0]"
          :powersWidth="{ attack: '50', defence: '50' }"
        />
      </div>

      <div class="union-names" v-if="showHelpState.includes(mine.state)">
        <span>{{ mine.current_campaign?.offence_sociaty?.name }}</span>
        <span>{{ mine.current_campaign?.defence_sociaty?.name }}</span>
      </div>
      <div class="union-names" v-else>
        <span>攻方</span>
        <span>守方</span>
      </div>

      <!-- 攻方/守方显示争夺状态 state=1-->
      <div class="union-infos" v-if="mine.state == 1">
        <div class="item">
          <span class="attack-icon">攻</span>
          <span class="name">攻方</span>
          <p class="title">当前质押量</p>
          <p class="number">0</p>
          <p class="title">成员数</p>
          <p class="number">0</p>

          <!-- <button
                        :circle="true"
                        v-if="[98,99].includes(unionRole?.role)"
                        @click="joinFight(signup_ids.includes(mine.ID))"
                    >
                        {{
                            signup_ids.includes(mine.ID) ? '已报名' : '参加争夺'
                        }}
                    </button> -->
          <div class="noBtns" style="background: none"></div>
        </div>
        <div class="item defence">
          <span class="defence-icon">守</span>
          <span class="name">守方</span>
          <p class="title">当前质押量</p>
          <p class="number">0</p>
          <p class="title">成员数</p>
          <p class="number">0</p>
          <!-- <button
                        v-if="[98,99].includes(unionRole?.role)"
                        @click="joinFight(signup_ids.includes(mine.ID))"
                    >
                        {{
                            signup_ids.includes(mine.ID) ? '已报名' : '参加争夺'
                        }}
                    </button> -->
          <!-- <button v-else style="background: none"></button> -->
          <div class="noBtns" style="background: none"></div>
        </div>
      </div>

      <!-- 攻方显示报名状态 state=5-->
      <div class="union-infos" v-if="mine.state == 5">
        <div class="item">
          <span class="attack-icon">攻</span>
          <span class="name">攻方</span>
          <p class="title">当前质押量</p>
          <p class="number">0</p>
          <p class="title">成员数</p>
          <p class="number">0</p>
          <button
            v-if="
              [98, 99].includes(unionRole?.role) &&
              unionRole?.sociaty?.id != mine.sociaty_id
            "
            @click="gotoSignUp(signup_ids.includes(mine.ID))"
          >
            {{ signup_ids.includes(mine.ID) ? "已报名" : "报名争夺" }}
          </button>

          <div class="noBtns" v-else style="background: none"></div>
        </div>
        <div class="item defence">
          <span class="defence-icon">守</span>
          <span class="name">{{
            mine.sociaty ? mine.sociaty.name : "守方"
          }}</span>
          <p class="title">当前质押量</p>
          <p class="number">
            {{ mine.sociaty ? mine.sociaty.bank : 0 }}
          </p>
          <p class="title">成员数</p>
          <p class="number">
            {{ mine.sociaty ? mine.sociaty.member_count : 0 }}
          </p>

          <button
            @click="chooseCoin"
            v-if="
              [98, 99].includes(unionRole?.role) &&
              mine?.campaign_count > mine?.defence_token_campaign_count &&
              unionRole.sociaty.id == mine.sociaty_id
            "
          >
            选择币种
          </button>
          <button
            v-else-if="
              unionRole?.sociaty?.id == mine.sociaty_id && unionRole?.role == 99
            "
            @click="isAbandonMine(mine.ID)"
          >
            放弃矿山
          </button>
          <div class="noBtns" v-else style="background: none"></div>
        </div>
      </div>

      <!-- PK结束分出胜负,查看自己的战利品 state=4-->
      <div class="union-infos" v-if="mine.state == 4">
        <div v-if="mine.current_campaign">
          <div class="item">
            <span
              class="victory"
              v-if="mine.current_campaign?.winner_side == 0"
            ></span>
            <span
              class="victory fail"
              v-else-if="mine.current_campaign?.winner_side"
            ></span>

            <span class="attack-icon">攻</span>
            <span class="name">{{
              mine.current_campaign?.offence_sociaty?.name
            }}</span>
            <p class="title">当前质押量</p>
            <p class="number">
              {{ mine.current_campaign?.offence_sociaty?.bank }}
            </p>
            <p class="title">成员数</p>
            <p class="number">
              {{ mine.current_campaign?.offence_sociaty?.member_count }}
            </p>
            <button
              v-if="
                hintRef !== '对战时间已结束' &&
                mine.current_campaign?.winner_side == 0
              "
              @click="gotoCheckSpoils"
            >
              查看战利品
            </button>
            <div class="noBtns" v-else style="background: none"></div>
          </div>
          <div class="item defence">
            <span
              class="victory"
              v-if="mine.current_campaign?.winner_side == 1"
            ></span>
            <span
              class="victory fail"
              v-else-if="mine.current_campaign?.winner_side"
            ></span>

            <span class="defence-icon">守</span>
            <span class="name">{{
              mine.current_campaign?.defence_sociaty?.name
            }}</span>
            <p class="title">当前质押量</p>
            <p class="number">
              {{ mine.current_campaign?.defence_sociaty?.bank }}
            </p>
            <p class="title">成员数</p>
            <p class="number">
              {{ mine.current_campaign?.defence_sociaty?.member_count }}
            </p>
            <button
              v-if="
                hintRef !== '对战时间已结束' &&
                mine.current_campaign?.winner_side == 1
              "
              @click="gotoCheckSpoils"
            >
              查看战利品
            </button>
            <button
              v-else-if="unionRole?.sociaty?.id == mine.sociaty_id"
              @click="isAbandonMine(mine.ID)"
            >
              放弃矿山
            </button>
            <div class="noBtns" v-else style="background: none"></div>
          </div>
        </div>
        <div v-else>
          <div class="item">
            <span class="attack-icon">攻</span>
            <span class="name">攻方</span>
            <p class="title">当前质押量</p>
            <p class="number">0</p>
            <p class="title">成员数</p>
            <p class="number">0</p>
            <div class="noBtns" style="background: none"></div>
          </div>
          <div class="item defence">
            <span class="defence-icon">守</span>
            <span class="name">{{
              mine.sociaty ? mine.sociaty.name : "守方"
            }}</span>
            <p class="title">当前质押量</p>
            <p class="number">
              {{ mine?.sociaty ? mine?.sociaty.bank : 0 }}
            </p>
            <p class="title">成员数</p>
            <p class="number">
              {{ mine?.sociaty ? mine?.sociaty.member_count : 0 }}
            </p>
            <button
              v-if="
                unionRole?.sociaty?.id == mine.sociaty_id &&
                unionRole?.role == 99
              "
              @click="isAbandonMine(mine.ID)"
            >
              放弃矿山
            </button>
            <div class="noBtns" v-else style="background: none"></div>
            <!-- <button  v-else > 开采中 </button> -->
          </div>
        </div>
      </div>

      <!-- 攻方显示争夺状态 2,7-->
      <div class="union-infos" v-if="showFightState.includes(mine.state)">
        <div class="item">
          <span class="attack-icon">攻</span>
          <span class="name">攻方</span>
          <p class="title">当前质押量</p>
          <p class="number">0</p>
          <p class="title">成员数</p>
          <p class="number">0</p>
          <button
            v-if="
              [98, 99].includes(unionRole?.role) &&
              unionRole.sociaty.id != mine.sociaty_id
            "
            @click="joinFight(signup_ids.includes(mine.ID))"
          >
            {{ signup_ids.includes(mine.ID) ? "已报名" : "参加争夺" }}
          </button>
          <div class="noBtns" v-else style="background: none"></div>
        </div>
        <div class="item defence">
          <span class="defence-icon">守</span>
          <span class="name">{{
            mine.sociaty ? mine.sociaty.name : "守方"
          }}</span>
          <p class="title">当前质押量</p>
          <p class="number">
            {{ mine.sociaty ? mine.sociaty.bank : 0 }}
          </p>
          <p class="title">成员数</p>
          <p class="number">
            {{ mine.sociaty ? mine.sociaty.member_count : 0 }}
          </p>
          <button
            @click="chooseCoin"
            v-if="
              [98, 99].includes(unionRole?.role) &&
              mine?.campaign_count > mine?.defence_token_campaign_count &&
              unionRole.addr == mine.sociaty_chief_addr
            "
          >
            选择币种
          </button>
          <button
            v-else-if="unionRole?.sociaty?.id == mine.sociaty_id"
            @click="isAbandonMine(mine.ID)"
          >
            放弃矿山
          </button>
          <div class="noBtns" v-else style="background: none"></div>
        </div>
      </div>

      <!-- 没有current_campaign,state=6 -->
      <div class="union-infos" v-if="mine.state == 6 && !mine.current_campaign">
        <div class="item">
          <span class="attack-icon">攻</span>
          <span class="name">攻方</span>
          <p class="title">当前质押量</p>
          <p class="number">0</p>
          <p class="title">成员数</p>
          <p class="number">0</p>
          <!-- <button
            v-if="
              [98, 99].includes(unionRole?.role) &&
              unionRole.sociaty.id != mine.sociaty_id
            "
            @click="joinFight(signup_ids.includes(mine.ID))"
          >
            {{ signup_ids.includes(mine.ID) ? "已报名" : "参加争夺" }}
          </button> -->
          <div class="noBtns" style="background: none"></div>
        </div>
        <div class="item defence">
          <span class="defence-icon">守</span>
          <span class="name">{{
            mine.sociaty ? mine.sociaty.name : "守方"
          }}</span>
          <p class="title">当前质押量</p>
          <p class="number">
            {{ mine.sociaty ? mine.sociaty.bank : 0 }}
          </p>
          <p class="title">成员数</p>
          <p class="number">
            {{ mine.sociaty ? mine.sociaty.member_count : 0 }}
          </p>
          <!-- <button
            @click="chooseCoin"
            v-if="
              [98, 99].includes(unionRole?.role) &&
              mine?.campaign_count > mine?.defence_token_campaign_count &&
              unionRole.addr == mine.sociaty_chief_addr
            "
          >
            选择币种
          </button> -->
          <button
            v-if="unionRole?.sociaty?.id == mine.sociaty_id"
            @click="isAbandonMine(mine.ID)"
          >
            放弃矿山
          </button>
          <div class="noBtns" v-else style="background: none"></div>
        </div>
      </div>
      <!-- PK进行中---显示助力3,6,8 -->
      <div
        class="union-infos"
        v-if="showHelpState.includes(mine.state) && mine.current_campaign"
      >
        <div
          :class="['item', sideRef === '0' ? 'active' : '']"
          @click="sideRef = '0'"
        >
          <span class="attack-icon">攻</span>
          <span class="name">{{
            mine.current_campaign?.offence_sociaty?.name
          }}</span>
          <p class="title">当前质押量</p>
          <p class="number">
            {{ mine.current_campaign?.offence_sociaty?.bank }}
          </p>
          <p class="title">成员数</p>
          <p class="number">
            {{ mine.current_campaign?.offence_sociaty?.member_count }}
          </p>
          <!-- 后期需求修改  只有状态攻守方有助力按钮 -->
          <div v-if="mine.state == 6">
            <button v-if="currentTime > mine.current_campaign?.end_time * 1000">
              已结束
            </button>
            <button
              v-else-if="currentTime < mine.current_campaign?.start_time * 1000"
            >
              还未开始
            </button>
            <button v-else>助力</button>
          </div>
          <div class="noBtns" v-else style="background: none"></div>
        </div>
        <div
          :class="['item', 'defence', sideRef === '1' ? 'active' : '']"
          @click="sideRef = '1'"
        >
          <span class="defence-icon">守</span>
          <span class="name">{{
            mine.current_campaign?.defence_sociaty?.name
          }}</span>
          <p class="title">当前质押量</p>
          <p class="number">
            {{ mine.current_campaign?.defence_sociaty?.bank }}
          </p>
          <p class="title">成员数</p>
          <p class="number">
            {{ mine.current_campaign?.defence_sociaty?.member_count }}
          </p>

          <!-- 后期需求修改  只有状态攻守方有助力按钮 -->
          <div v-if="mine.state == 6">
            <button v-if="currentTime > mine.current_campaign?.end_time * 1000">
              已结束
            </button>
            <button
              v-else-if="currentTime < mine.current_campaign?.start_time * 1000"
            >
              还未开始
            </button>
            <button v-else>助力</button>
          </div>
          <div v-else>
            <button
              @click="chooseCoin"
              v-if="
                mine.state != 3 &&
                [98, 99].includes(unionRole?.role) &&
                mine?.campaign_count > mine?.defence_token_campaign_count &&
                unionRole?.addr == mine.sociaty_chief_addr
              "
            >
              选择币种
            </button>
            <div class="noBtns" v-else style="background: none"></div>
          </div>
        </div>
      </div>
    </div>

    <!-- 显示红蓝宝石数量 -->
    <div class="gemstone" v-if="mine?.current_campaign">
      <div class="gemBox">
        <p><strong>当前宝石</strong>*对战结束后宝石量清零</p>
        <p>个人总量/宝石总量</p>
      </div>
      <div class="gemBox">
        <div class="imgBox">
          <div>
            <img src="../assets/images/mines/redGemstone.png" />
            <p class="blackCol">红宝石</p>
          </div>
          <p>
            <strong>{{ personGemstone?.offence_score }}/</strong
            >{{ mine?.current_campaign?.offence_score }}
          </p>
        </div>
        <div class="imgBox">
          <div>
            <img src="../assets/images/mines/blueGemstone.png" />
            <p class="blackCol">蓝宝石</p>
          </div>
          <p>
            <strong>{{ personGemstone?.defence_score }}/</strong
            >{{ mine?.current_campaign?.defence_score }}
          </p>
        </div>
      </div>
    </div>

    <div
      class="down-section"
      v-if="
        (showFightState.includes(mine.state) ||
          mine.state == 1 ||
          mine.state == 5) &&
        [98, 99].includes(unionRole?.role)
      "
    >
      <p class="hint">
        {{ mine.state == 5 ? "请先报名" : "可进行争夺" }}
      </p>
    </div>

    <!-- 助力显示的文字和操作 PK进行中 -->
    <div
      class="down-section"
      v-if="
        mine.state == 6 &&
        currentTime >= mine.current_campaign?.start_time * 1000 &&
        currentTime <= mine.current_campaign?.end_time * 1000
      "
    >
      <p v-if="!sideRef || hintRef === '对战时间已结束'" class="hint">
        {{ hintRef }}
      </p>
      <div v-else>
        <div class="table-info">
          <span class="attack-icon" v-if="sideRef == 0">攻</span>
          <span class="defence-icon" v-else>守</span>
          <span class="name"
            >{{
              sideRef == 0
                ? mine.current_campaign?.offence_sociaty?.name
                : mine.current_campaign?.defence_sociaty?.name
            }}{{}}</span
          >
          <button @click="quickStart">快速助力</button>
          <button @click="() => getTableInfo(null)">换一桌</button>
        </div>
        <Table
          v-if="tableInfoRef.info.gameNum"
          :info="tableInfoRef.info"
          @click="showPickNumRef = true"
        />
        <div v-if="gameInfoRef.info?.totalTable" class="table-total">
          <span>{{ gameInfoRef.info.currentTable }}</span>
          <span>/</span>
          <span>{{ gameInfoRef.info.totalTable }}</span>
        </div>
      </div>
    </div>
    <div class="down-section" v-if="mine.state == 3 || mine.state == 8">
      <p v-if="mine.state == 3" class="hint">对战即将开始</p>
      <p v-if="mine.state == 8" class="hint">报名已结束</p>
    </div>
    <div class="down-section" v-if="mine.state == 6 && !mine.current_campaign">
      <p class="hint">占领中</p>
    </div>
    <div
      class="down-section"
      v-if="
        mine.state == 4 &&
        mine.current_campaign?.status==1
      "
    >
      <p class="hint">结果计算中</p>
    </div>
    <!-- 关闭数字键键盘 -->
    <PickNumber
      v-if="showPickNumRef && showHelpState.includes(mine.state)"
      :info="tableInfoRef.info"
      :tokenAddr="campaignInfoRef.info?.tokens?.[sideRef]"
      :amount="amountRef?.[sideRef]"
      :campaignId="campaignInfoRef.info?.id"
      :betSide="sideRef"
      :quickStart="quickStartRef"
      :blankTableInfo="blankTableInfoRef.info"
      :totalTable="gameInfoRef.info?.totalTable"
      @close="closePickNum"
    />
    <el-dialog
      v-model="isShowMOdel"
      title="争夺/报名失败"
      top="30vh"
      width="90%"
      center
      custom-class="models"
      :show-close="false"
    >
      <div class="content">请先创建公会或加入公会后来开启矿山争夺战</div>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" @click="gotoJoinUnio">前往加入</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { ref, reactive, watch } from "vue";
import Countdown from "../components/pkpage/Countdown.vue";
import Table from "../components/pkpage/Table.vue";
import PickNumber from "../components/pkpage/PickNumber.vue";
import PowerLine from "../components/PowerLine.vue";
import { getCampaignData, getGame } from "../apis/contractPk";
import { getTokenAmount, getTokenToUsdtPrice } from "../apis/contractExchange";
import {
  getRandomGameInfo,
  getMinesDetail,
  getPlayerUnion,
  getAlreadySignedUp,
  checkCanOper,
  getPersonGemstone,
  abandonMine,
} from "../apis/httpReq";
import {
  removeDecimal,
  getMinesBonus,
  getAccount,
  getTokenInfoByName,
  getActionContract,
  isLoading,
} from "../utils";
import { ElMessage } from "element-plus";
import { createUnionCall } from "../apis/callBack";
import getConstants from "../store/constants";
import router from "@/router/index.js";
import { useStore } from "vuex";

const campaignInfoEffect = async (campaignId) => {
  const campaignInfoRef = reactive({ info: {} });
  const campaignInfo = await getCampaignData(campaignId);
  if (campaignInfo) {
    campaignInfoRef.info = campaignInfo[0];
    const amountRef = await exchangeToken(
      campaignInfoRef.info.tokens,
      campaignInfoRef.info.betValueUsdt
    );
    return { campaignInfoRef, amountRef };
  }
};
const exchangeToken = async (tokens, valueInUsdt) => {
  const amountRef = reactive([]);
  const tokensOptions = getConstants().TokensOptions;
  console.log(tokensOptions, "tokensOptions");
  console.log(tokens, "tokens");
  console.log(valueInUsdt, "valueInUsdt");
  for (let i = 0; i < tokens.length; i++) {
    const tokenAmount = await getTokenAmount(
      tokensOptions[tokens[i]].address,
      valueInUsdt
    );
    amountRef.push(tokenAmount);
  }
  return amountRef;
};
const tableInfoEffect = async (campaignId, sideRef, currentTable) => {
  const gameInfo = await getRandomGameInfo(campaignId, sideRef);
  const gameNum = currentTable
    ? currentTable
    : gameInfo?.game_num
    ? gameInfo.game_num
    : "0";
  let tableInfo = await getGame(campaignId, gameNum);
  let fullTable = true;
  if (tableInfo.players) {
    for (let i in tableInfo.players) {
      if (tableInfo.players[i] === getConstants().blankAddr) {
        fullTable = false;
        break;
      }
    }
  }
  if (fullTable) {
    tableInfo = await getGame(campaignId, "0");
  }
  return {
    gameInfo,
    tableInfo,
  };
};

export default {
  name: "PK",
  async setup() {
    const showPickNumRef = ref(false);
    const sideRef = ref();
    const quickStartRef = ref(false);
    const store = useStore();
    const gameInfoRef = reactive({ info: {} });
    const tableInfoRef = reactive({ info: {} });
    const blankTableInfoRef = reactive({ info: {} });
    const hintRef = ref("请先选择您要助力的一方");
    const showHelpState = [3, 6, 8]; //显示助力的状态
    const showFightState = [2, 7]; //显示争夺状态
    const actionContract = getActionContract();
    let isShowMOdel = ref(false);
    const powersWidthRef = reactive({ attack: "50", defence: "50" });
    let mine = ref({});
    let offenceTokenToUsdtPrice = 0,
      defenceTokenToUsdtPrice = 0;

    const getTableInfo = async (currentTable) => {
      const res = await tableInfoEffect(
        mine.value.current_campaign.id,
        sideRef.value,
        currentTable
      );
      gameInfoRef.info = res.gameInfo;
      tableInfoRef.info = res.tableInfo;
    };
    const quickStart = async () => {
      quickStartRef.value = true;
      showPickNumRef.value = true;
    };
    const handlePkEnd = () => {
      sideRef.value = null;
      hintRef.value = "对战时间已结束";
    };
    watch(sideRef, async () => {
      getTableInfo(null);
    });

    watch(
      () => store.state.websockData,
      (newValue) => {
        const data = JSON.parse(JSON.stringify(newValue));
        if (
          data?.code == "fv228" &&
          data?.data &&
          router.currentRoute.value.query.mineId == data?.data?.ID
        ) {
          //直接刷新页面
          location.reload();
          // mine.value = data.data;
        }
        if (
          data?.code == "fv229" &&
          data?.data &&
          router.currentRoute.value.query.mineId == data?.data?.mine_id
        ) {
          mine.value.current_campaign = data.data;
        }
        if (data?.code == "fv230" && data?.data) {
          personGemstone.value = data.data.scores;
        }
      },
      { deep: true }
    );

    let account = await getAccount();
    console.log(account, "地址");
    //当前矿山信息
    console.log(mine.value, "矿山详情");
    const mineData = await getMinesDetail(
      router.currentRoute.value.query.mineId
    );
    mine.value = mineData.mine;

    if (mine.value.current_campaign) {
      let offenceTokenInfo = getTokenInfoByName(
        mine.value.current_campaign.offence_token
      );
      let defenceTokenInfo = getTokenInfoByName(
        mine.value.current_campaign.defence_token
      );
      getTokenToUsdtPrice(offenceTokenInfo.address).then(
        (price) => (offenceTokenToUsdtPrice = price)
      );
      getTokenToUsdtPrice(defenceTokenInfo.address).then(
        (price) => (defenceTokenToUsdtPrice = price)
      );
      console.log(offenceTokenToUsdtPrice, defenceTokenToUsdtPrice);
      const powersTotal =
        Number(mine.value.current_campaign.offence_power) +
        Number(mine.value.current_campaign.defence_power);
      powersWidthRef.attack =
        (mine.value.current_campaign.offence_power / powersTotal).toFixed(1) *
          100 || 50;
      powersWidthRef.defence = (100 - powersWidthRef.attack).toFixed(1) || 50;
      console.log(mine.value.current_campaign);
    }

    //工会角色信息(会员,会长)
    const unionRole = await getPlayerUnion(account);
    console.log(unionRole, "工会信息, role=99为会长");
    console.log(mine.value.state, "当前矿山的状态");
    console.log(
      mine.value.sociaty_chief_addr,
      "当前矿山的sociaty_chief_addr值"
    );
    console.log(account, "当前用户的account值");

    //获取已经报名的矿山ID集合
    const { signup_ids } = await getAlreadySignedUp(account);
    console.log(signup_ids, "已经报名的矿山集合");

    //矿山属性--金木水火土
    const getBonusPosi = (bonus) => {
      return getMinesBonus(bonus);
    };

    //计算助力奖池usdt总数
    const getTotalReward = (offenceReward, defenceReward) => {
      offenceReward = offenceReward * offenceTokenToUsdtPrice;
      defenceReward = defenceReward * defenceTokenToUsdtPrice;
      return parseInt(defenceReward + offenceReward)
        ? parseInt(defenceReward + offenceReward)
        : 0;
    };

    //获取当前时间
    const currentTime = new Date().getTime();
    console.log(currentTime, "当前时间");

    //参加争夺
    const joinFight = async (canJoin) => {
      if (!canJoin) {
        const canFight = await checkCanOper(account, mine.value.ID);
        console.log(canFight, 8989898989);
        if (canFight.code === 2) {
          isShowMOdel.value = true;
        } else {
          router.push({
            path: "./chooseToken",
            query: {
              id: mine.value.ID,
              name: mine.value.name,
              type: 1,
              level: mine.value.level,
            },
          });
        }
      }
    };
    //点击报名
    const gotoSignUp = async (canJoin) => {
      if (!canJoin) {
        let mineId = mine.value.ID;
        const canFight = await checkCanOper(account, mine.value.ID);
        if (canFight.code === 2) {
          isShowMOdel.value = true;
        } else {
          router.push({
            path: "./chooseToken",
            query: {
              id: mineId,
              name: mine.value.name,
              type: 2,
              level: mine.value.level,
            },
          });
        }
      }
    };

    //会长选择币种
    const chooseCoin = () => {
      router.push({
        path: "./chooseToken",
        query: {
          id: mine.value.ID,
          name: mine.value.name,
          type: 3,
          level: mine.value.level,
        },
      });
    };

    //偷袭/报名引导进入创建工会
    const gotoJoinUnio = () => {
      isShowMOdel.value = false;
      router.push({ path: "unionlist" });
    };

    //获取个人(红蓝)宝石数量
    let personGemstone = ref({});
    if (mine.value?.current_campaign?.id) {
      personGemstone.value = await getPersonGemstone(
        account,
        mine.value.current_campaign?.id
      );
    }

    //查看战利品明细
    const gotoCheckSpoils = () => {
      router.push({ path: "/springInfo", query: { type: 2 } });
    };

    let campaignInfoRef = null;
    let amountRef = null;

    if (mine.value.current_campaign?.id) {
      const result = await campaignInfoEffect(mine.value.current_campaign?.id);
      campaignInfoRef = result.campaignInfoRef;
      amountRef = result.amountRef;
      blankTableInfoRef.info = await getGame(campaignInfoRef.info.id, 0);
    }

    const closePickNum = (currentTable) => {
      quickStartRef.value = false;
      showPickNumRef.value = false;
      if (currentTable) {
        getTableInfo(currentTable);
      }
    };

    //放弃矿山
    const isAbandonMine = async (mineID) => {
      const giveUp = await abandonMine(account, mineID);
      if (giveUp) {
        isLoading(true);
        actionContract.methods
          .requestPlayerAction("abandonMine", mineID + "")
          .send({ from: account })
          .catch((err) => {
            ElMessage.warning({
              message: "放弃矿山失败",
              type: "warning",
            });
            isLoading(false);
          })
          .then((res) => {
            let tx = res.transactionHash;
            setTimeout(function () {
              if (!store.state.createUnionNow) {
                createUnionCall(tx).then((res) => {
                  ElMessage.success({
                    message: res.msg || "放弃成功",
                    type: "success",
                  });
                  location.reload();
                  isLoading(false);
                  store.state.createUnionNow = false;
                });
              }
            }, 10000);
          });
      }
    };

    return {
      showPickNumRef,
      campaignInfoRef,
      amountRef,
      powersWidthRef,
      quickStartRef,
      gameInfoRef,
      tableInfoRef,
      blankTableInfoRef,
      getTableInfo,
      sideRef,
      closePickNum,
      getConstants,
      removeDecimal,
      quickStart,
      mine,
      showHelpState,
      showFightState,
      getBonusPosi,
      unionRole,
      signup_ids,
      currentTime,
      joinFight,
      gotoSignUp,
      getTotalReward: getTotalReward,
      chooseCoin,
      personGemstone,
      gotoCheckSpoils,
      isShowMOdel,
      gotoJoinUnio,
      router,
      handlePkEnd,
      hintRef,
      isAbandonMine,
      account,
    };
  },
  components: {
    Table,
    PickNumber,
    Countdown,
    PowerLine,
  },
};
</script>
<style lang="scss">
.el-overlay {
  background-color: rgba(0, 0, 0, 0.9) !important;
}
.el-dialog__title {
  color: #000 !important;
  font-size: 0.15rem !important;
  font-weight: 600;
}
.models {
  .content {
    width: 60% !important;
    color: #000;
    font-size: 0.14rem;
    font-weight: 400;
    margin: 0 auto;
    text-align: center;
    line-height: 0.28rem;
  }
  button {
    width: 94%;
    height: 0.48rem;
    background: linear-gradient(180deg, #fc7dcd 0%, #f7479e 98%);
    border-radius: 0.04rem;
    border: none;
    font-size: 0.15rem;
    font-weight: 600;
    color: #fff !important;
  }
}
</style>
