<template>
  <div class="title">
    <div class="bat"></div>
    <div class="title-ctr">
      <div class="menu_class">
        <div class="xuanshang"><a :href="jianghulin_url"></a></div>
        <div class="menu" @click="drawer = !drawer"></div>
      </div>
    </div>
    <div class="menu_content" v-if="drawer">
      <div class="menu_item" @click="handleSwitchTab(1)">
        <div class="RuneScape"></div>
        <span class="txt">江湖门派</span>
      </div>
      <div class="menu_item" @click="handleSwitchTab(2)">
        <div class="five"></div>
        <span class="txt">五行江湖</span>
      </div>
      <div class="menu_item" @click="handleSwitchTab(3)">
        <div class="myWork"></div>
        <span class="txt">我的公会</span>
      </div>
      <div class="menu_item" @click="handleSwitchTab(5)">
        <div class="myPackge"></div>
        <span class="txt">我的背包</span>
      </div>
      <div class="menu_item" @click="handleSwitchTab(6)">
        <div class="shichang"></div>
        <span class="txt">江湖市场(SWAP)</span>
      </div>
      <div class="menu_item" @click="handleSwitchTab(7)">
        <div class="yinhang"></div>
        <span class="txt">江湖银行(质押池)</span>
      </div>
      <div class="menu_item" @click="handleSwitchTab(8)">
        <div class="digong"></div>
        <span class="txt">地宫掠夺战</span>
      </div>
      <div class="menu_item" @click="handleSwitchTab(9)">
        <div class="game"></div>
        <span class="txt">江湖历险记</span>
      </div>
    </div>
  </div>
</template>

<script>
import router from "@/router/index.js";
import { ref } from "vue";
import { getUnionList, getPlayerUnion } from "../../apis/httpReq";
import { getAccount } from "../../utils";
import { ElMessage } from "element-plus";
import getConstants from '../../store/constants';


export default {
  name: "title",
  async setup(props, context) {
    let account = await getAccount();
    let myUnion = await getPlayerUnion(account);
    let jianghulin_url = `${getConstants().V1BaseUrl}#/jianghuOrder`;
    let myBag_url = `${getConstants().V1BaseUrl}#/profile`;
    let market_url = `${getConstants().swapLink}#/swap`;
    let bank_url = `${getConstants().swapLink}#/bankIncomeList`;
    let digong_url = `${getConstants().digongLink}`;
    let game_url = `${getConstants().gameLink}?address=${account}`;

    //  let myUnion = null;
    let chkType = ref(1);
    const drawer = ref(false);
    const handleSwitchTab = (side) => {
      switch (side) {
        case 1:
          router.push({ path: "jh" });
          break;
        case 2:
          window.location.href = 'https://5swap.io/';
          break;
        case 3:
          if (!myUnion) {
            ElMessage.warning({
              message: "您暂时没有加入任何公会",
              type: "warning",
            });
            router.push({
              path: "./unionlist",
            });
          } else {
            console.log(router);
            router.push({
              path: "./unionstatus",
              query: { type: "index" },
            });
          }

          break;
        case 4:
          router.push({ path: "./package" });
          break;
        case 5:
          window.location.href = myBag_url;
          break;
        case 6:
          window.location.href = market_url;
          break;
        case 7:
          window.location.href = bank_url;
          break;
        case 8:
          window.location.href = digong_url;
          break;
        case 9:
          window.location.href = game_url;
          break;
        default:
          break;
      }
    };
    return { chkType, handleSwitchTab, router , drawer , jianghulin_url , myBag_url , market_url , bank_url , digong_url , game_url };
  },
};
</script>

<style lang="scss" scoped>
a:link {
  color: #ffffff;
  text-decoration: none;
}
a:visited {
  color: #ffffff;
  text-decoration: none;
}
.title {
  height: 0.55rem;
  width: 100%;
  background: #1b1a1e;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 0.13rem;
  position: relative;
  // &-ctr {
  //   > div {
  //     display: inline-block;
  //     text-align: center;
  //     span,
  //     a {
  //       font-family: SweiMarkerLegCJKsc-Bold, SweiMarkerLegCJKsc;
  //       font-weight: bold;
  //       color: #ffffff;
  //       line-height: 14px;
  //       position: absolute;
  //       display: inline-block;
  //       width: 100%;
  //       white-space: nowrap;
  //       // text-shadow:2px 2px 0px #67243b;
  //       -webkit-text-stroke: 0.005rem rgb(116, 54, 76);
  //       font-weight: 800;
  //       left: 0;
  //       bottom: 0.03rem;
  //     }
  //     a {
  //       color: #ffffff;
  //       text-decoration: none;
  //       padding: 0.2rem 0;
  //       bottom: -0.17rem;
  //     }
  //   }
  // }
}
.menu_class {
  display: flex !important;
  align-items: center;
  margin-right: 0.17rem;
  position: relative;
  a {
    font-family: SweiMarkerLegCJKsc-Bold, SweiMarkerLegCJKsc;
    font-weight: bold;
    color: #ffffff;
    line-height: 14px;
    position: absolute;
    display: inline-block;
    width: 45px;
    white-space: nowrap;
    // text-shadow:2px 2px 0px #67243b;
    -webkit-text-stroke: 0.005rem rgb(116, 54, 76);
    font-weight: 800;
    left: 0;
    bottom: 0;
  }
  a {
    color: #ffffff;
    text-decoration: none;
    padding: 0.2rem 0;
  }
}
.menu_content {
  position: absolute;
  z-index: 200;
  right: 0;
  top: 0.55rem;
  width: 2.46rem;
  height: 4.8rem;
  background-color: #1C1B22;
  .menu_item {
    margin: 0 0.1rem;
    padding: 9px 0;
    display: flex;
    align-items: center;
    &:first-child {
      margin-top: 7px;
    }
    .txt {
      color: #FFFFFF;
      font-size: 0.15rem;
      margin-left: 0.12rem;
    }
  }
}
</style>
