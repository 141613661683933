<template>
    <div class="header">
        <div class="back" @click="back"></div>
        <p class="title">{{ title }}</p>
    </div>
</template>

<script>
import router from "@/router/index.js";
export default {
    name: 'Header',
    props: {
        title: String
    },
    setup() {
      const back=()=>{
          router.back()
      }  
      return{back}
    }
};
</script>

<style lang="scss" scoped>
.header {
    height: 44px;
    line-height: 44px;
    position: relative;
}
.back {
    width: 10px;
    height: 18px;
    background: url('../assets/images/back.png') 100% / cover;
    position: absolute;
    left: 20px;
    top: 13px;
}
.title {
    font-size: 18px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
}
</style>
