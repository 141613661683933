<template>
    <div v-if="formattedTimeRef.obj.hours" class="countdown">
        <span>{{ formattedTimeRef.obj.hours }}</span> :
        <span>{{ formattedTimeRef.obj.mins }}</span> :
        <span>{{ formattedTimeRef.obj.seconds }}</span>
    </div>
</template>

<script>
import { onMounted, onBeforeUnmount, reactive } from 'vue';
import { getRemainingTime } from '../../utils';
export default {
    name: 'Countdown',
    props: ['endTime'],
    setup(props) {
        let endTimestamp = props.endTime + '000';        
        let formattedTimeRef = reactive({ obj: {} });
        let int;
        onMounted(() => {
            int = setInterval(() => {
                if(endTimestamp>new Date().getTime()){
                    const countdownObj = getRemainingTime(endTimestamp--);
                    formattedTimeRef.obj = countdownObj;
                }else{
                    clearInterval(int);
                } 
            }, 1000);
        });
        onBeforeUnmount(() => {
            clearInterval(int);
        });
        return {
            formattedTimeRef
        };
    }
};
</script>

<style lang="scss" scoped>
.countdown {
    display: inline-block;
}
.countdown span {
    display: inline-block;
    height: 0.2rem;
    line-height: 0.2rem;
    width: 0.12rem;
    text-align: center;
    // background: linear-gradient(#929092, #110e11);
    color: #f7479e;
    font-size: 0.12rem;
    font-family: DINAlternate-Bold, DINAlternate;
    font-weight: bold;
    border-radius: 0.03rem;
}
</style>
