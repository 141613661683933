<template>
  <div class="union-status">
    <div class="union-info" v-if="headInfo">
      <img :src="headInfo.iconUrl" alt="icon" class="icon" />
      <div class="name">
        <p>{{ headInfo.name }}</p>
        <div class="stars">
          <div :class="{ chk: headInfo.level >= 1 ? true : false }"></div>
          <div :class="{ chk: headInfo.level >= 2 ? true : false }" v-if="headInfo.level >= 2"></div>
          <div :class="{ chk: headInfo.level >= 3 ? true : false }" v-if="headInfo.level >= 3"></div>
                  <div :class="{ chk: headInfo.level >= 4 ? true : false }" v-if="headInfo.level >= 4"></div>
                          <div :class="{ chk: headInfo.level >= 5 ? true : false }" v-if="headInfo.level >= 5"></div>
        </div>
      </div>
      <div class="numbers">
        <p>公会银行圣物：{{ headInfo.bank }}</p>
        <p>成员数：{{ headInfo.numberMembers }}</p>
        <button
          v-if="
            myUnion &&
            myUnion.role == 99 &&
            (router.currentRoute.value.query.id == myUnion.sociaty.id ||
              router.currentRoute.value.query.type) &&
            InfoContent.list.length > 0
          "
          @click="kickUnion('任命')"
        >
          任命
        </button>
        <button
          v-if="
            myUnion &&
            (router.currentRoute.value.query.id == myUnion.sociaty.id ||
              router.currentRoute.value.query.type)
          "
          @click="goFix"
        >
          质押
        </button>
        <button
   v-if="
            myUnion &&
            (myUnion.role == 99||myUnion.role == 98) &&
            (router.currentRoute.value.query.id == myUnion.sociaty.id ||
              router.currentRoute.value.query.type) 
          "
          @click="kickUnion('审核')"
        >
          审核
        </button>
          <!-- <button
          @click="kickUnion('审核')"
        >
          审核
        </button> -->
      </div>
    </div>
    <div class="activity" v-if="headInfo">
      <p class="title">公会活跃度</p>
      <p class="content">
        <span>总活跃度：{{ headInfo.active }}</span>
        <span class="right">当日活跃度：{{ headInfo.activeDay }}</span>
      </p>
      <p class="content" style="margin-bottom: 0.13rem">
        <span class="title_font">今日活跃度：</span>
        <span class="right"
          ><span class="pink">今日活跃度</span>/矿山所需活跃</span
        >
      </p>

      <span class="motAct" v-for="item of headInfo.mines" :key="item.ID"
        >{{ item.name }}({{ toChinese(item.level) }}星)：<span class="pink">{{
         Math.floor(Number(headInfo.activeDay ))
        }}</span
        >/
        <span style="font-weight: bold">{{ getInvite(item.level) }}</span></span
      >
      <!-- <span class="right">
          <span>二星矿山：<span class="pink">100</span>/800</span></span
        > -->

      <!-- <p class="content">
        <span>一星矿山：<span class="pink">100</span>/200</span>
        <span class="right">
          <span>二星矿山：<span class="pink">100</span>/800</span></span
        >
      </p>
      <p class="content">
        <span>一星矿山：<span class="pink">100</span>/200</span>
        <span class="right">
          <span>二星矿山：<span class="pink">100</span>/800</span></span
        >
      </p> -->
    </div>
    <div class="activity" v-if="earningInfo">
      <p class="content" @click="goEarnningInfo">
        <span class="earnings">矿山收益</span>
        <span class="right icon_right">查看明细</span>
      </p>
      <p class="getMy">
        <span>{{
          earningInfo.mine_reward_sum
            ? Number(earningInfo.mine_reward_sum).toFixed(1)
            : 0
        }}</span>
        <button @click="getEarningsToMe">领取</button>
      </p>
    </div>
    <div class="members" v-if="InfoContent">
      <table>
        <tr>
          <th style="flex: 1.4;width:2rem">成员名</th>
          <th style="flex: 0.8">活跃度</th>
          <th style="flex: 1">质押量</th>

          <th style="flex: 1; text-align: left">贡献值</th>
        </tr>
        <tr v-for="(item, index) of InfoContent.list" :key="item.id">
          <td style="flex: 1.4" :class="{ chairman: item.role === 98 }">
            <span style="padding-right:0.16rem;width:2rem">
 {{ item.nickname
            }}
            </span>
           <img
              :src="headInfo.iconUrl"
              alt="icon"
              class="icon"
              v-if="index == 0"
            />
          </td>
          <td style="flex: 0.8">{{ item.active }}</td>
          <td style="flex: 1">{{ item.pledge }}</td>

          <td style="flex: 1; text-align: left">
            {{ Number((Number(item.devote).toFixed(2) ? item.devote : 0) * 100 ).toFixed(2)}}
          </td>
        </tr>
      </table>
    </div>
    <div class="ctrs">
      <button v-if="!myUnion && Join" @click="joinUnion">加入公会</button>
        <button v-if="!myUnion && !Join" @click="apllyUnion">申请加入公会</button>
      <button
        v-if="
          myUnion &&
          myUnion.role != 99 &&
          (router.currentRoute.value.query.id == myUnion.sociaty.id ||
            router.currentRoute.value.query.type)
        "
        @click="LeaveUnion"
      >
        退出公会
      </button>
      <button
        v-if="
          myUnion &&
          myUnion.role == 99 &&
          (router.currentRoute.value.query.id == myUnion.sociaty.id ||
            router.currentRoute.value.query.type)
        "
        @click="brokenUnion"
      >
        解散公会
      </button>
      <button
        v-if="
          myUnion &&
          (myUnion.role == 99||myUnion.role == 98) &&
          (router.currentRoute.value.query.id == myUnion.sociaty.id ||
            router.currentRoute.value.query.type) &&
          InfoContent.list.length > 1
        "
        @click="kickUnion('踢出')"
      >
        踢出公会
      </button>
      <!-- <button

      @click="brokenUnion"
    >
      解散公会
    </button> -->
    </div>
  </div>
</template>

<script>
import { useRouter } from "vue-router";
import { ElMessage } from "element-plus";
import { useStore } from "vuex";
import { createUnionCall } from "../apis/callBack";
import getToken from "../store/constants";
import {
  getUnionInfoHead,
  getUnionInfoContent,
  getPlayerUnion,
  getPlayeMoney,
  getUnionPermission,
  getEarningsInfo,
  getCacheTableList,
  canJoin,
  applyUnion
} from "../apis/httpReq";
import { getAccount, getActionContract, isLoading } from "../utils";
export default {
  name: "UnionStatus",
  async setup() {
    const router = useRouter();
    const store = useStore();
    let account = await getAccount();
    let myUnion = await getPlayerUnion(account);

    let tokenAddr = "";
    let unionId = myUnion ? myUnion.sociaty.id : "";
    if (router.currentRoute.value.query.id) {
      unionId = router.currentRoute.value.query.id;
    }
  
    if (!unionId) {
      ElMessage.warning({
        message: "您暂时没有加入任何公会",
        type: "warning",
      });
      router.push({ path: "./unionList" });
      return;
    }
      let chacheList= await getCacheTableList(unionId)
    let earningInfo = await getEarningsInfo(
      account
      // myUnion.sociaty.token,
      // unionId
    );
         let Join=await canJoin(account,unionId);
      // if(Join){}
    const toChinese = (l) => {
      switch (l) {
        case 1:
          return "一";
        case 2:
          return "二";
        case 3:
          return "三";
        case 4:
          return "四";
        case 5:
          return "五";
        default:
          break;
      }
    };
    const getInvite = (l) => {
      switch (l) {
        case 1:
          return 70;
        case 2:
          return 200;
        case 3:
          return 800;
        case 4:
          return 3000;
        case 5:
          return 5000;
        default:
          break;
      }
    };

    // console.log(earningInfo, "我是收益");

    let playerMoney = await getPlayeMoney(account);
    let headInfo = await getUnionInfoHead(unionId);
    let actionContract = getActionContract();
    let InfoContent = await getUnionInfoContent(unionId);
    const LeaveUnion = async () => {
      //退出公会
      isLoading(true);
      actionContract.methods
        .requestLeaveGuild()
        .send({ from: await getAccount() })
        .catch((err) => {
          ElMessage.warning({
            message: "用户取消",
            type: "warning",
          });
          isLoading(false);
        })
        .then((res) => {
          console.log(res, "123123");
          console.log(store.state.createUnionNow, 1111111111111111111);
          setTimeout(function () {
            if (!store.state.createUnionNow) {
              let msg = createUnionCall(res.transactionHash).msg;
              ElMessage.success({
                message: msg || "退出成功",
                type: "success",
              });
              history.back();
              isLoading(false);
              store.state.createUnionNow = false;
            }
          }, 10000);
        });
    };
    const goFix = () => {
      Object.values(getToken().TokensOptions).forEach((v) => {
        if (v.name == myUnion.sociaty.token) {
          tokenAddr = v.address;
        }
      });
      router.push({
        path: "./fix",
        query: {
          tokenAddr: tokenAddr,
          token: myUnion.sociaty.token,
          name: myUnion.sociaty.tokenName ? myUnion.sociaty.tokenName : "灵石",
          type: "质押",
        },
      });
    };
    const getEarningsToMe = () => {
      router.push({
        path: "/Apy",
        query: {
          type: "earnings",
          price: earningInfo.mine_reward_sum,
        },
      });
    };

    //提出工会
    const kickUnion = (type) => {
      //    ElMessage.warning({
      //   message: "该功能稍后开放",
      //   type: "warning",
      // });
      router.push({
        path: "/kick",
        query: { id: unionId, type: type },
      });
    };
    const goEarnningInfo = () => {
      router.push({
        path: "/springInfo",
        query: { type: 4 },
      });
    };
    const apllyUnion= async()=>{
      let apply=await applyUnion(account,unionId);
         ElMessage.success({
            message: "申请成功",
            type: "warning",
          });
    }
    const brokenUnion = async () => {
      //解散公会
      // isLoading(true);
      let per = await getUnionPermission(unionId);

      if (!per) {
        isLoading(false);
        console.log(per, 111111111111);
        return;
      }
      isLoading(true);
      actionContract.methods
        .requestDismissGuild()
        .send({ from: await getAccount() })
        .catch((err) => {
          ElMessage.warning({
            message: "用户取消",
            type: "warning",
          });
          isLoading(false);
        })
        .then((res) => {
          console.log(store.state.createUnionNow, 1111111111111111111);
          setTimeout(function () {
            if (!store.state.createUnionNow) {
              let msg = createUnionCall(res.transactionHash).msg;
              ElMessage.success({
                message: msg || "解散成功",
                type: "success",
              });
              history.back();
              isLoading(false);
              store.state.createUnionNow = false;
            }
          }, 10000);
        });
    };
    const joinUnion = () => {
      router.push({ path: "./joinunion", query: { unionId } });
    };
    return {
      headInfo,
      InfoContent,
      myUnion,
      chacheList,
      goFix,
      Join,
      getInvite,
      toChinese,
      kickUnion,
      apllyUnion,
      earningInfo,
      joinUnion,
      goEarnningInfo,
      LeaveUnion,
      router,
      getEarningsToMe,
      unionId,
      brokenUnion,
    };
  },
};
</script>
