import Web3 from 'web3';
import FomoPkAbi from './abis/fomo_pk.json';
import FomoActionAbi from './abis/fomo_action.json';
import Erc20Abi from './abis/erc20.json';
import TokenExchangeAbi from './abis/token_exchange.json';
import getConstants from './store/constants';
import Decimal from 'decimal.js';
import { ElLoading } from 'element-plus';

let web3,
    pkContract,
    actionContract,
    exchangeContract,
    erc20ContractMap = {};
let loadingInstance;
const constants = getConstants();

export async function getAccount() {

    // return '0xFA0ab150C5388F8A665A14E8F08b54B3059C8399';
    let accounts = await getWeb3().eth.requestAccounts();
    // return '0xC8f9A5B28A89dAb5f47A11571d1738240daFd659';
    return accounts[0];
}

export function getWeb3() {
    if (!web3) {
        web3 = new Web3(window.ethereum);
    }

    return web3;
}
// 时间格式化
export function parseTime(time, cFormat) {
    const format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}';
    let date;
    if (typeof time === 'object') {
        date = time;
    } else {
        date = new Date(time);
    }

    const formatObj = {
        y: date.getFullYear(),
        m: date.getMonth() + 1,
        d: date.getDate(),
        h: date.getHours(),
        i: date.getMinutes(),
        s: date.getSeconds()
    };
    return format.replace(/{(y|m|d|h|i|s)+}/g, (result, key) => {
        let value = formatObj[key];
        if (result.length > 0 && value < 10) {
            value = '0' + value;
        }
        return value || 0;
    });
}
export function getPkContract() {
    if (!pkContract) {
        pkContract = new (getWeb3().eth.Contract)(
            FomoPkAbi,
            constants.FomoPkAddress
        );
    }
    return pkContract;
}

export function getActionContract() {
    if (!actionContract) {
        actionContract = new (getWeb3().eth.Contract)(
            FomoActionAbi,
            constants.FomoActionAddress
        );
    }
    return actionContract;
}

export function getTokenExchangeContract() {
    if (!exchangeContract) {
        exchangeContract = new (getWeb3().eth.Contract)(
            TokenExchangeAbi,
            constants.TokenExchangeAddress
        );
    }
    return exchangeContract;
}

export function getErc20Contract(tokenAddress) {
    if (!erc20ContractMap[tokenAddress]) {
        erc20ContractMap[tokenAddress] = new (getWeb3().eth.Contract)(
            Erc20Abi,
            tokenAddress
        );
    }
    return erc20ContractMap[tokenAddress];
}

export const isLoading = (isLoading = false) => {
    if (isLoading) {
        loadingInstance = ElLoading.service({
            fullscreen: true,
            background: 'transparent'
        });
    }
    if (!isLoading) {
        loadingInstance.close();
    }
};

export const addDecimal = (value, tokenAddr, toFixed = 0) => {
    const tokenInfo = getConstants().TokensOptions[tokenAddr];
    let calc = value;
    if (tokenInfo.unitDecimal) {
        calc = new Decimal(value)
            .mul(Math.pow(10, tokenInfo.decimal))
            .toNumber();
        calc = parseInt(calc);
        console.log(calc);
        if (Math.floor(calc) !== calc) {
            console.log(1111);
            const arr = calc.toString().split('.');
            if (Number(arr[1].substr(0, toFixed)) === 0) {
                calc = arr[0];
                console.log(111122);
            } else {
                calc = arr[0] + '.' + arr[1].substr(0, toFixed);
            }
        }
    }
    return tokenInfo.unitDecimal
        ? calc.toString() + tokenInfo.unitName
        : calc.toString();
};
export const  renderPrice=(value,toFixed=0)=>{
    let calc = value;
    if(value.length<toFixed){
        return value
    }
    const arr = calc.toString().split('.');
    console.log(Number(arr[0]) != 0,arr[1].length>2)
    if (Number(arr[0]) != 0 && arr[1].length>2) {
    
        calc = (value+'').substr(0,4);
    } else if(Number(arr[0]) == 0 && arr[1].length>2) {
        let index=arr[1].split('').findIndex(v=>{return v>0});
        let min =arr[1].replace(/[0]+/,'').substr(0,toFixed).split('');
        for(let i=0;i<=index-1;i++){
           min.unshift('0');
        }
        calc='0.'+min.join('')
    } else if(Number(arr[0]) == 0 && arr[1].length==2) {
        calc = value;
    }else if(Number(arr[0]) != 0 && arr[1].length<=2) {
        calc = value;
    }
    return calc;
}
export const addDecimalByName = (value, name, toFixed = 0) => {
    let addr;
    Object.values(getConstants().TokensOptions).forEach((v) => {
        if (v.name == name) {
            addr = v.address;
        }
    });
    const tokenInfo = getConstants().TokensOptions[addr];
    let calc = value;
    if (tokenInfo.unitDecimal) {
        calc = new Decimal(value)
            .mul(Math.pow(10, tokenInfo.decimal))
            .toNumber();
        calc = parseInt(calc);
        if (Math.floor(calc) !== calc) {
            console.log(1111);
            const arr = calc.toString().split('.');
            if (Number(arr[1].substr(0, toFixed)) === 0) {
                calc = arr[0];
                console.log(111122);
            } else {
                calc = arr[0] + '.' + arr[1].substr(0, toFixed);
            }
        }
    }
    return tokenInfo.unitDecimal
        ? calc.toString() + tokenInfo.unitName
        : calc.toString();
};
export const removeDecimal = (value, tokenAddr, toFixed = 0) => {
    const tokenInfo = getConstants().TokensOptions[tokenAddr];
    let decimal = tokenInfo.decimal;
    if (tokenInfo.unitDecimal) {
        decimal += tokenInfo.unitDecimal;
    }
    let calc = new Decimal(value).dividedBy(Math.pow(10, decimal)).toNumber();
    if (Math.floor(calc) !== calc) {
        const arr = calc.toString().split('.');
        if(arr.length>1){
        if ( Number(arr[1].substr(0, toFixed)) === 0) {
            calc = arr[0];
        } else {
            calc = arr[0] + '.' + arr[1].substr(0, toFixed);
        }
    }else{
        return '0'+tokenInfo.unitName
    }
    }
    return tokenInfo.unitDecimal
        ? calc.toString() + tokenInfo.unitName
        : calc.toString();
};

export const formatTokenUnit = (value, tokenAddr) => {
    let tokenInfo = getConstants().TokensOptions[tokenAddr];
    if (tokenInfo.unitDecimal) {
        return (
            new Decimal(value)
                .dividedBy(Math.pow(10, tokenInfo.unitDecimal))
                .toNumber() + tokenInfo.unitName
        );
    }
    return value;
};

export const getRemainingTime = (endTimestamp) => {
    let endDate = endTimestamp;

    var CurrentDate = new Date().getTime();
    const hours = Math.floor((endDate - CurrentDate) / 60 / 60 / 1000)
        .toString()
        .padStart(2, 0);
    const mins = Math.floor(((endDate - CurrentDate) / 60 / 1000) % 60)
        .toString()
        .padStart(2, 0);
    const seconds = Math.floor(((endDate - CurrentDate) / 1000) % 60)
        .toString()
        .padStart(2, 0);
    return {
        hours,
        mins,
        seconds
    };
};

//矿山属性
export const getMinesBonus = (positions) => {
    const bonusPos = {
        0: '金',
        1: '木',
        2: '水',
        3: '火',
        4: '土',
        5: '普'
    };
    return bonusPos[positions];
};

export const getTokenInfoByName = (name) => {
    let constants = getConstants();
    for (let i in constants.TokensOptions) {
        if (constants.TokensOptions[i].name === name) {
            return constants.TokensOptions[i];
        }
    }
};
