<template>
  <div class="tabBar">
    <div class="tabBar-ctr">
      <div
        class="index_chk"
        :class="{ Chk: chkType == 1 }"
        @click="handleSwitchRouter(1)"
      >
        <span>首页</span>
      </div>
      <div
        class="mot_chk"
        @click="handleSwitchRouter(2)"
        :class="{ Chk: chkType == 2 }"
      >
        <span>矿山对决</span>
      </div>
      <div
        class="ent_chk"
        @click="handleSwitchRouter(3)"
        :class="{ Chk: chkType == 3 }"
      >
        <span>娱乐模式</span>
      </div>
      <div
        class="wyf_chk"
        @click="handleSwitchRouter(4)"
        :class="{ Chk: chkType == 4 }"
      >
        <span>恶人谷</span>
      </div>
    </div>
  </div>
</template>

<script>
import router from "@/router/index.js";
import { ref } from "vue";
export default {
  name: "HomeTabBar",
  setup(props, context) {
    let chkType = ref(1);
    const handleSwitchRouter = (side) => {
      if (side == 2) {
        router.push({ path: "map" });
      } else {
        chkType.value = side;
        context.emit('routerType',chkType.value)
      }
    };
    return { chkType, handleSwitchRouter, router };
  },
};
</script>

<style lang="scss" scoped>
.tabBar {
  height: 1.1rem;
  width: 100%;
  padding-top: 0.1rem;
  line-height: 44px;
  background: #1a1518;
  &-ctr {
    .Chk {
        >span{
      -webkit-text-stroke:  0.004rem #F2DF9B !important;
        }
      position: relative;
    }
    .Chk::after {
      content: "";
      position: absolute;
      width: 130%;
      height: 130%;
      background: white;
      opacity: 0.1;
      left: -0.1rem;
      z-index: 1;
    }
    > div {
      display: inline-block;
      text-align: center;
      span {
        font-family: SweiMarkerLegCJKsc-Bold, SweiMarkerLegCJKsc;
        font-weight: bold;
        font-size: 14px;
        color: #ffffff;
        line-height: 14px;
        position: absolute;
        display: inline-block;
        width: 100%;
        white-space: nowrap;
        // text-shadow:2px 2px 0px #67243b;
        -webkit-text-stroke: 0.005rem #67243B;
        font-weight: 800;
        left: 0;
        bottom: 0;
      }
    }
  }
}
</style>
