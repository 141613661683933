<template>
  <div class="wyf_lay">
    <div class="layout home_top"></div>
    <div class="content wyf_bg">
      <div class="wyf" @click="preserve">
             <!-- <a href="https://wyf.fivexw.com"> -->
        <div class="btn btn_grey">
            敬请期待
        </div>
          <!-- </a> -->
      </div>
    </div>
    <div class="info">合欢宗新任宗主，在江湖中祸害了无数无辜少女，因此遭受了江湖上各大门派的联合围剿。</div>
    <div class="layout home_bot"></div>
  </div>
</template>

<script>
import { ElMessage } from "element-plus";
export default {
  name: "wyf",
  setup(){
    const preserve=()=>{
       ElMessage.warning({
          message: "爆锤吴签正在维护中",
          type: "warning",
        });
    }
    return {preserve}
  }
};
</script>

<style lang="scss" scoped>
.wyf{
    .btn{
          bottom: -0.24rem;
    left: 34%;
    }
}

.wyf_lay {
  width: 100%;
  height: 100%;
  .content {
    height: calc(100vh - 2.9rem);
    color: #fffdfc;
    font-size: 0.13rem;
    display: flex;
    align-items: center;
  }
  .info {
    height: 0.8rem;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 0.18rem;
    font-size: 0.14rem;
    color: rgba(255,255,255,0.5);
  }
  .home_top {
  }
  .home_bot {
    padding-top: 0.1rem;
  }
  .layout {
    height: 0.1rem;
    width: 100%;
    background: url("../../assets/images/wyf_lay.png") 100% no-repeat;
    background-position: center;
    position: relative;
    background-size: cover;
  }
}
</style>
