<template>
  <div class="create-union">
    <div class="item choose-icon" @click="goChoose">
      <span class="title">公会队标</span>
      <img
        :src="unionIcon"
        alt="icon"
        v-if="unionIcon"
        style="width: 0.3rem; vertical-align: middle"
      />
      <span v-else>请选择公会图标</span>
      <span class="icon_right" style="vertical-align: middle"></span>
    </div>
    <div class="item">
      <span class="title">名称</span>
      <input
        type="text"
        class="input"
        v-model="unionName"
        placeholder="请输入公会名称"
      />
      <!-- <span class="icon_right" style="vertical-align: middle"></span> -->
    </div>

    <div class="item">
      <span class="title">质押门槛</span>
      <el-input-number
        v-model="unionNum"
        :controls="false"
        controls-position="right"
        placeholder="请输入会员质押门槛"
      ></el-input-number>
      <!-- <span class="icon_right" style="vertical-align: middle"></span> -->
      <!-- <input type="text" class="input" /> -->
    </div>

    <div class="item">
      <span class="title">星级</span>
      <div class="stars">
        <div
          :class="{ chk: level >= 1 ? true : false }"
          @click="chkLevel(1)"
        ></div>
        <!-- <div
          :class="{ chk: level >= 2 ? true : false }"
          @click="chkLevel(2)"
        ></div>
        <div
          :class="{ chk: level >= 3 ? true : false }"
          @click="chkLevel(3)"
        ></div> -->
        <!-- <div
          :class="{ chk: level >= 4 ? true : false }"
          @click="chkLevel(4)"
        ></div>
        <div
          :class="{ chk: level >= 5 ? true : false }"
          @click="chkLevel(5)"
        ></div> -->
      </div>
    </div>
    <div class="item" @click="goChooseMen">
      <span class="title">门派</span>
      <input
        type="text"
        class="input"
        v-model="MineM"
        placeholder="请选择你的门派"
      />
      <span class="icon_right" style="vertical-align: middle"></span>
    </div>
    <div class="item" style="height: 0.8rem" v-if="MineM">
      <span class="title">质押数量</span>
      <el-input-number
        v-model="Thresholds"
        :controls="false"
        controls-position="right"
        placeholder="请输入你想要质押的数量"
      ></el-input-number>
      <p style="font-size: 0.08rem; line-height: 0.08rem; color: grey">
        Tips：你质押的数量越多，占领矿山后按比例得到的收益也越多
      </p>
      <!-- <input type="text" class="input" /> -->
    </div>
    <div class="item flex" v-if="MineM">
      <span>当前可用圣物：{{ currentGoods }}</span>
      <span class="fixButton" @click="goJh"> 合成 </span>
      <!-- <input type="text" class="input" /> -->
    </div>
    <p class="pledge" v-if="MineM">
      <span>创建公会最低需要质押：</span
      ><span class="number">{{ Threshold }}{{ price_name }}</span>
    </p>
    <button @click="handleUnion">创建公会</button>
  </div>
</template>

<script>
import { ref } from "@vue/reactivity";
import { createUnion } from "../apis/contractCreate";
import { useRouter } from "vue-router";
import { watch } from "@vue/runtime-core";
import { ElMessage } from "element-plus";
import { useStore } from "vuex";
import { getPlayeMoney } from "../apis/httpReq";
import { getAccount, addDecimal, isLoading, formatTokenUnit } from "../utils";
import { createUnionCall } from "../apis/callBack";
import getToken from "../store/constants";
import config from "../store/config";
import { getCurrentInstance } from "vue";
export default {
  name: "CreateUnion",
  async setup() {
    const router = useRouter();
    const store = useStore();
    let vue = getCurrentInstance();
  
    let level = ref(1);
    let token = ref("");
    let unionNum = ref("");
    let token_name = ref("");
    let unionName = ref("");
    let MineM = ref("");
    let unionIcon = ref(""); //选择公会图标返回的图标
    let Threshold = ref("");
    let Thresholds = ref("");

    let currentGoods = ref(0);
    let account = await getAccount();

    let price_name = ref("");
    let playerMoney;
    playerMoney = ref(await getPlayeMoney(account));
    console.log(router);
      vue.appContext.config.globalProperties.$emitter.on("getIcon", (e) =>{
        console.log(e)
      unionIcon.value = `/icon/union_icon${e.ix}.png`;
      }
    );
    watch(
      () => router.currentRoute.value.query.token,
      async () => {
        // console.log(router.currentRoute.value.path)
        if (router.currentRoute.value.path == "/createunion") {
          MineM.value = router.currentRoute.value.query.menpai
            ? router.currentRoute.value.query.menpai
            : "";
          token.value = router.currentRoute.value.query.token
            ? router.currentRoute.value.query.token
            : "";
          token_name.value = router.currentRoute.value.query.tokenName
            ? router.currentRoute.value.query.tokenName
            : "";
          Threshold.value = router.currentRoute.value.query.Threshold
            ? router.currentRoute.value.query.Threshold
            : "";
          Object.values(getToken().TokensOptions).forEach((v) => {
            if (v.name == token_name.value) {
              price_name.value = v.tokenName;
            }
          });
          account = await getAccount();
          playerMoney = ref(await getPlayeMoney(account));
          if (MineM && playerMoney) {
            playerMoney.value.forEach((v) => {
              if (v.token_name == token_name.value) {
                currentGoods.value = v.amount;
              }
            });
          }
        }
      }
    );
    // watch(
    //   () => router.currentRoute.value.query.iconIx,
    //   async () => {
    //     if (
    //       !router.currentRoute.value.query.menpai &&
    //       router.currentRoute.value.query.iconIx
    //     ) {
    //       console.log(router.currentRoute.value.query.iconIx);
    //       unionIcon.value = `/icon/union_icon${router.currentRoute.value.query.iconIx}.png`;
    //     }
    //   }
    // );

    const chkLevel = (l) => {
      level.value = l;
    };
    const goChoose = () => {
      router.push({
        path: "./chooseicons",
        query: { iconIx: router.currentRoute.value.query.iconIx },
      });
    };
    const goJh = () => {
      router.push({ path: "./jh", query: { menpai: MineM.value } });
    };
    const goChooseMen = () => {
      router.replace({
        path: "./chooseiconMen",
        query: { menpai: MineM.value },
      });
    };
    let handleUnion = async () => {
      console.log(
        currentGoods.value,
        Threshold.value,
        Thresholds.value,
        currentGoods.value
      );
      if (
        Number(currentGoods.value) < Number(Threshold.value) ||
        Number(Thresholds.value) > Number(currentGoods.value)
      ) {
        ElMessage.warning({
          message: "可用圣物不足，请先进行合成",
          type: "warning",
        });
        return;
      } else if (Number(Thresholds.value) < Number(Threshold.value)) {
        ElMessage.warning({
          message: "质押数量不够创建的标准",
          type: "warning",
        });
        return;
      } else if (unionNum.value < 0 || !unionNum.value) {
        ElMessage.error({
          message: "质押门槛数不正确或未填写",
        });
        return;
      } else if (Thresholds.value < 0 || !Thresholds.value) {
        ElMessage.warning({
          message: "质押数不正确",
        });
        return;
      }

      let param = {
        tokenAddress: token.value,
        name: unionName.value,
        logo: unionIcon.value,
        joinGemThreshold: unionNum.value,
        pledgeAmount: Thresholds.value,
      };
      if (!param.name || !param.tokenAddress) {
        ElMessage.warning({
          message: "请先完善信息！",
          type: "warning",
        });
        return;
      } else if (unionName.value.length > 9) {
        ElMessage.warning({
          message: "公会名字不能超过9个字",
        });
        return;
      }
      isLoading(true);
      createUnion(param).then((res) => {
        if (res) {
          setTimeout(async () => {
            if (!store.state.createUnionNow) {
              let msg = await createUnionCall(res).msg;
              ElMessage.success({
                message: msg || "创建成功",
                type: "success",
              });
              isLoading(false);
              store.state.createUnionNow = false;
              router.push({ path: "./" });
            } else {
              unionName.value = "";
              token.value = "";
              unionIcon = "";
              MineM.value = "";
            }
          }, config.HTTP_OUT_TIME);
        }
      });
    };

    return {
      handleUnion,
      goChoose,
      goChooseMen,
      level,
      goJh,
      Threshold,
      Thresholds,
      addDecimal,
      currentGoods,
      unionNum,
      MineM,
      unionIcon,
      token,
      price_name,
      unionName,
      chkLevel,
      formatTokenUnit,
    };
  },
};
</script>
<style lang="scss" scoped>
.flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.icon_right {
  position: absolute;
  right: 0.16rem;
  top: 0.18rem;
}
.item {
  position: relative;
}
::v-deep .el-input__inner {
  border: none;
}
.fixButton {
  background: linear-gradient(180deg, #fc7dcd 0%, #f7479e 98%);
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: white;
  border-radius: 2px;
  font-size: 0.12rem;
  height: 0.2rem;
  padding: 0 0.06rem;
}
</style>
