const env = process.env.NODE_ENV;
const mode = process.env.VUE_APP_MODE;
console.log(999999, process.env);
export default function getConstants() {
  if (mode === "test" || env === "development") {
    return {
      swapLink: "https://testswap.fivexw.com",
      serverLink: "https://testservice.5swap.io",
      wsUri: "wss://testservice.5swap.io/api/fv2-game-ws",
      FomoPkAddress: "0xc973b8B9B30CC0A0EfF7CFCC6162bB7f558db1E5",
      FomoActionAddress: "0x071Efeb02336588F423e1a0f402008546a81867A",
      TokenExchangeAddress: "0x42dC41CE89f025c83E08083F37571B6d933e8371",
      blankAddr: "0x0000000000000000000000000000000000000000",
      V1BaseUrl: "https://testv1.fivexw.com/#/selectMode",
      digongLink: "http://chain.pet.user.yone.tuanxo.com",
      gameLink: "http://chain.pet.game.yone.tuanxo.com",
      TokensOptions: {
        "0x0B6F8848fCA58031fd612dDfc26ABA460A4929aE": {
          name: "AURA",
          address: "0x0B6F8848fCA58031fd612dDfc26ABA460A4929aE",
          decimal: 18,
          unitDecimal: 0,
          unitName: "",
          tokenName: "灵石",
        },
        // '0xCafA482773481435ACb227A5a08e6B4f49CD88Ce': {
        //     name: 'HTMoon2',
        //     address: '0xCafA482773481435ACb227A5a08e6B4f49CD88Ce',
        //     decimal: 18,
        //     unitDecimal: 8,
        //     unitName: '亿',
        //     tokenName:'火月'
        // },
        // '0x36E5d9e3Bb9498ac47B8957Dc8d213Ec126B18C1': {
        //     name: 'HJW',
        //     address: '0x36E5d9e3Bb9498ac47B8957Dc8d213Ec126B18C1',
        //     decimal: 18,
        //     unitDecimal: 0,
        //     unitName: '',
        //     tokenName:'精金'
        // }
      },
    };
  } else if (env === "production") {
    return {
      swapLink: "https://bscswap.fivexw.com",
      serverLink: "https://bscservice.5swap.io",
      wsUri: "wss://bscservice.5swap.io/api/fv2-game-ws",
      FomoPkAddress: "0x8Df389b0424b6993466f996222fb7a64214eAFfb",
      FomoActionAddress: "0x681A0d36561eb508C7f3D31F295D7891a4405f77",
      TokenExchangeAddress: "0x4773Aecb3e42306c58823e1922beB6485Adc1E32",
      blankAddr: "0x0000000000000000000000000000000000000000",
      V1BaseUrl: "https://bscv1.fivexw.com/#/selectMode",
      digongLink: "http://bsc.pet.fivexw.com",
      gameLink: "http://bsc.pet.game.fivexw.com",
      TokensOptions: {
        "0xADB1Ab0912C8ead567fC40753aa7062ea897c745": {
          name: "AURA",
          address: "0xADB1Ab0912C8ead567fC40753aa7062ea897c745",
          decimal: 18,
          unitDecimal: 0,
          unitName: "",
          tokenName: "灵石",
        },
      },
    };
  } else {
    console.log("ENV:", env);
    return null;
  }
}
