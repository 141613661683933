<template>
  <div class="tableslist">
    <div class="tab">
      <span
        :class="[statusRef === '2' ? 'active' : '']"
        @click="() => handleChangeStatus('2')"
      >
        已完成
        {{ tableRef?.end_count }}
      </span>
      <span
        :class="[statusRef === '1' ? 'active' : '']"
        @click="() => handleChangeStatus('1')"
      >
        进行中
        {{ tableRef?.playing_count }}
      </span>
    </div>
    <div class="container" v-if="tableArrRef.data.length">
      <div v-for="(el, i) in tableArrRef.data" :key="i" class="item">
        <Table :info="el" :isSmall="true"></Table>
      </div>
    </div>
    <div :style="{ textAlign: 'center' }">
      <div v-if="!noMoreRef" class="loadMoreBtn" @click="handleNextPage">
        加载更多
      </div>
    </div>
  </div>
</template>

<script>
import { ref, reactive, onMounted } from "vue";
import router from "@/router/index.js";
import Table from "../components/pkpage/Table.vue";
import { getTableList, getCampaign } from "../apis/httpReq";
import { getAccount } from "../utils";

const getTableArrEffect = () => {
  const tableArrRef = reactive({ data: [] });
   let tableRef 
  const noMoreRef = ref(true);
  const getTableArr = async (status, page) => {
    
    const tableArr = await getTableList(
      await getAccount(),
      router.currentRoute.value.query.campaignId,
      status,
      page
    );
    
        console.log(tableRef,22222222222);
    if (tableArr) {
      tableArrRef.data = tableArrRef.data.concat(tableArr);
      if (tableArr.length < 20) {
        noMoreRef.value = true;
      } else {
        noMoreRef.value = false;
      }
    } else {
      noMoreRef.value = true;
    }

  };

  return { tableArrRef, noMoreRef, getTableArr,tableRef };
};

export default {
  name: "TablesList",
 async setup() {
    // 1：进行中；2: 已完成
    const statusRef = ref("1");
    let page = 1;

    const { tableArrRef, noMoreRef, getTableArr } = getTableArrEffect();
    console.log(tableRef,111);

    const handleChangeStatus = async (status) => {
      page = 1;
      getTableArr(status, page);
      statusRef.value = status;
      tableArrRef.data = [];
      noMoreRef.value = true;
    };
    const handleNextPage = async () => {
      getTableArr(statusRef.value, ++page);
    };

    onMounted(async () => {
      getTableArr(statusRef.value, page);
    });
  let tableRef = await getCampaign(
      await getAccount(),
      router.currentRoute.value.query.campaignId
    );
    return {
      tableArrRef,
      statusRef,
      noMoreRef,
      tableRef,
      handleChangeStatus,
      handleNextPage,
    };
  },
  components: {
    Table,
  },
};
</script>
