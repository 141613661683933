<template>
  <div class="create-union">
    <ul>
      <li
        v-for="(item, index) of tabList"
        :key="index"
        class="item"
        @click="handleChooseMenPai(item)"
      >
        <span>{{ item.name }}</span>
        <span
          :class="{ active: currentI == item.address ? true : false }"
        ></span>
      </li>
    </ul>

    <button @click="sure">确认</button>
  </div>
</template>

<script>
import { ref } from "vue";
import router from "@/router/index.js";
import getToken from "../store/constants";
export default {
  name: "ChooseIcons",
  setup: async () => {
    const tabList = [];

    Object.values(getToken().TokensOptions).forEach((v) => {
      tabList.push(v);
    });
    let currentI = ref(tabList[0].address);
    let currentT = ref(tabList[0].name);
    
    const handleChooseMenPai = (ix) => {
      currentI.value = ix.address;
      currentT.value = ix.name;
    };

    const sure = () => {
      router.replace({
        path: "./chooseToken",
        query: { money: currentI.value, token: currentT.value },
      });
    };

    return {
      currentI,
      handleChooseMenPai,
      sure,
      router,
      tabList,
    };
  },
};
</script>
<style lang="scss" scoped>
.item {
  display: flex;
  justify-content: space-between;
}
.active {
  background: url("../assets/images/selected.png") no-repeat 100% / contain;
  width: 0.2rem;
  height: 0.5rem;
}
</style>
