<template>
  <div class="fix_lay">
    <div class="fix">
      <div class="flex" style="margin-bottom: 0.24rem">
        <span class="bold">{{ type }}{{ name }}</span>
        <span class="grey"
          >{{ removeDecimal(singPrice, tokenAddr) }}{{ token }}=1{{
            name
          }}</span
        >
      </div>
      <p class="item_title" v-if="type == '合成'">选择锁仓策略</p>
      <ul class="time" v-if="type == '合成'">
        <!-- <li   @click="changeTime(index)">
          活期
        </li> -->
        <li
          v-for="(item, index) of regular[token]"
          :class="{ checked: currentTimeType == index }"
          :key="index"
          @click="changeTime(index)"
        >
          {{ item.duration ? item.duration / (24 * 60 * 60) + "天" : "活期" }}
        </li>
      </ul>
      <div class="info" v-if="type == '合成'">
        <p>
          <span>收益权重：</span>
          <span>{{ regular[token][currentTimeType].current_ratio }}</span>
        </p>
        <p>
          <span>APY：</span>
          <span>{{
            Number(regular[token][currentTimeType].apy).toFixed(2) * 100 + "%"
          }}</span>
        </p>
        <!-- <p><span>获得灵石：</span> <span>33</span></p> -->
      </div>
      <!-- <div class="info" v-if="type=='合成' && currentTimeType==0" >
        <p><span>活期收益权重：</span> <span>1</span></p>
        <p><span>活期APY：</span> <span>1</span></p>
 
      </div> -->
      <div class="flex">
        <span class="bold" v-if="type == '合成'">质押数量</span>
        <span class="nomal" v-else
          >可用{{ type == "合成" ? token : name }}数量</span
        >
        <span class="nomal"
          ><span v-if="type == '合成'">可用余额 ：</span
          ><span class="pink">
            {{
              type == "合成"
                ? removeDecimal(tokenBalance, tokenAddr)
                : currentStock
            }}
          </span></span
        >
      </div>
      <div class="flex" v-if="type == '合成'">
        <!-- <span class="nomal" style="line-height: 0.26rem"
          >{{ type == "合成" ? name : type }}数量</span
        > -->
        <div style="width: 100%; position: relative">
          <el-input-number
            v-model="stockNum"
            size="middle"
            style="width: 100%"
            :min="0"
            :max="getMaxLimit()"
          ></el-input-number>
          <span class="tokenAlias">{{ name }}</span>
          <button @click="stockMax" class="max">最大</button>
        </div>
      </div>
      <div class="flex" v-else>
        <span class="nomal" style="line-height: 0.26rem"
          >{{ type == "合成" ? name : type }}数量</span
        >
        <div>
          <el-input-number
            v-model="stockNum"
            size="mini"
            :min="0"
            :max="getMaxLimit()"
          ></el-input-number>
          <button @click="stockMax" class="max_s">最大</button>
        </div>
      </div>
      <button @click="fixStock">{{ type }}{{ name }}</button>
    </div>
  </div>
</template>

<script>
import { ref } from "@vue/reactivity";
import router from "@/router/index.js";
import { ElMessage } from "element-plus";
import getToken from "../../store/constants";
import { createUnionCall } from "../../apis/callBack";
import { useStore } from "vuex";
import { getPlayeMoney, getRegular } from "../../apis/httpReq";
import config from "../../store/config";

import {
  getAccount,
  getActionContract,
  getErc20Contract,
  addDecimal,
  removeDecimal,
  isLoading,
} from "../../utils";
export default {
  name: "fix",

  async setup() {
    const store = useStore();
    let tokenAddr = router.currentRoute.value.query.tokenAddr;
    let name = router.currentRoute.value.query.name;
    let token = router.currentRoute.value.query.token;
    let type = router.currentRoute.value.query.type;
    const tokenAlias = router.currentRoute.value.query.tokenAlias;
    
    const regular = await getRegular();
    let account = await getAccount();
    let playerMoney = await getPlayeMoney(account);
    let currentTimeType = ref(0);
    let stockNum = ref(1);
    let contract = getErc20Contract(tokenAddr); // 获得合约对象
    let actionContract = getActionContract(); //获得action合约对象
    console.log(regular);
    let currentStock;
    playerMoney.map((v) => {                      //算出可用余额
      if (v.token_name == token) {
        if(type==='分解') {
          if (v.regular_frozen_amount <= v.frozen_amount) {
            currentStock =
              v.amount - v.frozen_amount > 0 ? v.amount - v.frozen_amount : 0;
          }else{
            currentStock =
              v.amount - v.regular_frozen_amount > 0 ? v.amount - v.regular_frozen_amount : 0;
          }
        }else {
          currentStock = v.amount - v.frozen_amount;
        }
      }
    });

    let singPrice = Number(
      await actionContract.methods.getGemPrice(tokenAddr).call()
    ); //获得一个圣物价值多少token
    let tokenBalance = ref(await contract.methods.balanceOf(account).call()); //获得玩家持有的币的数量 有decimal
    //获得max上线
    const getMaxLimit = () => {
      if (type == "合成") {
        return Math.floor(tokenBalance.value / singPrice);
      } else {
        return currentStock;
      }
    };
    //自动算出最大的合成分解值
    const stockMax = () => {
      if (type == "合成") {
        stockNum.value = Math.floor(tokenBalance.value / singPrice);
      } else {
        stockNum.value = currentStock;
      }
    };
    //选择期限类别
    const changeTime = (i) => {
      currentTimeType.value = i;
    };
    //分解合成
    const fixStock = async () => {
      if(stockNum.value===0){
           ElMessage.warning({
          message: "余额不足！",
          type: "warning",
        });
        return
      }
      let tx;
      if (tokenBalance.value < stockNum.value * singPrice && type == "合成") {
        ElMessage.warning({
          message: "余额不足！",
          type: "warning",
        });
        return;
      } else if (currentStock <= 0 && (type == "分解" || type == "质押")) {
        ElMessage.warning({
          message: `当前${name}不足无法完成！`,
          type: "warning",
        });
        return;
      }
      isLoading(true);
      if (type == "合成" && currentTimeType.value == 0) {
        let allowance = Number(
          await contract.methods
            .allowance(account, getToken().FomoActionAddress)
            .call()
        );
        // console.log(allowance);
        if (allowance < stockNum.value * singPrice) {
          await contract.methods
            .approve(
              getToken().FomoActionAddress,
              "900000000000000000000000000000000000000000000000000000"
            )
            .send({ from: await getAccount() })
            .catch((err) => {
              ElMessage.warning({
                message: "用户取消",
                type: "warning",
              });
              isLoading(false);
              return;
            })
            .then((res) => {
              if (!res) {
                return;
              }
              actionContract.methods
                .tokenConvertToGem(tokenAddr, stockNum.value)
                .send({ from: account })
                .catch((err) => {
                  ElMessage.warning({
                    message: "用户取消",
                    type: "warning",
                  });
                  isLoading(false);
                })
                .then((res) => {
                  tx = res.transactionHash;
                  console.log("执行set-");
                  setTimeout(function () {
                    console.log("执行set+");
                    if (!store.state.createUnionNow) {
                      createUnionCall(tx).then((res) => {
                        ElMessage.success({
                          message: res.msg || "合成成功",
                          type: "success",
                        });
                        history.back();
                        isLoading(false);
                        store.state.createUnionNow = false;
                      });
                    }
                  }, config.HTTP_OUT_TIME);
                });
            });
        } else {
          actionContract.methods
            .tokenConvertToGem(tokenAddr, stockNum.value)
            .send({ from: account })
            .catch((err) => {
              ElMessage.warning({
                message: "用户取消",
                type: "warning",
              });
              isLoading(false);
            })
            .then((res) => {
              tx = res.transactionHash;
              console.log("执行set-");
              setTimeout(function () {
                console.log("执行set+");
                if (!store.state.createUnionNow) {
                  createUnionCall(tx).then((res) => {
                    ElMessage.success({
                      message: res.msg || "合成成功",
                      type: "success",
                    });
                    history.back();
                    isLoading(false);
                    store.state.createUnionNow = false;
                  });
                }
              }, config.HTTP_OUT_TIME);

              console.log(res);
            });
        }
      } else if (type == "分解") {
        actionContract.methods
          .requestConvertToToken(tokenAddr, stockNum.value)
          .send({ from: account })
          .catch((err) => {
            ElMessage.warning({
              message: "用户取消",
              type: "warning",
            });
            isLoading(false);
          })
          .then((res) => {
            tx = res.transactionHash;

            setTimeout(function () {
              console.log("执行set");
              if (!store.state.createUnionNow) {
                createUnionCall(tx).then(async (res) => {
                  console.log(tokenBalance.value);
                  tokenBalance.value = await contract.methods
                    .balanceOf(account)
                    .call();
                  console.log(tokenBalance.value);
                  ElMessage.success({
                    message: res.msg || "分解成功",
                    type: "success",
                  });
                  isLoading(false);

                  store.state.createUnionNow = false;
                });
              }
            }, config.HTTP_OUT_TIME);

            console.log(res);
          });
      } else if (type == "质押") {
        actionContract.methods
          .requestIncreaseGuildPlegeAmount(stockNum.value)
          .send({ from: account })
          .catch((err) => {
            ElMessage.warning({
              message: "用户取消",
              type: "warning",
            });
            isLoading(false);
          })
          .then((res) => {
            tx = res.transactionHash;

            setTimeout(function () {
              console.log("执行set");
              if (!store.state.createUnionNow) {
                createUnionCall(tx).then(async (res) => {
                  console.log(tokenBalance.value);
                  tokenBalance.value = await contract.methods
                    .balanceOf(account)
                    .call();
                  console.log(tokenBalance.value);
                  ElMessage.success({
                    message: res.msg || "质押成功",
                    type: "success",
                  });
                  isLoading(false);

                  store.state.createUnionNow = false;
                });
              }
            }, config.HTTP_OUT_TIME);

            console.log(res);
          });
      } else if (type == "合成" && currentTimeType.value > 0) {
        let allowance = Number(
          await contract.methods
            .allowance(account, getToken().FomoActionAddress)
            .call()
        );
        console.log(allowance<stockNum.value * singPrice *1000000000000000000000);
        if (allowance < stockNum.value * singPrice) {
          await contract.methods
            .approve(
              getToken().FomoActionAddress,
              "900000000000000000000000000000000000000000000000000000"
            )
            .send({ from: await getAccount() })
            .catch((err) => {
              ElMessage.warning({
                message: "用户取消",
                type: "warning",
              });
              isLoading(false);
              return;
            })
            .then((res) => {
              if (!res) {
                return;
              }
              actionContract.methods
                .requestPlayerAction(
                  "assetsRegular",
                  stockNum.value +
                    "," +
                    removeDecimal(singPrice, tokenAddr) +
                    "," +
                    currentTimeType.value +
                    "," +
                    token
                )
                .send({ from: account })
                .catch((err) => {
                  ElMessage.warning({
                    message: "用户取消",
                    type: "warning",
                  });
                  isLoading(false);
                })
                .then((res) => {
                  tx = res.transactionHash;
                  console.log("执行set-");
                  setTimeout(function () {
                    console.log("执行set+");
                    if (!store.state.createUnionNow) {
                      createUnionCall(tx).then((res) => {
                        ElMessage.success({
                          message: res.msg || "合成成功",
                          type: "success",
                        });
                        history.back();
                        isLoading(false);
                        store.state.createUnionNow = false;
                      });
                    }
                  }, config.HTTP_OUT_TIME);
                });
            });
        } else {
          actionContract.methods
            .requestPlayerAction(
              "assetsRegular",
              stockNum.value +
                "," +
                removeDecimal(singPrice, tokenAddr) +
                "," +
                currentTimeType.value +
                "," +
                token
            )
            .send({ from: account })
            .catch((err) => {
              ElMessage.warning({
                message: "用户取消",
                type: "warning",
              });
              isLoading(false);
            })
            .then((res) => {
              tx = res.transactionHash;
              console.log("执行set-");
              setTimeout(function () {
                console.log("执行set+");
                if (!store.state.createUnionNow) {
                  createUnionCall(tx).then((res) => {
                    ElMessage.success({
                      message: res.msg || "合成成功",
                      type: "success",
                    });
                    history.back();
                    isLoading(false);
                    store.state.createUnionNow = false;
                  });
                }
              }, config.HTTP_OUT_TIME);

              console.log(res);
            });
        }
      }
    };

    return {
      stockNum,
      singPrice,
      name,
      regular,
      changeTime,
      tokenAlias,
      currentTimeType,
      config,
      getMaxLimit,
      stockMax,
      currentStock,
      token,
      type,
      fixStock,
      tokenBalance,
      addDecimal,
      tokenAddr,
      removeDecimal,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/scss/_varibles.scss";
.fix_lay {
  width: 100%;
  height: 100%;
  background: rgba(1, 1, 1, 0.8);
  display: flex;
  align-items: center;
  .item_title {
    text-align: left;
    font-size: 0.14rem;
    margin-bottom: 0.24rem;
    font-weight: bold;
  }

  .info {
    text-align: left;
    font-size: 0.14rem;
    margin-top: 0.2rem;
    p {
      margin-top: 0.13rem;
    }
    span {
      width: 57%;
      display: inline-block;

      color: #f7479e;
    }
    span:first-child {
      width: 35%;
      color: #333333;
    }
  }
  .time {
    list-style: none;
    text-align: left;
    width: 100%;
    .checked {
      border: 1px solid #f7479e;
      color: #f7479e;
    }
    li {
      display: -webkit-box;
      width: calc(25% - 0.1rem);
      display: inline-block;
      text-align: center;
      padding: 0.13rem;
      margin: 0 0.1rem 0 0;
      font-size: 0.13rem;
      border-radius: 4px;
      border: 1px solid #e5e5e5;
      color: #909498;
    }
    li:last-child {
      margin-top: 0.1rem;
    }
    // li:nth-last-child(2) {
    //   margin: 0;
    // }
  }

  .flex {
    display: flex;
    justify-content: space-between;
    margin-top: 0.2rem;
  }
  ::v-deep .el-icon-minus {
    color: rgb(206, 36, 121);
  }
  // ::v-deep .el-input__inner {
  //   text-align: left;
  // }
  ::v-deep .el-icon-plus {
    color: rgb(206, 36, 121);
  }

  .fix {
    margin: 0 0.14rem;
    padding: 0.14rem;
    display: inline-block;
    background: #ffffff;
    border-radius: 8px;
    width: 100%;
  }
  button {
    @include primaryButton();
    margin-top: 0.5rem;
    width: 100%;
  }
  .tokenAlias {
    right: 0.55rem;
    top: 0.1rem;
    position: absolute;
  }
  button.max_s {
    width: 0.36rem;
    height: 0.22rem;
    margin: 0;
    margin-left: 0.01rem;
    // vertical-align: top;
    font-size: 0.13rem;
    // display: inline-flex;
    line-height: 100%;
    // padding: 0;
    // justify-content: center;
    vertical-align: middle;
    // align-items: center;
  }
  button.max {
    width: 0.36rem;
    height: 0.22rem;
    margin: 0;
    margin-left: 0.01rem;
    right: 0.1rem;
    top: 0.08rem;
    position: absolute;
    // vertical-align: top;
    font-size: 0.13rem;
    // display: inline-flex;
    line-height: 100%;
    // padding: 0;
    // justify-content: center;
    vertical-align: middle;
    // align-items: center;
  }
}
.grey {
  color: #909498;
  font-size: 0.13rem;
}
.nomal {
  color: #333333;
  font-size: 0.13rem;
}
.bold {
  font-weight: 600;
  font-size: 0.15rem;
  color: #000000;
}
</style>
