<template>
  <div class="wuqianlog">
    <div class="log-top">
      <div class="gift-count" v-if="type != 2 && type != 4">
        <span v-if="type == 1">当前灵气收益总量</span>
        <!-- <span v-if="type == 2">累计获得战利品</span> -->
        <span v-if="type == 3">累计质押灵气量</span>
        <span class="count">{{ Number(countGift).toFixed(2) }}</span>
      </div>

      <div class="dates">
        <el-date-picker
          v-model="startTime"
          size="large"
          type="month"
          placeholder="选择日期"
          :clearable="false"
          prefix-icon="el-icon-caret-bottom"
          @change="timeChange"
        >
        </el-date-picker>
      </div>
      <div class="tabs" v-if="type == 1">
        <ul>
          <li
            v-for="(item, index) of tabList"
            :key="index"
            @click="checkTab(index)"
            :class="{ checked: currentTab == index }"
          >
            {{ item }}
          </li>
        </ul>
      </div>
    </div>
    <div class="loginfo" v-if="type == 1" ref="list">
      <p><span>TVL/到期时间</span><span>个人收益(AURA)</span></p>
      <ul>
        <li v-for="(item, index) of list" :key="index">
          <div>
            <p v-if="item.type == 1">
             活期质押 {{ item.package_amount }}{{getTokenInfoByName(item.token).tokenName}}
            </p>
            <p v-else>
              锁仓{{ item.package_duration / (24 * 60 * 60) + "天"
              }}{{ item.package_amount
              }}{{ getTokenInfoByName(item.token).tokenName }}
            </p>
            <p class="date" v-if="item.type == 1">
              {{ parseTime(item.package_create_at * 1000) }}
            </p>
            <p class="date" v-else>{{ outTime((item.package_create_at+item.package_duration) * 1000) }}</p>
          </div>
          <span class="money">{{
            addDecimalsByName(
              item.amount ? Number(item.amount).toFixed(2) : 0,
              item.token
            )
          }}</span>
        </li>
      </ul>
    </div>
    <div class="loginfo" v-if="type == 2" ref="list">
      <ul>
        <li v-for="(item, index) of list" :key="index">
          <div>
            <p>
              赢得{{ item.mine_name }}({{
                PKnature[item.bonus_position]
              }})争夺战
            </p>
            <p class="date">{{ parseTime(item.update_time * 1000) }}</p>
          </div>
          <div>
            <p v-if="Number(item.def_token_amount) && item.def_status">
              <span class="pink"
                >+{{
                  addDecimalsByName(
                    Number(item.def_token_amount).toFixed(2),
                    item.defence_token
                  )
                }}{{ item.defence_token }}</span
              >
            </p>
            <p v-if="Number(item.off_token_amount) && item.off_status">
              <span class="money pink"
                >+{{
                  addDecimalsByName(
                    Number(item.off_token_amount).toFixed(2),
                    item.offence_token
                  )
                }}{{ item.offence_token }}</span
              >
            </p>
          </div>
        </li>
      </ul>
    </div>
    <div class="loginfo" v-if="type == 3" ref="list">
      <ul>
        <li v-for="(item, index) of list" :key="index">
          <div>
            <p v-if="item.type == 0">
              使用灵气合成{{ getPriceName(item.relic_name) }}
            </p>
            <p v-else>分解{{ getPriceName(item.relic_name) }}变成灵气</p>
            <p class="date">{{ parseTime(item.create_time * 1000) }}</p>
          </div>
          <span class="money"
            >{{ item.type == 0 ? "+" : "-"
            }}{{ item.type == 1 ? item.relic_amount : item.relic_amount }}</span
          >
        </li>
      </ul>
    </div>
    <div class="loginfo" v-if="type == 4" ref="list">
      <ul>
        <li v-for="(item, index) of list" :key="index">
          <div>
            <p>获得占领矿山{{ item.mine_name }}时的收益</p>
            <p class="date">{{ parseTime(item.create_at * 1000) }}</p>
          </div>
          <div>
            <p>
              <span class="pink"
                >+{{
                  addDecimalsByName(
                    Number(item.reward).toFixed(2),
                    item.yield_token
                  )
                }}{{ item.yield_token }}</span
              >
            </p>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { parseTime, getAccount, getTokenInfoByName } from "../../utils";
import {
  getLogList,
  getSpringLogList,
  getPkLogList,
  getEarningsInfoList,
  getSpringNewLogList
} from "../../apis/httpReq";
import getToken from "../../store/constants";
import { addDecimalByName } from "../../utils";
import { ElMessage } from "element-plus";
// import { ref, onMounted } from "vue";
export default {
  name: "wuqianLog",
  props: {
    data: {
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      countGift: 0, //总量
      startTime: "",
      endTime: "",
      // list: [{mine_name:'马匹',bonus_position:1,def_token_amount:123,def_status:2}],
      list: [],
      type: 1, //判断是从那个页面过来的1是收益2，4是战利品，3是灵泉
      unionId: "",
      countDamage: 0,
      conutHash: 0,
      tabList: ["全部", "活期", "锁仓"],
      currentTab: 0,
      token: "",
      page: 1,
      addr: "", //token地址
      lock: false, //节流下拉
      PKnature: ["金", "木", "水", "火", "土", "普"],
    };
  },
  mounted() {
    let that = this;
    that.$refs.list.addEventListener(
      //监听下拉加载
      "scroll",
      function () {
        let limitB =
          that.$refs.list.scrollHeight -
          that.$refs.list.scrollTop -
          that.$refs.list.clientHeight;
        if (limitB < 150) {
          if (!that.lock) {
            that.lock = true;
            that.loadData();
          }
        }
      },
      false
    );
  },
  methods: {
    //     compare(list){
    // let pareList=list.map(v=>{
    // if(v.type)
    // })
    //     },
    getTokenInfoByName(a) {
      return getTokenInfoByName(a);
    },
    outTime(time) {
      let outTime = time - Date.now();
      if(outTime>0){
     let d = Math.floor(outTime / (1000 * 60 * 60 * 24));
      let h = Math.floor((outTime / (1000 * 60 * 60 * 24) - d) * 24);
      let m = new Date(outTime).getSeconds();
      return d + "天" + h + "小时" + m + "秒后过期";
      }else{
        return "过期"
      }
 
    },
    parseTime(e) {
      return parseTime(e);
    },
    checkTab(i) {
      this.currentTab = i;
      this.resetPage();
      this.loadData(true, i);
    },
    getPriceName(item) {
      let name;
      Object.values(getToken().TokensOptions).forEach((v) => {
        if (v.name == item.replace("_RELIC", "")) {
          name = v.tokenName;
        }
      });
      return name;
    },
    getTokenAddr(item) {
      let token;
      Object.values(getToken().TokensOptions).forEach((v) => {
        if (v.name == item.replace("_RELIC", "")) {
          token = v.address;
        }
      });
      return token;
    },
    addDecimalsByName(value, tokenAddr) {
      return addDecimalByName(value, tokenAddr);
    },
    // addDecimals(value, tokenAddr) {
    //   console.log(value, tokenAddr);
    //   return addDecimal(value, tokenAddr);
    // },
    resetPage() {
      this.page = 1;
      this.$refs.list.scrollTop = 0;
    },

    timeChange(e) {
      let y = e.getFullYear(),
        m = e.getMonth();
      this.startTime = Date.parse(new Date(y, m, 1));
      this.endTime = Date.parse(new Date(y, m + 1, 0));
      this.resetPage(); //回到顶部切换事件后
      this.loadData(true);
    },
    /**
     * @param timeCilck 用于判断是否是选择日期执行的函数，区别下拉
     * @param type 收益的type判断定期活期，0全部，1活期，2定期
     */
    async loadData(timeClick = false) {
      if (this.type == 1) {
        getSpringNewLogList({
          addr: this.addr,
          endTime: this.endTime / 1000 ? this.endTime / 1000 : "",
          startTime: this.startTime / 1000 ? this.startTime / 1000 : "",
          token: this.token,
          page: this.page,
          type: this.currentTab ? this.currentTab : "",
        }).then((res) => {
          !timeClick
            ? res.list
              ? (this.list = this.list.concat(res.list))
              : ""
            : (this.list = res.list);
          if (res.list) {
            this.page += 1;
            this.lock = false;
          } else if (!res.list && this.page > 1) {
            ElMessage.warning({
              message: `已全部加载`,
              type: "warning",
            });
          } else {
            timeClick ? (this.lock = false) : (this.lock = true);
          }
          this.countGift = res.total;
        });
      } else if (this.type == 3) {
        getLogList({
          addr: this.addr,
          endTime: this.endTime / 1000 ? this.endTime / 1000 : "",
          startTime: this.startTime / 1000 ? this.startTime / 1000 : "",
          name: this.token,
        }).then((res) => {
          this.countGift = res.count;
          this.list = res.list;
        });
      } else if (this.type == 2) {
        getPkLogList({
          addr: this.addr,
          endTime: this.endTime / 1000 ? this.endTime / 1000 : "",
          startTime: this.startTime / 1000 ? this.startTime / 1000 : "",
          name: this.token,
        }).then((res) => {
          this.list = res;
        });
      } else if (this.type == 4) {
        getEarningsInfoList({
          addr: this.addr,
          endTime: this.endTime / 1000 ? this.endTime / 1000 : "",
          startTime: this.startTime / 1000 ? this.startTime / 1000 : "",
          id: this.unionId,
          token: this.token,
          page: this.page,
        }).then((res) => {
          !timeClick
            ? res.list
              ? (this.list = this.list.concat(res.list))
              : ""
            : (this.list = res.list);
          if (res.list) {
            this.page += 1;
            this.lock = false;
          } else if (!res.list && this.page > 1) {
            ElMessage.warning({
              message: `已全部加载`,
              type: "warning",
            });
          } else {
            timeClick ? (this.lock = false) : (this.lock = true);
          }

          // this.list=res.list;
        });
      }
    },
    back() {
      history.back();
    },
  },
  created() {
    this.type = this.$route.query.type;
    this.token = this.$route.query.token;
    this.unionId = this.$route.query.id;
    getAccount().then((res) => {
      this.addr = res;
      this.loadData();
    });
  },
};
</script>

<style lang="scss" scoped>
a {
  color: white !important;
  text-decoration: none;
}
::v-deep .el-input__inner {
  padding: 0.06rem !important;
  background: #f5f5f5 !important;
  border-radius: 40px !important;
  height: 0.26rem;
  width: 60%;
}
.wuqianlog {
  -webkit-overflow-scrolling: touch;
  ::v-deep .el-input__icon {
    right: -1.1rem;
    font-size: 18px;
    /* left: 0; */
    position: absolute;
  }
}
.money {
  color: #882fea;
}
.el-button {
  border: none !important;
  background: linear-gradient(180deg, #fd93d8 0%, #f95aaf 100%);
  border-radius: 4px;
  font-size: 0.1rem;
  color: white !important;
}
.tabs {
  text-align: left;
  li {
    color: #333333;
    font-size: 0.15rem;
    margin-right: 0.13rem;
    display: inline-block;
    padding: 0.05rem;
    padding-bottom: 0.18rem;
  }
  .checked {
    color: #fa65b7;
    border-bottom: 1px solid #fa65b7;
  }
}
.loginfo {
  background: #f7f8fa;
  height: 82vh;
  width: 100%;
  overflow: scroll;
  > p {
    margin-top: 0.13rem;
    span {
      width: 40%;
      margin-left: 0.2rem;
      display: inline-block;
      text-align: left;
    }
  }
  > ul {
    padding: 0.16rem 0.14rem;
    li:first-child {
      margin: 0;
    }
    li {
      background: #ffffff;
      border-radius: 0.1rem;
      border: 1px solid #f5f5f5;
      display: flex;
      margin-top: 15px;
      color: #333333;
      padding: 0.26rem 0 0.2rem 0;
      font-size: 0.2rem;
      div {
        width: 70%;
        > p {
          font-size: 0.16rem;
          text-align: left;
          margin-left: 0.1rem;
          margin-bottom: 8px;
          font-family: Helvetica;
        }
      }

      span {
        font-size: 0.2rem;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 500;
        justify-content: center;
        display: flex;
        align-items: center;
      }
    }
  }
  &-right {
    display: flex;
    width: 30%;
    flex-direction: column;
    justify-content: center;
  }
}
.log-top {
  margin: 0 0.1rem;
}
.date {
  color: #b0b0b0;
  margin-left: 0.1rem;
  font-size: 0.13rem;
  margin-top: 0.16rem;
}

.dates {
  text-align: left;
  margin: 0.2rem 0 0.22rem 0;
}
.gift-count {
  margin-top: 0.2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: linear-gradient(180deg, #fd93d8 0%, #f95aaf 100%);
  border-radius: 0.1rem;
  height: 0.88rem;

  color: #ffffff;
  .count {
    font-size: 0.2rem;
    font-family: DINAlternate-Bold, DINAlternate;
    font-weight: bold;
    color: #ffffff;
  }
  span {
    margin: 0 0.22rem;
  }
}
.topbar {
  display: flex;
  height: 100%;
  font-size: 0.14rem;
  align-items: center;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #333333;
  justify-content: space-between;
}
</style>