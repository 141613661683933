<template>
    <div class="choose-icons">
        <p class="title">公会队标</p>
        <div class="icons-container">
            <span
                v-for="(val, ix) in icons"
                :key="val"
                :class="{ active: selectedIx === ix }"
                @click="handleChooseIcon(ix)"
                >
                <img :src="getUrl(ix)" alt="">
            </span>
        </div>
 <button
      @click="()=>ok()
   
      "
    >
      确认
    </button>
    </div>
</template>

<script>
import { ref } from 'vue';
import router from "@/router/index.js";
import { getCurrentInstance } from "vue";
export default {
    name: 'ChooseIcons',
    async setup() {
        let vue=getCurrentInstance();
        const icons = [1, 2, 3, 4,5];
        let selectedIx = ref(0);
       selectedIx.value= router.currentRoute.value.query.iconIx;
        const handleChooseIcon = (ix) => {
            selectedIx.value = ix;
        };
        const getUrl=(index)=>{
            return `/icon/union_icon${index}.png`;
        }
        const ok=()=>{
           vue.appContext.config.globalProperties.$emitter.emit('getIcon', {ix: selectedIx.value });
           history.back();
        }
        return { icons, handleChooseIcon, selectedIx,router,getUrl,ok  };
    }
};
</script>
