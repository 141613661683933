<template>
  <div class="union-list">
    <div class="search-container">
      <div class="input">
        <i class="search"></i>
        <input
          type="text"
          placeholder="搜索词"
          @input="handleInputChange"
          :value="searchValue"
        />
        <i class="delete" @click="handleDeleteInputValue"></i>
      </div>
      <span class="cancel">取消</span>
    </div>
    <ul class="list-container">
      <li
        v-for="item of unionList.list"
        :key="item.id"
        @click="goUnion(item.id)"
      >
        <img :src="item.iconUrl" alt="icon" class="icon" />
        <!-- <span class="awatar"></span> -->
        <div class="name">
          <p>{{ item.name }}</p>
          <div class="stars">
            <div :class="{ chk: item.level >= 1 ? true : false }" v-if="item.level >= 1"></div>
            <div :class="{ chk: item.level >= 2 ? true : false }" v-if="item.level >= 2"></div>
            <div :class="{ chk: item.level >= 3 ? true : false }" v-if="item.level >= 3"></div>
              <div :class="{ chk: item.level >= 4 ? true : false }" v-if="item.level >= 4"></div>
            <div :class="{ chk: item.level >= 5 ? true : false }" v-if="item.level >= 5"></div>
          </div>
        </div>
        <div class="number">
          <p>成员数</p>
          <p>{{ item.numberMembers }}</p>
        </div>
        <div class="number">
          <p>质押量</p>
          <p>{{ item.bank }}</p>
        </div>
      </li>
    </ul>
    <div class="buttons">
      <button @click="goUnion(myUnion.sociaty.id)" v-if="myUnion">
        我的公会
      </button>
      <button @click="goCreateUnion" v-else>创建公会</button>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import { useRouter } from "vue-router";
import { getUnionList, getPlayerUnion } from "../apis/httpReq";
import { getAccount } from "../utils";
// import { onBeforeRouteLeave } from "vue-router";
export default {
  name: "UnionList",
  async setup() {
    const router = useRouter();
    let searchValue = ref("");
  
    let unionList = ref([]);
    unionList.value = await getUnionList(searchValue.value);
    let account = await getAccount();
    let myUnion = await getPlayerUnion(account);
  // onBeforeRouteLeave((to, from) => {
  //   console.log(to.fullPath,myUnion)
  //  if(to.fullPath=='/unionstatus?type=index' && !myUnion){
  //      router.push({ path: "./" });
  //  }
  //   });
    const handleInputChange = async (e) => {
      searchValue.value = e.target.value;
      unionList.value = await getUnionList(searchValue.value);
      console.log(unionList.value);
    };
    const goCreateUnion = () => {
      router.push({ path: "./createunion" });
    };
    const goUnion = (id) => {
      router.push({ path: "./unionstatus", query: { id } });
    };
    const handleDeleteInputValue = async () => {
      searchValue.value = "";
      unionList.value = await getUnionList(searchValue.value);
    };
    return {
      searchValue,
      handleInputChange,
      handleDeleteInputValue,
      goUnion,
      myUnion,
      goCreateUnion,
      unionList,
    };
  },
};
</script>
