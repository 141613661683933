<template>
  <div class="rule">
    <div class="rule-info">
      <p class="rule-info-title">攻略</p>
      <p class="label">矿山设定:</p>
      <p class="content">
        1.矿山开采权：仅工会会长有开采/偷袭资格；一星公会只能挑战一星矿山；二星公会只能挑战二星矿山；三星公会只能挑战三星矿山和四星矿山；四星五星公会只能挑战三星矿山、四星矿山和五星矿山；一二三星公会最多可以同时占领2座矿山，四星五星公会最多可以同时占领3座矿山。
        2.版图：东部大陆+西部大陆两大板块（按本工会星级进入默认页面，点击切换），东部大陆以1、2、3星矿山为主；西部大陆以3、4、5星矿山为主。
        3.矿山设置（矿山将逐步开放）：
        (1)一星矿山：5个，1星工会可开采；收益率：日化1%，饱和度200灵石（1万AURA），即每天产币1000个AURA。
        (2)二星矿山：4个，2星工会可开采；收益率：日化1.5%，饱和度1000灵石，即每天产币7500个AURA。
        (3)三星矿山：3个，3-5星工会可开采；收益率：日化2%，饱和度5000灵石，即每天产币5万个AURA。
        (4)四星矿山：2个，3-5星工会可开采；收益率：日化2.5%，饱和度5000灵石，即每天产币6.25万个。
        (5)五星矿山：1个，4-5星工会可开采。收益率：日化3%，饱和度5000灵石，即每天产币7.5万个。
        4.矿山收益：公会会长拥有矿山8%的收益权（个人质押之外的奖励），剩下92%所有人按公会银行圣物质押比例分红。
        收益发放时间为：五星和四星矿山每天19:00发放；三二一星矿山每一轮pk结束后3.5小时发放。
        5.矿山状态：可争夺、争夺中、开采中（保护期）。
      </p>
      <p class="label">保卫战方式：</p>
      <p class="content">
        1.矿山参与制：<br>
        矿山保卫战分为偷袭制和报名制，其中一星二星三星为偷袭制，在矿山保护期解除后5分钟内符合要求的公会可发起偷袭，如有多个公会在5分钟内发起偷袭，那么活跃度最高的公会获得偷袭权，并在5分钟后开启PK战，时间为半小时，PK结束后胜利方赢得矿山，并有3.5小时的保护期，PK过程中的0.5小时由守方获得挖矿收益，也就是说PK胜利方最少能获得4小时的挖矿收益（3.5+0.5），若在保护期结束后5分钟内，没有公会发起偷袭，那么占领公会继续开采矿山，但是任何满足挑战资格的公会可以直接发起PK挑战，发起后双方有五分钟的准备时间。
        四星五星矿山为报名制24小时一轮，每天晚上20:00-21:00为固定pk时间，每天19:00为发起挑战最后报名截至时间，公会活跃度最高的公会获得挑战资格玩家（包括攻方工会成员、守方工会成员、其他散户）可任意选择攻守一方助力，不一定要选择本工会方。
        2.公会不需要任何费用即可参与1、2、3星矿山的偷袭，而4、5星矿山需要投入1000
        AURA的报名费，在19：00结束报名时，选出活跃度最高的两只公会参与PK，未被选中的公会将不退还报名费。
        3.助力方式：点击需要参与的矿山PK，选择助力的一方，助力的形式为五行阵法游戏，每局助力额的96%平分给未中的玩家，3%进入pk池，1%gas手续费，中签的玩家将获得助力边的宝石，结束后pk池（红方3%+蓝方3%）按比例分给胜利方拥有宝石的玩家，失败方的宝石不参与瓜分奖池。
        4.攻守方胜负判定方式：pk每场半小时，攻守双方各自发起五行阵解密，每参加一次解密就算一次助力次数，输赢由两边的助力次数决定。
        5.赢家占领矿山可以按照公会银行的圣物比例来获得挖矿收益，比如三星矿山4小时挖矿产量为5000aura，圣物饱和值为1万灵石，若公会银行有5000灵石，则可以挖得2500aura，若公会银行有1.2万灵石，则可以挖得最高的5000aura，同时占领矿山后不共享公会仓库灵石的质押额度，比如公会银行有1000灵石，占领A矿山后，A矿山的灵石饱和量是600，那么当该公会同时占领B矿山时，公会银行只有400个灵石的质押量来进行计算。
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "rule",
  data() {
    return {};
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.rule {
  text-align: left;
  background: linear-gradient(180deg, #1f1a1c 0%, #110c11 100%);
  padding-top: 0.1rem;
  padding-bottom: 40px;

  &-info {
    margin: 20px;
    padding: 8px;
    background: black;
    border-radius: 8px;
    border: 2px solid rgba(112, 123, 129, 1);
  }

  &-info-title {
    font-size: 0.16rem;
    margin-bottom: 0.17rem;
    font-family: PingFangTC-Semibold, PingFangTC;
    font-weight: 600;
    color: #fb409b;
  }
}

.pink {
  color: rgba(251, 64, 155, 1) !important;
}

.yellow {
  color: rgba(229, 199, 96, 1) !important;
}

.label {
  font-size: 0.16rem;
  color: rgba(229, 199, 96, 1);
  margin: 0.16rem 0;
}

.content {
  font-size: 0.13rem;
  color: white;
  line-height: 0.18rem;
}
</style>