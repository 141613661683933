<template>
  <div class="mount-status">
    <div class="spoils" @click="gotoCheckSpoils">
      战利品明细
      <img src="../assets/images/mines/spoils.png" />
    </div>
    <el-checkbox
      v-model="checked"
      label="只看我参与的"
      @change="checkedChange"
    ></el-checkbox>
    <div v-if="minesList && minesList.length > 0">
      <div class="item" v-for="(item, index) in minesList" :key="index">
        <div class="amount">{{ item.bet_value_usdt }}<span>USDT等额</span></div>
        <span class="mount-name"
          >{{ item.name }}({{ getBonusPosi(item.bonus_position) }})</span
        >
        <span class="click-hint" @click="operaMine(item)">{{
          getCheckMinesTxt(
            item.sociaty_chief_addr,
            item.state,
            item.current_campaign
          )
        }}</span>
        <div class="stars">
          <span v-for="index in item.level" :key="index"></span>
        </div>

        <!-- pk双方信息 -->
        <div
          v-if="
            item.current_campaign &&
            (item.state == 3 || item.state == 6 || item.state == 8) &&
            item.current_campaign.defence_sociaty &&
            item.current_campaign.offence_sociaty
          "
        >
          <div>
            <PowerLine
              :powers="[
                item.current_campaign.offence_power,
                item.current_campaign.defence_power,
              ]"
              :powersWidth="{
                attack: getPercent(
                  item.current_campaign.offence_power
                    ? item.current_campaign.offence_power
                    : 10,
                  item.current_campaign.defence_power
                    ? item.current_campaign.defence_power
                    : 10
                ).num1Per,
                defence: getPercent(
                  item.current_campaign.offence_power
                    ? item.current_campaign.offence_power
                    : 10,
                  item.current_campaign.defence_power
                    ? item.current_campaign.defence_power
                    : 10
                ).num2Per,
              }"
            />
            <p class="union-names">
              <span>{{
                item.current_campaign.offence_sociaty.name
                  ? item.current_campaign.offence_sociaty.name
                  : "攻方"
              }}</span>
              <span>{{
                item.current_campaign.defence_sociaty.name
                  ? item.current_campaign.defence_sociaty.name
                  : "守方"
              }}</span>
            </p>
            <img class="sword" src="@/assets/images/sword.png" alt="" />
          </div>
        </div>

        <!-- 只有一方占领矿山时 -->
        <div
          class="guildBox"
          v-if="
            (item.state == 2 ||
              item.state == 4 ||
              item.state == 6 ||
              item.state == 5 ||
              item.state == 7) &&
            item.sociaty &&
            !item.current_campaign
          "
        >
          <img :src="item.sociaty.icon_url" />
          <div class="nameAndStart">
            <p>{{ item.sociaty.name }}</p>
            <div class="star">
              <div
                :class="{ chk: item.sociaty.level >= 1 ? true : false }"
              ></div>
              <div
                :class="{ chk: item.sociaty.level >= 2 ? true : false }"
              ></div>
              <div
                :class="{ chk: item.sociaty.level >= 3 ? true : false }"
              ></div>
              <div
                :class="{ chk: item.sociaty.level >= 4 ? true : false }"
              ></div>
              <div
                :class="{ chk: item.sociaty.level >= 5 ? true : false }"
              ></div>
            </div>
          </div>
        </div>

        <!-- 矿山未被占领,无工会信息 -->
        <div class="profitBox" v-if="item.state == 0 || item.state == 1">
          <div>
            <p>最高存储量</p>
            <p>{{ item.max_store }}</p>
          </div>
          <div>
            <p>每天收益</p>
            <p>{{ item.yield }}</p>
          </div>
        </div>
        <p class="status-text">
          {{ getMineStatus(item.state, item.current_campaign, item.sociaty) }}
          <Countdown
            v-if="item.state == 4 || item.state == 3 || item.state == 8"
            :endTime="item.next_state_time"
          ></Countdown>
        </p>
      </div>
    </div>
    <div v-else>
      <el-empty :image-size="200" description="暂无数据"></el-empty>
    </div>
  </div>
</template>

<script>
import { ref, getCurrentInstance } from "vue";
import PowerLine from "../components/PowerLine.vue";
import * as utils from "../utils";
import { getMines, getPlayerUnion } from "../apis/httpReq";
import Decimal from "decimal.js";
import { useRouter } from "vue-router";
import Countdown from "../components/minePage/countDown.vue";
import { useStore } from "vuex";
import { watch } from "@vue/runtime-core";
// 矿山状态可操作提示文字
const checkMinesTxt = (status) => {
  const mineStatus = {
    0: "暂未开放",
    1: "立即争夺",
    2: "可偷袭",
    3: "点击查看",
    4: "当前矿山保护期",
    5: "点击报名",
    6: "点击查看",
    7: "点击偷袭",
    8: "点击查看",
  };
  return mineStatus[status];
};

//矿山状态
const getMinesStatusTxt = (status) => {
  const mineStatus = {
    0: "暂未开放",
    1: "可争夺",
    2: "争夺中",
    3: "被偷袭",
    4: "距离保护期结束还有",
    5: "正在报名",
    6: "争夺中",
    7: "可被偷袭",
    8: "距离争夺开始还有",
  };
  return mineStatus[status];
};

//pk双方所占百分比计算
const calcPercent = (num1, num2) => {
  let total = new Decimal(num1).add(new Decimal(num2));
  let num1Per = new Decimal(num1)
    .div(new Decimal(total))
    .mul(new Decimal(100))
    .toFixed(1);
  let num2Per = new Decimal(100).sub(new Decimal(num1Per));
  return {
    num1Per,
    num2Per,
  };
};
export default {
  setup: async () => {
    let { ctx: that } = getCurrentInstance();
    const router = useRouter();
    const store = useStore();

    let searchValue = ref(""); //查询条件
    let account = await utils.getAccount();
    let minesList = ref([]);

    watch(
      () => store.state.websockData,

      (newValue) => {
        const data = JSON.parse(JSON.stringify(newValue));
        if (data?.code == "fv228" && data?.data) {
          for (const key in minesList.value) {
            if (minesList.value[key].ID === data.data.ID) {
              minesList.value[key] = data.data;
            }
          }
        }
        if (data?.code == "fv229" && data?.data) {
          for (const key in minesList) {
            if (minesList.value[key]?.ID === data.data.mine_id) {
              minesList.value[key].current_campaign = data.data;
            }
          }
        }
      },
      { deep: true }
    );

    //矿山列表
    minesList.value = await getMines(searchValue.value);

    //工会角色信息
    const unionRole = await getPlayerUnion(account);
    console.log(unionRole, "工会信息");

    //选中状态修改-只看我参与的
    const checkedChange = async (val) => {
      if (val) {
        searchValue.value = account;
        minesList.value = await getMines(searchValue.value);
        console.log(minesList.value, 9999999);
        that.$forceUpdate();
      } else {
        searchValue.value = "";
        minesList.value = await getMines(searchValue.value);
        console.log(minesList.value, 888888);
        that.$forceUpdate();
      }
    };
    //矿山属性--金木水火土
    const getBonusPosi = (bonus) => {
      return utils.getMinesBonus(bonus);
    };
    //矿山状态:文字提示(争夺中,开采中等)
    const getMineStatus = (status, current_campaign, sociaty) => {
      if (!current_campaign && status == 6) {
        if (!sociaty) {
          return "无人争夺";
        } else {
          return "占领中";
        }
      }
      return getMinesStatusTxt(status);
    };
    //根据矿山状态进行的操作提示文字
    const getCheckMinesTxt = (address, status) => {
      const canGiveUp = [2, 4, 5, 7];

      if (address == account && canGiveUp.includes(status)) {
        return "放弃矿山";
      } else {
        return checkMinesTxt(status);
      }
    };
    //获取PK占比
    const getPercent = (num1, num2) => {
      console.log(calcPercent(num1, num2), "百分比数字");
      return calcPercent(num1, num2);
    };
    //矿山操作:攻击,报名,查看
    const operaMine = (item) => {
      if (item.state != 0) {
        router.push({ path: "pk", query: { mineId: item.ID } });
      } else {
        return;
      }
    };

    //查看战利品明细
    const gotoCheckSpoils = () => {
      router.push({ path: "/springInfo", query: { type: 2 } });
    };

    return {
      checked: ref(false),
      minesList,
      getBonusPosi,
      getMineStatus,
      getCheckMinesTxt,
      getPercent,
      operaMine,
      checkedChange,
      gotoCheckSpoils,
    };
  },
  components: {
    PowerLine,
    Countdown,
  },
};
</script>
