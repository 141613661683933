<template>
  <div class="create-union">
    <div class="item">
      <span class="title">矿山名称</span>
      <input
        type="text"
        class="input"
        v-model="name"
        placeholder="永歌池(金)"
      />
    </div>

    <div class="item">
      <span class="title">星级</span>
      <div class="stars">
        <div :class="{ chk: level >= 1 ? true : false }"></div>
        <div :class="{ chk: level >= 2 ? true : false }"></div>
        <div :class="{ chk: level >= 3 ? true : false }" ></div>
        <div :class="{ chk: level >= 4 ? true : false }" ></div>
        <div :class="{ chk: level >= 5 ? true : false }" ></div>
      </div>
    </div>
    <div class="item" @click="goChooseMoney">
      <span class="title">币种类型</span>
      <input
        type="text"
        class="input"
        style="width: calc(100% - 1.2rem)"
        v-model="currentT"
        placeholder="请选择你的币种"
      />
      <span class="icon_right" style="vertical-align: middle"></span>
    </div>
    <!-- <p class="pledge">
      <span>报名费：</span><span class="number">10000AURA</span>
    </p> -->
    <button @click="handleSure(chooseType)">
      {{
        chooseType == 1 ? "参加争夺" : chooseType == 2 ? "确认报名" : "确认选择"
      }}
    </button>
  </div>
</template>

<script>
import { ref } from "vue";
import { useRouter } from "vue-router";
import { ElMessage } from "element-plus";
import { getAccount, getActionContract, isLoading } from "../utils";
import { createUnionCall } from "../apis/callBack";
import { useStore } from "vuex";
import { watch } from "@vue/runtime-core";
import config from"../store/config";
export default {
  name: "CreateUnion",
  
  async setup() {
    const router = useRouter();
    const store = useStore();
    const actionContract = getActionContract();
    let token = ref("");
    let name = ref("");
    let chooseType = ref(""); //1-争夺  2-报名 3-选择币种
    let level=ref(0);

    let currentT = ref("");
    let account = await getAccount();
    let id = ref("");

    name.value = router.currentRoute.value.query.name;
    id.value = router.currentRoute.value.query.id;
    chooseType.value = router.currentRoute.value.query.type;
    level.value = router.currentRoute.value.query.level;
  watch(
      () => router.currentRoute.value.query.id,
      () => {
      console.log(1222222)
      if (router.currentRoute.value.path == "/chooseToken"  && !router.currentRoute.value.query.money) {
    name.value = router.currentRoute.value.query.name;
    id.value = router.currentRoute.value.query.id;
    chooseType.value = router.currentRoute.value.query.type;
    level.value = router.currentRoute.value.query.level;
      }
      }
    );
    watch(
      () => router.currentRoute.value.query.money,
      () => {
                if (router.currentRoute.value.path == "/chooseToken"  && router.currentRoute.value.query.money) {
        token.value = router.currentRoute.value.query.money
          ? router.currentRoute.value.query.money
          : "";
        currentT.value = router.currentRoute.value.query.token
          ? router.currentRoute.value.query.token
          : "";
      }
      }
    );

    // let level = ref(1);
    // const chkLevel = (n) => {
    //   level.value = n;
    // };

    const goChooseMoney = () => {
      router.replace({ path: "./chooseMoney" });
    };
    const handleSure = () => {
      if (!token.value) {
        ElMessage.warning({
          message: "请先选择币种",
          type: "warning",
        });
        return;
      }
      console.log(id.value, token.value);
      isLoading(true);
      if (chooseType.value == 1) {
        actionContract.methods
          .requestAttackMine(id.value, token.value)
          .send({ from: account })
          .catch((err) => {
            ElMessage.warning({
              message: "用户取消",
              type: "warning",
            });
             isLoading(false);
          })
          .then((res) => {
            let tx = res.transactionHash;
            setTimeout(function () {
              if (!store.state.createUnionNow) {
                createUnionCall(tx).then((res) => {
                  ElMessage.success({
                    message: res.msg || "争夺成功",
                    type: "success",
                  });
                  isLoading(false);
                  store.state.createUnionNow = false;
                  setTimeout(() => {
                    router.push({ path: "pk", query: { mineId: id.value } });
                  }, 800);
                });
              }
            }, config.HTTP_OUT_TIME);
          });
      } else if (chooseType.value == 2) {
        actionContract.methods
          .requestSignupMinePK(id.value, token.value)
          .send({ from: account })
          .catch((err) => {
            ElMessage.warning({
              message: "用户取消",
              type: "warning",
            });
             isLoading(false);
          })
          .then((res) => {
            let tx = res.transactionHash;
            setTimeout(function () {
              if (!store.state.createUnionNow) {
                createUnionCall(tx).then((res) => {
                  ElMessage.success({
                    message: res.msg || "报名成功",
                    type: "success",
                  });
                  isLoading(false);
                  store.state.createUnionNow = false;
                  setTimeout(() => {
                    router.push({ path: "pk", query: { mineId: id.value } });
                  }, 800);
                });
              }
            }, config.HTTP_OUT_TIME);
          });
      } else {
        //暂时调用报名接口
        console.log("确认报名");
        actionContract.methods
          .requestSignupMinePK(id.value, token.value)
          .send({ from: account })
          .catch((err) => {
            ElMessage.warning({
              message: "用户取消",
              type: "warning",
            });
             isLoading(false);
          })
          .then((res) => {
            let tx = res.transactionHash;
            setTimeout(function () {
              if (!store.state.createUnionNow) {
                createUnionCall(tx).then((res) => {
                  ElMessage.success({
                    message: res.msg || "操作成功",
                    type: "success",
                  });
                  isLoading(false);
                  store.state.createUnionNow = false;
                  setTimeout(() => {
                    router.push({ path: "pk", query: { mineId: id.value } });
                  }, 800);
                });
              }
            }, config.HTTP_OUT_TIME);
          });
      }
    };

    return {
      goChooseMoney,
      level,
      token,
      name,
      chooseType,
      currentT,
      handleSure,
    };
  },
};
</script>
<style lang="scss" scoped>
.flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.fixButton {
  background: linear-gradient(180deg, #fc7dcd 0%, #f7479e 98%);
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: white;
  border-radius: 2px;
  font-size: 0.12rem;
  height: 0.2rem;
  padding: 0 0.06rem;
}
</style>
