<template>
  <div class="home">
    <HomeTitle class="title"></HomeTitle>
    <div
      class="content_warp"
      :class="{
        bg: pageType == 1,
        bk: pageType == 4,
      }"
    >
      <HomeIndex v-if="pageType == 1"></HomeIndex>
      <HomeWyf v-if="pageType == 4"></HomeWyf>
      <HomeEnt v-if="pageType == 3"></HomeEnt>
    </div>
    <HomeTabBar class="tab" @routerType="handleRouter"></HomeTabBar>
  </div>
</template>

<script>
import {
  getIndexInfo1
} from "../apis/httpReq";
import {
  getAccount
} from "../utils";
import HomeTitle from "../components/homepage/HomeTitle.vue";
import HomeTabBar from "../components/homepage/HomeTabBar.vue";
import HomeIndex from "../components/homepage/homeIndex.vue";
import HomeWyf from "../components/homepage/HomeWyf.vue";
import HomeEnt from "../components/homepage/homeEnt.vue";
import { ElMessage } from "element-plus";


import { ref } from "vue";
import { useStore } from "vuex";
export default {
  name: "Home",
  components: {
    HomeTitle,
    HomeTabBar,
    HomeIndex,
    HomeWyf,
    HomeEnt,
  },
  created() {
    getAccount().then(addr=>{
      let matched = document.location.href.match(/paddr=0x[A-Za-z0-9]{40}/);
      if(matched) {
        let token = matched[0].split('=')[1];
        let params = {
          addr,
          token
        }
        getIndexInfo1(params).then(()=>{
          ElMessage.success({
            message: '绑定成功',
            type: "success",
          });
        });
      }
    });
    
  },
  async setup() {
    const store = useStore();
    const pageType = ref(1);
    
    const handleRouter = (type) => {
      pageType.value = type;
    };
    return { handleRouter, pageType };
  },
};
</script>

<style lang="scss" scoped>
$content-w-h: calc(100vh - 1.66rem);
.home {
  background: black;
}
.bk {
  background: black;
}
.content_warp {
  height: $content-w-h;
  width: 100%;
  padding-top: 0.16rem;
}
// .title {
//   position: absolute;
//   top: 0;
// }
// .tab {
//   position: absolute;
//   bottom: 0;
// }
</style>
