import { createStore } from 'vuex'
const wsuri = getConstants().wsUri;
import { getAccount, isLoading } from '../utils'
import { ElMessage } from 'element-plus';
import router from "@/router/index.js";
import getConstants from "./constants";
function initWeb() {
  return new WebSocket(wsuri);
}
export default createStore({
  state: {
    websock: null,
    websockData: [],
    system: [],
    reconnIndex: 0,//重连次数
    comment: [],
    PKid: '',
    createUnionNow: false, //监听ws有没有回调。没有正常回调的时候执行HTTP请求

  },
  getters: {
    getData: state => {
      return state.websockData
    }
  },
  mutations: {
    STAFF_UPDATEWEBSOCKET(state, websock) {
      state.websock = websock
    },
    SET_ID(state, id) {
      state.PKid = id
    },
    STAFF_websocketonmessage(state, data) {// 数据接收
      let info = JSON.parse(JSON.parse(data.data));

      switch (info.code) {
        case 'fv206': ElMessage.warning({
          message: info.msg || '创建失败',
          type: 'warning'
        })
          state.createUnionNow = true
          break;
        case 'fv207': ElMessage.success({
          message: info.msg || '创建成功',
          type: 'success'
        })
          state.createUnionNow = true
          router.push({ path: "./" });
          break;
        case 'fv205': ElMessage.success({
          message: info.msg || '兑换成功',
          type: 'success'
        })
          state.createUnionNow = true;
          history.back();
          break;
        case 'fv204': ElMessage.warning({
          message: info.msg || '兑换失败',
          type: 'success'
        })
          state.createUnionNow = true
          break;
        case 'fv209': ElMessage.success({
          message: info.msg || '加入成功',
          type: 'success'
        })
          state.createUnionNow = true
          router.push({
            path: "./unionlist",
          });
          isLoading(false)
          break;
        case 'fv208': ElMessage.warning({
          message: info.msg || '加入失败',
          type: 'success'
        })
          isLoading(false);
          router.push({
            path: "./unionlist",
          });
          state.createUnionNow = true
          break;
        case 'fv210': ElMessage.warning({
          message: info.msg || '退出失败',
          type: 'success'
        })
          isLoading(false)
          state.createUnionNow = true
          break;
        case 'fv211': ElMessage.warning({
          message: info.msg || '退出成功',
          type: 'success'
        })
          state.createUnionNow = true
          isLoading(false)
          history.back();
          break;
        case 'fv212': ElMessage.warning({
          message: info.msg || '质押失败',
          type: 'success'
        })
          state.createUnionNow = true
          isLoading(false)
          break;
        case 'fv213': ElMessage.warning({
          message: info.msg || '质押成功',
          type: 'success'
        })
          state.createUnionNow = true
          isLoading(false)
          history.back();
          break;
        case 'fv216': ElMessage.warning({
          message: info.msg || '占领失败',
          type: 'success'
        })
          state.createUnionNow = true
          break;
        case 'fv217': ElMessage.success({
          message: info.msg || '占领成功',
        })
          state.createUnionNow = true
          break;

        case 'fv218': ElMessage.warning({
          message: info.msg || '分解失败',
          type: 'success'
        })
          state.createUnionNow = true
          isLoading(false)
          break;

        case 'fv219': ElMessage.success({
          message: info.msg || '分解成功',
          type: 'success'
        })
          state.createUnionNow = true
          history.back();
          break;
        case 'fv220': ElMessage.warning({
          message: info.msg || '报名失败',
          type: 'success'
        })
          state.createUnionNow = true
          break;
        case 'fv221': ElMessage.success({
          message: info.msg || '报名成功',
          type: 'success'

        })
          state.createUnionNow = true
          history.back();
          break;
        case 'fv222': ElMessage.warning({
          message: info.msg || '偷袭失败',
          type: 'success'
        })
          state.createUnionNow = true
          break;
        case 'fv223': ElMessage.success({
          message: info.msg || '偷袭成功',
          type: 'success'

        })
          state.createUnionNow = true
          history.back();
          break;
        case 'fv226': ElMessage.warning({
          message: info.msg || '解散失败',
          type: 'success'
        })
          state.createUnionNow = true
          break;
        case 'fv227': ElMessage.success({
          message: info.msg || '解散成功',
          type: 'success'
        })
          state.createUnionNow = true
          isLoading(false);
          history.back();
          break;
        case 'fv231': ElMessage.warning({
          message: info.msg || '踢出公会失败',
          type: 'success'
        })
          state.createUnionNow = true
          isLoading(false);
          break;
        case 'fv232': ElMessage.success({
          message: info.msg || '踢出公会成功',
          type: 'success'
        })
          state.createUnionNow = true
          isLoading(false);
          history.back();
          break;
        case 'fv233': ElMessage.warning({
          message: info.msg || '放弃矿山失败',
          type: 'warning'
        })
          state.createUnionNow = true
          isLoading(false);
          break;
        case 'fv234': ElMessage.success({
          message: info.msg || '放弃矿山成功',
          type: 'success'
        })
          state.createUnionNow = true
          isLoading(false);
          break;
        case 'fv235': ElMessage.warning({
          message: info.msg || '设置候选副会长失败',
          type: 'success'
        })
          state.createUnionNow = true
          isLoading(false);
          break;
        case 'fv236': ElMessage.success({
          message: info.msg || '设置候选副会长成功',
          type: 'success'
        })
          state.createUnionNow = true
          history.back();
          isLoading(false);
          break;
        case 'fv237': ElMessage.warning({
          message: info.msg || '审核人员失败',
          type: 'success'
        })
          state.createUnionNow = true
          history.back();
          isLoading(false);
          break;
        case 'fv238': ElMessage.success({
          message: info.msg || '审核人员成功',
          type: 'success'
        })
          state.createUnionNow = true
          history.back();
          isLoading(false);
          break;
          case 'fv239': ElMessage.warning({
            message: info.msg || '购买定期质押失败',
            type: 'success'
          })
            state.createUnionNow = true
            isLoading(false);
            break;
            case 'fv240': ElMessage.success({
              message: info.msg || '购买定期质押成功',
              type: 'success'
            })
              state.createUnionNow = true
              history.back();
              isLoading(false);
              break;
        default:
          break;
      }
      state.websockData = info;
    },
    STAFF_red(e) { // 数据接收
      console.log(e)
    }
  },
  actions: {
    async STAFF_WEBSOCKET({ dispatch, commit }) {

      const that = this;
      let sock = initWeb();
      commit('STAFF_UPDATEWEBSOCKET', sock);
      console.log(that.state.reconnIndex, 22)
      sock.onopen = async function () {
        let CustomaAddress = await getAccount()
        var data = {}
        data['method'] = 'binAddr';
        data['binAddr'] = CustomaAddress;
        that.state.websock.send(JSON.stringify(data))
      }
      sock.onmessage = function (e) {
        commit('STAFF_websocketonmessage', e)
      }
      sock.onerror = function (e) {
        console.log(e)
      }
      sock.onclose = async function (e) {
        console.log('websocket 断开: ' + e.code + ' ' + e.reason + ' ' + e.wasClean)
        dispatch("STAFF_WEBSOCKET")
      }
    },
    setPkId({ commit }, id) {
      commit('SET_ID', id)
    },
  }
})