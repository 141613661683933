import { createApp } from 'vue';
import router from './router';
import store from './store';
import App from './App.vue';
import mitt from 'mitt';//事件总线
import { ElCheckbox, ElInputNumber, ElDatePicker, ElEmpty, ElDialog, ElButton, ElRadio,ElCollapse } from 'element-plus';
const emitter = mitt();
function launch() {
    const app = createApp(App);
    app.config.globalProperties.$emitter = emitter;
    app.use(ElCheckbox);
    app.use(ElCollapse);
    app.use(ElInputNumber);
    app.use(ElDatePicker);
    app.use(ElEmpty);
    app.use(ElDialog);
    app.use(ElButton);
    app.use(ElRadio);

    app.use(store)
        .use(router)
        .mount('#app');

}
function check() {
    if (window.ethereum) {
        clearInterval(checkInterval);
        launch();
    }
}
let checkInterval = window.setInterval(check, 500);
check();
