<template>
  <div class="package">
    <div class="package_content">
      <div class="package_title flex">
        <span> * 只展示当前灵气相关数据</span>
        <p>全网排名<span class="onlineTop">5%</span></p>
      </div>
      <div class="package_item flex">
        <span>我的灵气</span>
        <div class="levelProcess">
          <p class="flex">
            <span class="pink">level5</span
            ><span class="grey size_12">level6</span>
          </p>
          <div class="processLay">
            <div class="process"></div>
          </div>
        </div>
      </div>
      <div class="package_item flex">
        <span>灵气类型(币种)</span>
        <p><span class="pink">USDT</span> <span class="icon_right"></span></p>
      </div>
      <div class="package_item flex">
       <span> <span class="xiandan"></span>我的仙丹</span>
        <p><span class="pink">80</span> <button>兑换灵丹</button></p>
      </div>
         <div class="package_item flex">
        <span><span class="lingdan"></span>我的灵丹</span>
        <p><span class="pink">80</span> <span class="icon_right"></span></p>
      </div>
         <div class="package_item flex">
       <span> <span class="shengwu"></span>我的圣物</span>
        <p> <span class="icon_right"></span></p>
      </div>
         <div class="package_item flex">
        <span>昨日奖金</span>
        <p> <span class="pink">80</span><span class="pink">USDT</span></p>
      </div>
           <div class="package_item flex">
        <span>今日已完成</span>
        <p> <span class="pink">80</span><span class="icon_right"></span></p>
      </div>
         <div class="package_item flex">
        <span>我的公会</span>
        <p> <span class="icon_right"></span></p>
      </div>
          <div class="package_item flex">
        <span>个人活跃度</span>
        <p> <span ></span></p>
      </div>
      <div class="package_item flex">
        <span>总活跃度</span>
        <p> <span class="pink">9999</span></p>
      </div>
       <div class="package_item flex">
        <span>当日跃度</span>
        <p> <span class="pink">9999</span></p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "package",
  async setup() {},
};
</script>

<style lang="scss" scoped>

.package {
  width: 100%;
  height: 100%;
  background: #f8f8f8;
  font-size: 0.14rem;
  padding: 0.16rem;
  button {
    background: linear-gradient(#fc7dcd, #f7479e);
    padding: 0.05rem;
    border-radius: 0.05rem;
    color: white;
  }
  &_content {
    background: white;
    padding: 0.16rem 0.04rem;
  }
  &_title {
    color: #abb0b4;
    > p {
      font-size: 0.11rem;
    }
  }
  &_item {
    font-size: 0.15rem;
    padding: 0.13rem;
    border-radius: 0.08rem;
    margin-top: 0.09rem;
    border: 1px solid #f5f5f5;
  }
  .levelProcess {
    display: inline-flex;
    flex-direction: column;

    width: 1rem;
    .processLay {
      display: inline-block;
      width: 100%;
      height: 0.03rem;
      background: #c2c0b7;
      position: relative;
      margin-top: 0.05rem;
      border-radius: 0.1rem;
      .process {
        background: #ac650f;
        width: 20%;
        height: 0.03rem;
        position: absolute;
        border-radius: 0.1rem;
        left: 0;
      }
    }
  }
  .flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .onlineTop {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 0.2rem;
    color: #fed83e;
    margin-left: 0.1rem;
    height: 0.2rem;
    background: url("../../assets/images/onlineTop.png") 100% no-repeat;
    background-position: center;
    position: relative;
    background-size: cover;
  }
}
</style>
