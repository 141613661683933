import { getPkContract, getAccount, isLoading } from '../utils';
import { ElMessage } from 'element-plus';

export const getCampaignData = async (campaignId) => {
    isLoading(true);
    try {
        const res = await getPkContract()
            .methods.getCampaign(campaignId)
            .call();
        return res;
    } catch (e) {
        console.log(e);
        return null;
    } finally {
        isLoading(false);
    }
};

export const getGame = async (campaignId, gameNum) => {
    isLoading(true);
    try {
        const res = await getPkContract()
            .methods.getGame(campaignId, gameNum)
            .call();
        return res;
    } catch (e) {
        console.log(e);
        return null;
    } finally {
        isLoading(false);
    }
};

export const fillIn = async (params) => {
    isLoading(true);
    try {
        const res = await getPkContract()
            .methods.fillIn(
                params.campaignId,
                params.betSide,
                params.gameNum,
                params.conflictOption,
                params.betNum1,
                params.betNum2,
                params.position
            )
            .send({ from: await getAccount(), gas: '1000000' });
        ElMessage.success({
            message: '投注成功',
            type: 'success'
        });
        return res;
    } catch (e) {
        console.log(e);
        ElMessage.warning({
            message: '投注失败',
            type: 'warning'
        });
        return null;
    } finally {
        isLoading(false);
    }
};
