import http from '../http';

// pk
export const getRandomGameInfo = async (campaignId, betSide) => {
    const info = await http(
        `/campaign/${campaignId}/fetch?bet_side=${betSide}`
    );
    if (info.game) {
        return {
            game_num: info.game.game_num,
            currentTable: info.position,
            totalTable: info.total
        };
    } else {
        return null;
    }
};
export const getGameMenPai = async () => {
    const info = await http(`/fv2-get-sects-config`);
    if (info) {
        return info;
    } else {
        return null;
    }
};

//矿山列表
export const getMines = async (address) => {
    const info = await http(`/mines?my_address=${address}`);
    if (info) {
        return info;
    } else {
        return null;
    }
};

export const getLingqi = async (address, token) => {
    const info = await http(`/draw-aura/${address}/${token}`);
    if (info) {
        return info;
    } else {
        return null;
    }
};

export const getGrou = async (address, token) => {
    const info = await http(`/get-regular-grou-list/${address}?token=${token}`);
    if (info) {
        return info;
    } else {
        return null;
    }
};

export const getDrawAura = async (address, type,token,page) => {
    const info = await http(`/get-regular-list/${address}/${type}?token=${token}&pag=${page}`);
    if (info) {
        return info;
    } else {
        return null;
    }
};

//矿山详情
export const getMinesDetail = async (id) => {
    const info = await http(`/mine/${id}`);
    if (info) {
        return info;
    } else {
        return null;
    }
};

// 已报名的矿山ID集合
export const getAlreadySignedUp = async (address) => {
    const info = await http(`/player/${address}/current-signups`);
    if (info) {
        return info;
    } else {
        return null;
    }
};

//检测能否对矿山发起偷袭和报名
export const checkCanOper = async (address, mineId) => {
    const info = await http(
        `/attack-mine/check?player=${address}&mine_id=${mineId}`
    );
    if (info) {
        return info;
    } else {
        return null;
    }
};

//获取个人宝石数量
export const getPersonGemstone = async (playerAddress, campaignId) => {
    const info = await http(
        `/player/${playerAddress}/campaign/${campaignId}/scores`
    );
    if (info) {
        return info;
    } else {
        return null;
    }
};

//公会相关接口
export const getUnionList = async (name) => {
    const info = await http(`/fv2-get-sects-list?name=${name}`);
    if (info) {
        return info;
    } else {
        return null;
    }
};
//获取收益
export const getEarningsInfo = async (addr,token,id) => {
    const info = await http(`/mine-profit-total/${addr}/AURA`);
    if (info) {
        return info;
    } else {
        return null;
    }
};
//提取收益
export const getEarnings = async (addr,token,id) => {
    const info = await http(`/draw-mine-profit/${addr}/AURA`);
    if (info) {
        return info;
    } else {
        return null;
    }
};

export const getUnionInfoHead = async (id) => {
    const info = await http(`/fv2-get-sects-info/${id}`);
    if (info) {
        return info;
    } else {
        return null;
    }
};
export const getUnionInfoContent = async (id) => {
    const info = await http(`/fv2-get-sects-member-list/${id}`);
    if (info) {
        return info;
    } else {
        return null;
    }
};
export const getUnionPermission = async (id) => {
    const info = await http(`/dismiss-sociaty/${id}/check`);
    if (info) {
        return info;
    } else {
        return null;
    }
};
//获取合成页面得相关参数
export const getRegular = async (id) => {
    const info = await http(`/get-regular-all-token-apy`);
    if (info) {
        return info;
    } else {
        return null;
    }
};

//获取玩家个人信息
export const getPlayerUnion = async (addr) => {
    const info = await http(`/player/${addr}/sociaty`);
    if (info) {
        return info;
    } else {
        return null;
    }
};
export const getPlayeMoney = async (addr) => {
    const info = await http(`/player/${addr}/relic-assets`);
    if (info) {
        return info;
    } else {
        return null;
    }
};
export const getSpringInfo = async (addr, token) => {
    const info = await http(`/aura-total/${addr}/${token}`);
    if (info) {
        return info;
    } else {
        return null;
    }
};

//log日志接口
export const getLogList = async (params) => {
    const info = await http(
        `/player/${params.addr}/relic-logs?start_time=${params.startTime}&end_time=${params.endTime}&relic_name=${params.name}`
    );
    if (info) {
        return info;
    } else {
        return null;
    }
};
export const getSpringLogList = async (params) => {
    const info = await http(
        `/pledge-profit-log/${params.addr}/${params.token}?start_time=${params.startTime}&end_time=${params.endTime}&page=${params.page}&type=${params.type}`
    );
    if (info) {
        return info;
    } else {
        return null;
    }
};
export const getSpringNewLogList = async (params) => {
    const info = await http(
        `/new-pledge-profit-log/${params.addr}/${params.token}?page=${params.page}&start_time=${params.startTime}&end_time==${params.endTime}&type=${params.type}`
    );
    if (info) {
        return info;
    } else {
        return null;
    }
};

//收益明细
export const getEarningsInfoList = async (params) => {
    const info = await http(`/get-mine-reward-list/${params.addr}?start_time=${params.startTime}&end_time=${params.endTime}&page=${params.page}`);
    if (info) {
        return info;
    } else {
        return null;
    }
};
//PK明细
export const getPkLogList = async (params) => {
    const info = await http(
        `/player/${params.addr}/campaign-reward-logs?start_time=${params.startTime}&end_time=${params.endTime}`
    );
    if (info) {
        return info;
    } else {
        return null;
    }
};
//首页
export const getIndexInfo = async () => {
    const info = await http(`/token-statistics`);
    if (info) {
        return info;
    } else {
        return null;
    }
};
//首页1
export const getIndexInfo1 = async (params) => {
    const info = await http(`/player-invite/${params.addr}/${params.token}`);
    if (info) {
        return info;
    } else {
        return null;
    }
};

// tables list
export const getTableList = async (account, campaignId, status, page) => {
    console.log(account, campaignId, status, page);
    const data = await http(
        `get-campaign-game-bet-log/${account}?campaignId=${campaignId}&status=${status}&page=${page}`
    );
    if (data) {
        return data;
    } else {
        return null;
    }
};
//tablist
export const getCampaign = async (account, campaignId) => {
    const info = await http(`/campaign/${campaignId}/summary/${account}`);
    if (info) {
        return info;
    } else {
        return null;
    }
};

//审核列表
export const getCacheTableList = async (socialId) => {
    const data = await http(
        `/get-sociaty-member-cache-list/${socialId}`
    );
    if (data) {
        return data;
    } else {
        return null;
    }
};
//申请加入
export const applyUnion = async (account,socialId) => {
    const data = await http(
        `/apply-sociaty-member-cache/${account}/${socialId}`
    );
    if (data) {
        return data;
    } else {
        return null;
    }
};


//放弃矿山
export const abandonMine = async (account, id) => {
    const data = await http(
        `get-sure-give-up-mine/${account}/${id}`
    );
    if (data) {
        return data;
    } else {
        return null;
    }
};
//判断能否加入
export const canJoin = async (account,socialId) => {
    const data = await http(
        `/is-sociaty-member-cache-list/${account}/${socialId}`
    );
    if (data) {
        return data;
    } else {
        return null;
    }
};