import http from '../http';
//websork请求失败时请求
export const createUnionCall= async (hash) => {
    const info = await http(
        `/fv2-txhash-back/${hash}`
    );
    if (info) {
        return info;
    } else {
        return null;
    }
};

