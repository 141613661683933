<template>
  <div class="Apy_lay" @click.self="back">
    <div class="Apy">
      <p class="title" style="text-align: left" v-if="type == 'apy'">Apy</p>
      <p class="title" style="text-align: center" v-if="type == 'earnings'">
        领取矿山收益
      </p>

      <p class="flex" v-if="type == 'earnings'">
        <span>当前可领取矿山收益(AURA)</span
        ><span>{{ price ? Number(price).toFixed(1) : 0 }}</span>
      </p>
      <ul  v-if="type == 'apy'">
        <li v-for="(item, index) of regular[token]" :key="index" class="flex">
          <span>   {{ item.duration ? item.duration / (24 * 60 * 60) + "天锁仓" : "活期" }}</span><span>{{ Number(item.apy*100).toFixed(3) }}%</span>
        </li>
      </ul>
      <!-- <p class="flex"><span>30天锁仓</span><span>20%</span></p>
      <p class="flex"><span>90天锁仓</span><span>20%</span></p>
      <p class="flex"><span>180天锁仓</span><span>20%</span></p>
      <p class="flex"><span>360天锁仓</span><span>20%</span></p> -->
      <button @click="getEarningsToMe" v-if="type == 'earnings'">
        确认领取
      </button>
    </div>
  </div>
</template>

<script>
import router from "@/router/index.js";
import { getEarnings, getRegular } from "../../apis/httpReq";
import { ElMessage } from "element-plus";
import { getAccount, isLoading } from "../../utils";
import { ref } from "@vue/reactivity";
export default {
  name: "Apy",
  async setup() {
    let type = router.currentRoute.value.query.type;
    let price = ref(router.currentRoute.value.query.price);
    const token=router.currentRoute.value.query.token;
    let profit_year_rate = router.currentRoute.value.query.profit_year_rate
      ? router.currentRoute.value.query.profit_year_rate
      : "";
    let account = await getAccount();
    const regular = await getRegular();
    console.log(regular)
    const back = () => {
      history.back();
    };
    const getEarningsToMe = () => {
      isLoading(true);
      getEarnings(account).then((res) => {
        ElMessage.success({
          message: "领取成功",
          type: "success",
        });
        history.back();
        price.value = 0;
      });
    };
    return { back, profit_year_rate, type, getEarningsToMe, price, regular,token };
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/scss/_varibles.scss";
.Apy_lay {
  width: 100%;
  height: 100%;
  background: rgba(1, 1, 1, 0.8);
  display: flex;
  align-items: center;
  button {
    @include primaryButton();
    margin-top: 0.5rem;
    width: 100%;
  }
  font-size: 0.16rem;
  .title {
    font-weight: 600;
    color: #000000;
    margin-bottom: 0.5rem;
  }
  .flex {
    display: flex;
    justify-content: space-between;
    margin-top: 0.2rem;
    span {
      color: #333333;
    }
    > span:last-child {
      color: #f7479e;
    }
  }

  .Apy {
    margin: 0 0.14rem;
    padding: 0.14rem;
    display: inline-block;
    background: #ffffff;
    border-radius: 8px;

    width: 100%;
    height: 2.6rem;
  }
}
</style>
